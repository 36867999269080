import { useEffect, useState, useMemo } from "react";
import React from 'react';
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import {Button} from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import "../../App.css";
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import {Row,Col} from "reactstrap";
import { default as ReactSelect } from "react-select";
import {getProductionReport,getProductionReportCSV} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import { getAllPlants} from '../../services/PlantServices';
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import { getAllSalesRepresentatives } from '../../services/SalesRepresentativeServices';
import {getAllCustomerSites} from '../../services/CustomerSiteServices';
import { getAllProducts } from "../../services/ProductServices";
import { getAllEquipments } from '../../services/EquipmentServices';
import { getCustomerVehicles } from '../../services/DeliveryChallanServices';
import moment  from 'moment';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';
import {HandleSelect,ValueContainer,Option} from "../../components/MultiSelectControls";

const {RangePicker} = DatePicker;
function ProductionReportTable() 
{
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [plants, setPlants] = React.useState([]);
    const [selectedPlant, setSelectedPlant] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedSalesrep, setSelectedSalesRep] = useState([]);
    const [salesreps, setSalesreps] = useState([]);
    const [selectedSite, setSelectedSite] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState([]);
    const [grades, setGrades] = useState([]);

    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
        setFromDate(values[0]);
        setToDate(values[1]);
        }
    };

    React.useEffect(() => {
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                const plants = plantList.plant_list.map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlants(plants);
                // setSelectedPlant(plants);
                setSelectedPlant(values =>[...values,...plants, ...plantsWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
        getAllCustomerMain(cookies)
        .then (
            customerList => {
                const customers = customerList.customer_list.map(
                    customer => {
                        return { value: customer.id, label: customer.name }
                    }
                );
                setCustomers(customers);
                // setSelectedCustomer(customers);
                setSelectedCustomer(values =>[...values,...customers, ...customersWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
    getAllCustomerSites(cookies)
    .then (
        siteList => {
            const sites = siteList.consignee_list.map(
                site => {
                    return { value: site.id, label: site.name }
                }
            );
            setSites(sites);
            // setSelectedSite(sites);
            setSelectedSite(values =>[...values,...sites, ...sitesWithSelectAll]);
        }
    )
    }, []);

    React.useEffect(() => {
        getAllSalesRepresentatives(cookies)
        .then (
            salesRepList => {
                const salesrep = salesRepList.salesrep_data.map(
                sales => {
                        return { value: sales.id, label: sales.entity_name }
                    }
                );
                //console.log(JSON.stringify(salesRepList)+"salesRepList")
                setSalesreps(salesrep);
                // setSelectedSalesRep(salesrep);
                setSelectedSalesRep(values =>[...values,...salesrep, ...salesrepsWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
        getCustomerVehicles(cookies)
        .then (
            equipment => {
                const customer_vehicles = equipment.customer_vehicles_list.map(
                    equip => {
                        return { value: equip, label: equip}
                    }
                );
                setVehicles(customer_vehicles);
                setSelectedVehicle(customer_vehicles);
            }
        )
    }, []);

    React.useEffect(() => {
        getAllEquipments(cookies)
        .then (
            equipmentObject => {
                const equipments = equipmentObject.equipment_list.filter(obj => ((obj.equip_type.name === 'TRANSIT MIXER'))).map(
                    equipment => {
                        return { value: equipment.id, label: equipment.equip_name }
                    }
                );
                setVehicles(values =>[...values, ...equipments]);
                setSelectedVehicle(values =>[...values,...equipments, ...vehiclesWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
        getAllProducts(cookies)
        .then (
            productList => {
                const products = productList.product_list.map(
                    product => {
                        return { value: product.id, label: product.name}
                    }
                )
                setGrades(products);
                // setSelectedGrade(products);
                setSelectedGrade(values =>[...values,...products, ...gradesWithSelectAll]);
            }
        )
    }, []);

    // Add a "Select All" option to the plants array
    const plantsWithSelectAll = [
        { value: "select-all", label: "Select All plants" },
        ...plants,
    ];

    // Add a "Select All" option to the customers array
    const customersWithSelectAll = [
        { value: "select-all", label: "Select All customers" },
        ...customers,
    ];
    
    // Add a "Select All" option to the sites array
    const sitesWithSelectAll = [
        { value: "select-all", label: "Select All sites" },
        ...sites,
    ];
    
    // Add a "Select All" option to the salesreps array
    const salesrepsWithSelectAll = [
        { value: "select-all", label: "Select All sales representatives" },
        ...salesreps,
    ];  
    
    // Add a "Select All" option to the vehicles array
    const vehiclesWithSelectAll = [
        { value: "select-all", label: "Select All vehicles" },
        ...vehicles,
    ];

    // Add a "Select All" option to the grades array
    const gradesWithSelectAll = [
        { value: "select-all", label: "Select All grades" },
        ...grades,
    ];
      
    const onPlantSelect = (selected) => {
        HandleSelect(selected,selectedPlant,setSelectedPlant, plants, plantsWithSelectAll);
    };
    
    const onCustomerSelect = (selected) => {
        HandleSelect(selected,selectedCustomer, setSelectedCustomer, customers, customersWithSelectAll);
    };
    
    const onSiteSelect = (selected) => {
        HandleSelect(selected,selectedSite, setSelectedSite, sites, sitesWithSelectAll);
    };
        
    const onSalesRepSelect = (selected) => {
        HandleSelect(selected,selectedSalesrep, setSelectedSalesRep, salesreps, salesrepsWithSelectAll);
    };

    const onVehicleSelect = (selected) => {
        HandleSelect(selected,selectedVehicle, setSelectedVehicle, vehicles, vehiclesWithSelectAll);
    };
    
    const onGradeSelect = (selected) => {
        HandleSelect(selected,selectedGrade, setSelectedGrade, grades, gradesWithSelectAll);
    };

    const areAllPlantsSelected = () => {
        // Check if all plants are selected
        return plants.every((plant) =>
          selectedPlant ? selectedPlant.some((selected) => selected.value === plant.value) : false
        );
    };

    const areAllCustomersSelected = () => {
        // Check if all customers are selected
        return customers.every((customer) =>
            selectedCustomer ? selectedCustomer.some((selected) => selected.value === customer.value) : false
        );
    };

    const areAllConsigneesSelected = () => {
        // Check if all consignees are selected
        return sites.every((consignee) =>
        selectedSite ? selectedSite.some((selected) => selected.value === consignee.value) : false
        );
    };

    const areAllSalesRepsSelected = () => {
        // Check if all salesreps are selected
        return salesreps.every((salesrep) =>
        selectedSalesrep ? selectedSalesrep.some((selected) => selected.value === salesrep.value) : false
        );
    };
    
    const areAllVehiclesSelected = () => {
        // Check if all vehicles are selected
        return vehicles.every((vehicle) =>
        selectedVehicle ? selectedVehicle.some((selected) => selected.value === vehicle.value) : false
        );
    };
    
    const areAllGradesSelected = () => {
        // Check if all grades are selected
        return grades.every((grade) =>
        selectedGrade ? selectedGrade.some((selected) => selected.value === grade.value) : false
        );
    };

    const Home = () => {
        navigate('/Home')
      }

    const columns = useMemo(
        () => [
            {
                fieldName: 'plant_name', 
                headerName: 'Plant Name'
            },
            {
                fieldName: 'plant_alias', 
                headerName: 'Plant Alias'
            },
            {
                fieldName: 'production_plant_name', 
                headerName: 'Production Plant Name'
            },
            {
                fieldName: "delivery_challan_date",
                headerName: 'Date'
            },
            {
                fieldName: 'delivery_challan_no', 
                headerName: 'DC Number'
            },
            {
                fieldName: "batch_no",
                headerName: 'Batch Number'
            },
            {
                fieldName: 'delivery_challan_time', 
                headerName: 'Time'
            },
            {
                fieldName: "sales_order_no",
                headerName: 'SO Number'
            },
            {
                fieldName: "purchase_order_no",
                headerName: 'PO Number'
            },
            {
                fieldName: "invoice_number",
                headerName: 'Invoice Number'
            },
            {
                fieldName: 'customer_name', 
                headerName: 'Customer Name'
            },
            {
                fieldName: "project_name",
                headerName: 'Site/Project Name'
            },
            {
                fieldName: "production_grade",
                headerName: 'Production Grade'
            },
            {
                fieldName: 'production_qty', 
                headerName: 'Production Qty'
            },
            {
                fieldName: "purchase_grade",
                headerName: 'Purchase Grade'
            },
            {
                fieldName: 'purchase_qty', 
                headerName: 'Purchase Qty'
            },
            {
                fieldName: 'received_grade', 
                headerName: 'Received Grade'
            },
            {
                fieldName: 'received_qty', 
                headerName: 'Received Qty'
            },
            {
                fieldName: "other_grade",
                headerName: 'Other Grade'
            },
            {
                fieldName: "other_qty",
                headerName: 'Other Qty'
            },
            {
                fieldName: "invoice_grade",
                headerName: 'Invoice Grade'
            },
            {
                fieldName: "invoice_qty",
                headerName: 'Invoice Qty'
            },
            {
                fieldName: 'dc_grade', 
                headerName: 'DC Grade'
            },
            {
                fieldName: 'dc_qty', 
                headerName: 'DC Qty'
            },
            {
                fieldName: 'rate', 
                headerName: 'Rate'
            },
            {
                fieldName: 'delivery_mode', 
                headerName: 'Mode of Delivery'
            },
            {
                fieldName: 'vehicle_no', 
                headerName: 'Vehicle No'
            },
            {
                fieldName: "salesrep_name",
                headerName: 'Marketting'
            },
            {
                fieldName: "operator_name",
                headerName: 'Operator Name'
            },
            {
                fieldName: "driver_name",
                headerName: 'Driver Name'
            },
            {
                fieldName: "savings_company",
                headerName: 'Savings Company'
            },
            {
                fieldName: "savings_client",
                headerName: 'Savings Client'
            }
        ],
        [],);
   
    const handleCSVExport = (event) => {
        // console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(dc => {
                const csvRow = {
                    plant_name:dc.plant_name,
                    plant_alias: dc.plant_alias,
                    production_plant_name: dc.production_plant_name,
                    delivery_challan_date: dc.delivery_challan_date,
                    delivery_challan_no: dc.delivery_challan_no,
                    batch_no: dc.batch_no,
                    delivery_challan_time: dc.delivery_challan_time,
                    sales_order_no: dc.sales_order_no,
                    purchase_order_no:dc.purchase_order_no,
                    invoice_number: (dc.invoice_number!= null )?dc.invoice_number:'pending',
                    customer_name:dc.customer_name ,
                    project_name:dc.project_name,
                    production_grade:dc.production_grade,
                    production_qty: dc.production_qty?dc.production_qty:0,
                    purchase_grade: dc.purchase_grade,
                    purchase_qty : dc.purchase_qty?dc.purchase_qty:0,
                    received_grade: dc.received_grade,
                    received_qty: dc.received_qty?dc.received_qty :0,
                    other_grade: dc.other_grade,
                    other_qty:  dc.other_qty?dc.other_qty:0,
                    invoice_grade: (dc.invoice_grade!= null )?dc.invoice_grade:'pending',
                    invoice_qty: dc.invoice_qty?dc.invoice_qty :0,
                    dc_grade:  dc.dc_grade,
                    dc_qty: dc.dc_qty?dc.dc_qty :0,
                    rate: dc.rate?dc.rate :0,
                    delivery_mode: dc.delivery_mode,
                    vehicle_no: dc.vehicle_no,
                    salesrep_name: dc.salesrep_name,
                    operator_name: dc.operator_name,
                    driver_name:  dc.driver_name,
                    savings_company:  dc.savings_company?dc.savings_company:0,
                    savings_client:  dc.savings_client?dc.savings_client:0
                }
                return csvRow;
                }))
    };

    const handlePDFExport = (event) => {
        // console.log('PDF Export');
        exportAsPDF(
            'Production Report',
            columns
            .filter(col => ['Date', 'DC Number','Batch Number','Time','Customer Name','Site/Project Name','Production Grade'
            ,'Production Qty','Vehicle No','Marketting'].includes(col.headerName))
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(dc => ({
                delivery_challan_no: dc.delivery_challan_no,
                delivery_challan_date: dc.delivery_challan_date,
                delivery_challan_time: dc.delivery_challan_time,
                customer_name:dc.customer_name,
                project_name:dc.project_name,
                salesrep_name: dc.salesrep_name,
                vehicle_no: dc.vehicle_no,
                production_grade:dc.production_grade,
                batch_no: dc.batch_no,
                production_qty: dc.production_qty,
            })),
            'Production_report.pdf'
        );
    };

    const constructFormData = () => {
        const EMPTY_STRING = '';
        return {
            plant_ids:areAllPlantsSelected()? '*' // Send '*' if all plants are selected
            : selectedPlant.map((plant) => plant.value).join(','),
            customer_ids:areAllCustomersSelected()? '*' // Send '*' if all customers are selected
            : selectedCustomer.map((customer) => customer.value).join(','),
            consignee_ids:areAllConsigneesSelected()? '*' // Send '*' if all consignees are selected
            : selectedSite.map((consignee) => consignee.value).join(','),
            sales_rep_ids:areAllSalesRepsSelected()? '*' // Send '*' if all salesreps are selected
            : selectedSalesrep.map((salesrep) => salesrep.value).join(','),
            vehicle_ids:areAllVehiclesSelected()? '*' // Send '*' if all vehicles are selected
            : selectedVehicle.map((vehicle) => vehicle.value).join(','),
            product_ids:areAllGradesSelected()? '*' // Send '*' if all grades are selected
            : selectedGrade.map((grade) => grade.value).join(','),
            from_date:fromDate.format('DD-MM-YYYY'),
            upto_date:toDate.format('DD-MM-YYYY')
          }
    }

    const handleSubmit=(event)=> 
    {
        event.preventDefault();
        console.log(JSON.stringify(constructFormData())+"submit")
        const inputs = constructFormData()
        if ((inputs.plant_ids) && (inputs.customer_ids) && (inputs.consignee_ids) && (inputs.sales_rep_ids) && 
        (inputs.vehicle_ids) && (inputs.product_ids) &&
        (inputs.from_date) && (inputs.upto_date))
        {
            getProductionReport(cookies, constructFormData())
            .then(response => {
                const tableData = response.production_report_list
                .map(dc => ({
                    id: dc.id,
                    batch_no: dc.batch_no,
                    production_qty: dc.production_qty,
                    purchase_qty : dc.purchase_qty,
                    received_qty: dc.received_qty,
                    client_adjust_qty:dc.client_adjust_qty,
                    transport_mode: dc.transport_mode,
                    operator_name: dc.operator_name,
                    checkout_no: dc.checkout_no,
                    vehicle_no: dc.vehicle_no,
                    plant_name: dc.plant_name,
                    plant_alias: dc.plant_alias,
                    production_plant_name: dc.production_plant_name,
                    production_plant_alias: dc.production_plant_alias,
                    delivery_challan_no: dc.delivery_challan_no,
                    delivery_challan_date: dc.delivery_challan_date,
                    delivery_challan_time: dc.delivery_challan_time,
                    delivery_challan_prefix: dc.delivery_challan_prefix,
                    sales_order_no: dc.sales_order_no,
                    sales_order_prefix:dc.sales_order_prefix,
                    purchase_order_no:dc.purchase_order_no,
                    invoice_number: dc.invoice_number,
                    customer_name:dc.customer_name,
                    customer_id: dc.customer_id,
                    project_name:dc.project_name,
                    production_grade:dc.production_grade,
                    delivery_mode: dc.delivery_mode,
                    purchase_grade: dc.purchase_grade,
                    received_grade: dc.received_grade,
                    other_grade: dc.other_grade,
                    invoice_grade: dc.invoice_grade,
                    invoice_qty: dc.invoice_qty,
                    other_qty:  dc.other_qty,
                    dc_grade:  dc.dc_grade,
                    dc_qty: dc.dc_qty,
                    rate: dc.rate,
                    amount:  dc.amount,
                    salesrep_name: dc.salesrep_name,
                    accepted_qty:  dc.accepted_qty,
                    customer_dc_status: dc.customer_dc_status,
                    driver_name:  dc.driver_name,
                    total_km:  dc.total_km,
                    net_weight:  dc.net_weight,
                    savings_company:  dc.savings_company,
                    savings_client:  dc.savings_client 
                }));
                setData({
                    total: data.total,
                    records: tableData
                });
                setIsLoading(false);
            }).catch((error) => {
                console.log(error.response.data);
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("plant,customer,consignee,sales_rep,vehicle,product,from_date,upto_date must not be empty.", "", "info");  
        }
    }

    const handleDownloadCsv = () => {
        console.log(JSON.stringify(constructFormData())+"submit")
        const inputs = constructFormData()
        if ((inputs.plant_ids) && (inputs.customer_ids) && (inputs.consignee_ids) && (inputs.sales_rep_ids) && 
        (inputs.vehicle_ids) && (inputs.product_ids) &&
        (inputs.from_date) && (inputs.upto_date))
        {
            getProductionReportCSV(cookies, constructFormData())
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'production_report.csv');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((error) => {
                    console.log(error.response.data);
                    displayError(error.response.data);
                });
        }
        else 
        {
            Swal.fire("plant,customer,consignee,sales_rep,vehicle,product,from_date,upto_date must not be empty.", "", "info");  
        }
    };
    return (
        <>
            <form className="form" onSubmit={handleSubmit}>
                <div className="container">
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                                    <ReactSelect
                                        options={plantsWithSelectAll}
                                        isMulti= {true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{Option,ValueContainer}}
                                        isClearable={true}
                                        value={selectedPlant}
                                        onChange={onPlantSelect}
                                        className="react-select-dropdown col-sm-3"
                                        placeholder="Please select Plant name"
                                    />
                                    <br/><br/>
                                
                                    <ReactSelect
                                        options={customersWithSelectAll}
                                        isMulti= {true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{Option, ValueContainer}}
                                        isClearable={true}
                                        value={selectedCustomer}
                                        onChange={onCustomerSelect}
                                        className="react-select-dropdown col-sm-3"
                                        placeholder="Please select Customer"
                                    />
                                    <br/><br/>

                                    <ReactSelect
                                        options={sitesWithSelectAll}
                                        isMulti= {true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{Option, ValueContainer}}
                                        isClearable={true}
                                        value={selectedSite}
                                        onChange={onSiteSelect}
                                        className="react-select-dropdown col-sm-3"
                                        placeholder="Please Select Site"
                                    />
                                    <br/><br/>

                                    <ReactSelect
                                        options={salesrepsWithSelectAll}
                                        isMulti= {true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{Option, ValueContainer}}
                                        isClearable={true}
                                        value={selectedSalesrep}
                                        onChange={onSalesRepSelect}
                                        className="react-select-dropdown col-sm-3"
                                        placeholder="Please Select SalesRep"
                                    />
                                    <br/><br/>
                                
                                    <ReactSelect
                                        options={vehiclesWithSelectAll}
                                        isMulti= {true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{Option, ValueContainer}}
                                        isClearable={true}
                                        value={selectedVehicle}
                                        onChange={onVehicleSelect}
                                        className="react-select-dropdown col-sm-3"
                                        placeholder="Please Select Vehicle"
                                    />

                                    <ReactSelect
                                        options={gradesWithSelectAll}
                                        isMulti= {true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{Option, ValueContainer}}
                                        isClearable={true}
                                        value={selectedGrade}
                                        onChange={onGradeSelect}
                                        className="react-select-dropdown col-sm-3"
                                        placeholder="Please Select Grade"
                                    />&nbsp;&nbsp;

                                    <RangePicker
                                        defaultValue={[initialFromDate, initialToDate]}
                                        format="DD-MM-YYYY"
                                        onChange={handleDateChange}
                                        style={{height:"40px",width:"265px"}}
                                    />&nbsp;&nbsp;
                                        
                                    <Button  type="submit" style={{backgroundColor: "RGB(188,232,253)",fontWeight:"bold",height:"30px",display: "flex",
                                        alignItems: "center",  
                                        justifyContent: "center"}} >
                                        <i className="fa fa-search fa-2x" id="myData" aria-hidden="true" style={{color:"rgb(93, 156, 233)"}}></i>
                                    </Button> &nbsp;&nbsp; 
                                    <Button color="primary" onClick={handleDownloadCsv} startIcon={<FileDownloadRoundedIcon/>}  variant="contained">CSV</Button>
                                        &nbsp;&nbsp;   
                            </div>
                        </Col>
                    </Row> 
                </div>
            </form>
            <div className="container item-list-table-container">
                <div className="table-responsive">
                    <ReactTableWrapper
                        title='CustomerData Report'
                        columns={columns}
                        data={data.records}
                        onCSVExport={handleCSVExport}
                        onPDFExport={handlePDFExport}
                        isLoading={isLoading}
                        enableExportToolbar={false}
                    />
                </div>
            </div> 
        </>
);
}
export default ProductionReportTable;
