import React from 'react';
import { useState } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import TableRows from "../../forms/admin/TableRows";
import { Link,useParams } from 'react-router-dom';
import '../../button.css';
import {displayErrorToast,displayError} from '../../helpers';

import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import {  getNumberSettingDetails, updateNumberSetting ,deleteNumberSetting} from "../../services/NumberSettingServices";
import { getAllPlants } from '../../services/PlantServices';
import { checkForDuplicateNumberSettings } from './NumberSettingUtils';

function NumberSettingEdit() {
  const [cookies] = useCookies(['myToken']);
  
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [inputs, setInputs] = useState({
    id:'',
    voucher_type:'',
    plant_id:'',
    starting_number:'',
    number_of_digits:'',
    prefix:'',
    valid_from_date:'',
    valid_upto_date:'',
    user_remarks:'',
    rowsData:''
});

  const navigate = useNavigate();

 
  const [plants, setPlants] = React.useState([]);


    React.useEffect(() => {
      getAllPlants(cookies)
    .then (
        plantList => {
            
            const plants = plantList.plant_list.filter(obj => obj.status).map(
              plant => {
                    return { value: plant.id, label: plant.plant_alias }
                }
            );
            setPlants(plants);
        }
    )
}, []);
  

  const handleChange2 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
   
    
      // if(name == 'salesorder' || 'grn')
      // {
    
      //   setInputs(values => ({...values, ['voucher_type']:value}))
      //         document.getElementById('voucher_type').disabled = true;
      // }

      
        setInputs(values => ({...values, [name]: value}))
   
   }
  const [rowsData, setRowsData] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const addTableRows = () => {
    const count=rowsData.length +1;
    const rowsInput={
      prefix:'',
      valid_from_date: '',
      valid_upto_date: '',
      starting_number:'',
      number_of_digits:'',
      user_remarks:''
   
        }
        setRowsData([...rowsData, rowsInput])
  }
    
  const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }
 
  const handleChange = (index, evnt)=>{
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
  }
  
  
  const handleSubmit=(event)=> {
    event.preventDefault();
    try{
      //checkForDuplicateNumberSettings(event.target.entity_name.name, event.target.entity_name.value, cookies, id)
    // .then(isNameExists => {
    //   if (isNameExists) {
    //     setError(values => ({...values, [event.target.entity_name.name]: 'Group name already exists.'}))
        
    //   } 
    // else {
    
  
        if (Object.values(error).every((x) => !x) && isSubmitting) { 
    //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
            Swal.fire({title: 'Do you want to update?',  
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
              if (result.isConfirmed) { 
         
      
                  console.log(JSON.stringify(inputs)+"inputsubmit")
                  updateNumberSetting(cookies, {
                    //id:inputs.id,
                    //plant_id:parseInt(inputs.plant_id),
                    plant_id:((inputs.plant_id === 'all')?  -1: inputs.plant_id),
                  voucher_type:inputs.voucher_type,         
                  settings_list:rowsData
         
                 }, id)
                 //console.log(updateAccountMaster)
                .then(response => {
                    Swal.fire("Updated!", "", "success");
                    view()
                }).catch((error) => {
                    console.log(error.response.data);
                    displayError(error.response.data,"Updated Failed");
                });
                
              } 
              else if (result.isDismissed) {
                Swal.fire("Not Updated", "", "info");
              }
            });
      
      }

}
// });
// } 
catch (e) {
  displayErrorToast(e);
} 
 }  
      const Reload = () => {
        window.location.reload();
      }      
      
      const view = () => {
        navigate('/NumberSettingTable')
      }        
      
      const Back = () => {
        navigate('/Home')
      }
      const onDeleteNumberSetting = (event) => {
        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            // setIsLoading(true);
            deleteNumberSetting(cookies, id)
            .then(response => {
                console.log(`NumberForm with id ${id} deleted`);
                Swal.fire('Deleted Successfully!', '', 'success');
                // setRefreshKey(oldKey => oldKey +1)
                view()
            })
            .catch(error => {
                console.log(error.response.data);
                displayError(error.response.data,'Delete Failed');
            }); 
          } 
          else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
    };
      const Cancel = () => {
        setInputs(() => "");
        const rowsInput={
          id:'',
          voucher_type:'',
    plant_id:'',
    starting_number:'',
    number_of_digits:'',
    prefix:'',
    valid_from_date:'',
    valid_upto_date:'',
    user_remarks:'',
    rowsData:''
        }
        setRowsData([rowsInput]);
      }
       
      const {id}=useParams()
      React.useEffect(() => {
        if (id) {
          setInProgress(true);
          getNumberSettingDetails(cookies, id)
            .then(response => {
              setInProgress(false);
              setInputs({...response,plant_id:response.plant.id});
              
              setRowsData(response.settings_list.map((numberentry)=>({...numberentry})));
       
            })
            .catch(error => {
              setInProgress(false);
              displayError(error.response.data, "Loading Failed");
              console.log(error+"error")
            });
          }
        }, []);
  return (
    <>
    {/* <div id="outer-container"  className="App" >  */}
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form className="form" onSubmit={handleSubmit} data-id={inputs.id}>
                <div id="header">
                    <h3 className = "text font-weight-bold page-title" >NUMBER SETTING EDIT FORM</h3>
                </div>
      
                <FloatingControls tableLink="/NumberSettingTable"  enableCancel={false}/>
          <div className="container-fluid">
          <Row>
          <Col xl={4} lg={12} md={12}>
                      </Col>
              <Col xl={4} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel ">
                  <h5 className='col-sm-11'><b>Number Setting Details</b></h5><br/><br/>
                        <label htmlFor="voucher_type"className="form-group col-sm-5 text-right  ">Voucher Type</label>
                        <select  id="voucher_type" required name="voucher_type" onChange={handleChange2} value={inputs.voucher_type }className="form-control  col-sm-7 browser-default custom-select  mandatory-form-control" disabled="disabled">
                            <option value=""></option>
                            <option value="sales_order">Sales Order</option> 
                            <option value="delivery_challan">Delivery Challan</option>
                            <option value="cube_test_report">Cube Test Report</option>
                            <option value="invoice">Invoice</option>
                            <option value="receipt_voucher">Receipt</option>
                            <option value="credit_note">Credit Note</option>
                            <option value="quotation">Quotation</option>
                            <option value="purchase_order">Purchase Order</option>
                            <option value="goods_reciept_note">Goods Receipt Note</option>
                        </select><br/>

                        <label htmlFor="plant_id"className="form-group col-sm-5 text-right  ">Plant Location</label>
                        <select  id="plant_id"  name="plant_id" onChange={handleChange2} value={inputs.plant_id || "" }className="form-control  col-sm-7 browser-default custom-select  mandatory-form-control" disabled="disabled">
                            {/* <option value="" disabled="disabled"></option>  */}
                             <option value="all" disabled="disabled">All</option>
                                 <option value="-1">All</option>
                            {plants.map((item) => (
                                          <option key={item.value} value={item.value}>
                                            {item.label}
                                          </option>
                                        ))}
                        </select><br/>
        
                       
                  </div>
                  </Col>
          </Row> 
          {/*  */}
          <div className="form-row table-bordered table-light sky blue shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
        <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)"}}>
            <thead>
                  <tr>
                    <th><button className="btn btn-success" onClick={addTableRows} >+</button></th>
                    <th  colSpan="1">Prefix</th>
                    
                    <th  colSpan="1">Starting Number</th>
                    <th colSpan="1">Number of Digits</th>
                    <th  colSpan="1">Valid from date</th>
                    <th colSpan="1">Valid upto date</th>
                    <th colSpan="1">Remarks</th>
                  </tr>
            </thead>
            <tbody >
                <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
            </tbody>
        </table>
        </div>
          </div>
              <div className="footer text-center"><br/>
                  <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(42,245,39,0.8)"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;
                  <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter" onClick={view}>View</Button>&nbsp;&nbsp;
                  <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter" onClick={Back}>Home</Button>
                  <Button className="btn btn-delete"  type="button"style={{width:"80px",fontWeight:"bold"}}  onClick={onDeleteNumberSetting}>Delete</Button>&nbsp;&nbsp;    
              </div>
              </form> 
          </div><br/>
          {/* </div>  */}
    </>
  );
}
export default NumberSettingEdit;
