
import { getApiBaseUrl, getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
// export async function  getAllCheckIn(cookies) {
//     return getApiClient(cookies)
//     .get(`${apiBaseUrl}/checkin/`)
//     .then(response => response.data);
// }
export async function getAllCheckIn(cookies,inputs) {
  let url = `${apiBaseUrl}/checkin/?from_date=${inputs.from_date}&upto_date=${inputs.upto_date}`
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}

export async function createCheckIn(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/checkin/`,inputs)
  
}

export async function updateCheckIn(cookies, inputs, checkinId) {

    return getApiClient(cookies)
    .put(`${apiBaseUrl}/checkin/${checkinId}/`,inputs)
}
  
export async function getCheckInDetails(cookies, checkinId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/checkin/${checkinId}/`)
  .then(response => response.data)
}

export async function getCheckInNumber(cookies, plantId,movement_type) {

  return getApiClient(cookies).get(`${apiBaseUrl}/checkout/checkout_number/?plant_id=${plantId}&movement_type=${movement_type}`)
  .then(response => response.data)
}
export async function deleteCheckIn(cookies, checkinId) {

  return getApiClient(cookies).delete(
    `${apiBaseUrl}/checkin/${checkinId}/`)
   
}

export async function printDcCheckin(cookies, checkinId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/checkout/${checkinId}/print_dc/`)
              .then(response => response.data)
}









