import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import { getAllPlants} from '../../services/PlantServices';
import "../../App.css";
import React from 'react';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {Row,Col} from "reactstrap";
import { getAllSalesRepresentatives } from '../../services/SalesRepresentativeServices';
import { getAccountReceivableReport} from "../../services/ReportServices";
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import {  DatePicker } from 'antd';
import moment  from 'moment';


function AccountReceivableReportTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [plants, setPlants] = React.useState([]);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [selectedSalesrep, setSelectedSalesRep] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [salesreps, setSalesreps] = useState([]);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const {RangePicker} = DatePicker;
    const currentMonthStartDate = moment().startOf('month');
    const currentDate = moment();
  
    const [dates, setDates] = useState([currentMonthStartDate, currentDate]);
    const Option = (props) => {
        return (
            <>
                <components.Option {...props}>
                    <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}/>{" "}
                <label>{props.label}</label>
                </components.Option>
            </>
        );
    };
    React.useEffect(() => {
        
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                
                const plants = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.entity_name }
                    }
                );
                setPlants(plants);
            }
        )
    }, []);
    React.useEffect(() => {
        getAllSalesRepresentatives(cookies)
        .then (
            salesRepList => {
                
                const salesrep = salesRepList.salesrep_data.filter(obj => obj.status).map(
                  sales => {
                        return { value: sales.id, label: sales.entity_name }
                    }
                   
                );
                //console.log(JSON.stringify(salesRepList)+"salesRepList")
            
                setSalesreps(salesrep);
              
            }
        )
    }, []);
    React.useEffect(() => {
        getAllCustomerMain(cookies)
        .then (
            customerList => {
                
                const customers = customerList.customer_list.filter(obj => obj.status).map(
                    customer => {
                        return { value: customer.id, label: customer.name }
                    }
                );
                setCustomers(customers);
            }
        )
    }, []);
    const onPlantSelect = (selected) => {
        setSelectedPlant(selected);
    };
    const onSalesRepSelect = (selected) => {
        setSelectedSalesRep(selected);
    };
    const onCustomerSelect = (selected) => {
        setSelectedCustomer(selected);
    };
    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Partticulars'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Opening balance'
            },
           
            {
                fieldName: "address",
                headerName: 'Transactions'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Closing Balance'
            },
          
           
        ],
        [],);

   
    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    address: r.address,
                    phone_number:r.phone_number,
                    mobile_number:r.mobile_number,
                    status:r.status
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'AccountReceivable Report',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                address: r.address, 
                phone_number:r.phone_number,
                mobile_number:r.mobile_number,
                status:r.status
            })),
            'Account_rec.pdf'
        );
    };

    useEffect(() => {

        getAccountReceivableReport(cookies)
        .then( customerList => {
            const tableData = customerList.salesrep_data
            .map(customer => ({
                entity_name: customer.entity_name,
                address: `${customer.address_1},\n${customer.address_2},\n${customer.address_3}`,
                phone_number:customer.phone_number,
                mobile_number:customer.mobile_number,
                status:customer.status,
                id: customer.id,    
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Account Receivable Report</h3>
            <br/>
            <form className="form" onSubmit={""}>
        <div className="container">
        <Row>
          <Col xl={12} lg={12} md={12}>
            </Col>
           
          <Col xl={12} lg={12} md={12}>
          <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                               
          <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                  {/* <div style={{width: '58%'}} > */}
                                  <ReactSelect
                                    options={plants}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedPlant}
                                    onChange={onPlantSelect}
                                    className={"react-select-dropdown col-sm-3"} 
                                    placeholder="Please select Plant name"
                                  />
                                  {/* </div> */}
                                <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      
                                <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                 <ReactSelect
                                   options={salesreps}
                                   isMulti= {true}
                                   closeMenuOnSelect={false}
                                   hideSelectedOptions={false}
                                   components={{Option}}
                                   isClearable={true}
                                   // defaultValue={selectedOption}
                                   value={selectedSalesrep}
                                   onChange={onSalesRepSelect}
                                   className={"react-select-dropdown col-sm-3"} 
                                   placeholder="Please Select Sales Representative"
                                 />
                               
                               <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                 <ReactSelect
                                   options={customers}
                                   isMulti= {true}
                                   closeMenuOnSelect={false}
                                   hideSelectedOptions={false}
                                   components={{Option}}
                                   isClearable={true}
                                   // defaultValue={selectedOption}
                                   value={selectedCustomer}
                                   onChange={onCustomerSelect}
                                   className={"react-select-dropdown col-sm-3"} 
                                   placeholder="Please select Customer name"
                                 />
                              
                               <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-right"></label>
                                  <RangePicker 
                                        defaultValue={dates}
                                        format="DD-MM-YYYY"
                                    onchange={(values)=>{
                                      setDates(values.map(item=>{
                                        return moment(item.format('YYYY-DD-MM'))
                                      }))
                                    }}/>
                              
 
                <div>    
                    <Link  to={'/Search'} >
                        <i className=" form-group col-sm-3  fa fa-search fa-2x" id="myData" aria-hidden="true"></i>
                    </Link>
                </div> 

                <div>    
                    <Link  to={'/Search'} >
                        <i className=" form-group col-sm-3  fa fa-home fa-2x"id="myTable" aria-hidden="true"></i>
                    </Link>
                </div> 
                
          </div>
          </Col>
   
          </Row> 
        </div>
    
        </form>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='Equipment Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 

            </div>
                {/* </div> */}
                </>
    );

}

export default AccountReceivableReportTable;