import { useEffect, useState, useMemo } from "react";
import React from 'react';
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import {Button} from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import "../../App.css";
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import {Row,Col} from "reactstrap";
import { default as ReactSelect } from "react-select";
import {getUserReport,getUserReportCSV} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import { getAllUsers} from "../../services/UserServices";
import moment  from 'moment';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';
import {HandleSelect,ValueContainer,Option} from "../../components/MultiSelectControls";
const {RangePicker} = DatePicker;

function UserReport() 
{
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [users, setUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    // const [inputs, setInputs] = useState({});

    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
        setFromDate(values[0]);
        setToDate(values[1]);
        }
    };

    React.useEffect(() => {
        //Load data for Plant dropdown
        getAllUsers(cookies)
        .then (
            userList => {
                const users = userList.user_list.map(
                    user => {
                        return { value: user.user, label: user.username }
                    }
                );
                setUsers(users);
                setSelectedUser(values =>[...values,...users, ...usersWithSelectAll]);
            }
        )
    }, []);

    // Add a "Select All" option to the users array
    const usersWithSelectAll = [
        { value: "select-all", label: "Select All Users" },
        ...users,
    ];

    const onUserSelect = (selected) => {
        HandleSelect(selected,selectedUser,setSelectedUser, users, usersWithSelectAll);
    };

    const areAllUsersSelected = () => {
        // Check if all plants are selected
        console.log(JSON.stringify(users)+"users");
        console.log(JSON.stringify(selectedUser)+"selectedUser");
        // Check if users array is empty
        if (users.length === 0) {
            return false;
        }
        return users.every((user) =>
            selectedUser ? selectedUser.some((selected) => selected.value === user.value) : false
        );
    };

    const Home = () => {
        navigate('/Home')
    }
    
    const left_pinned = ["content_type"]
    const right_pinned = ["user_name"]
    
    const columns = useMemo(
        () => [
            {
                fieldName: 'user_ip_addr', 
                headerName: 'IP Address'
            },
            {
                fieldName: 'content_type', 
                headerName: 'Content Type'
            },
            {
                fieldName: 'action_done', 
                headerName: 'Action Done'
            },
            {
                fieldName: "sl_no",
                headerName: 'Sl No'
            },
            {
                fieldName: 'module_name', 
                headerName: 'Module Name'
            },
            {
                fieldName: "tracking_dtm",
                headerName: 'Tracking Datetime'
            },
            {
                fieldName: 'plant', 
                headerName: 'Plant/Company Name'
            },
            {
                fieldName: "user_name",
                headerName: 'User Name'
            },
            {
                fieldName: "location",
                headerName: 'Location'
            }
        ],
        [],);
   
    const handleCSVExport = (event) => {
    };

    const handlePDFExport = (event) => {
    };

    const constructFormData = () => {
        console.log(JSON.stringify(areAllUsersSelected())+"areAllUsersSelected constructFormData")
        const EMPTY_STRING = '';
        return {
            user_ids:areAllUsersSelected()? '*' // Send '*' if all users are selected
            : selectedUser.map((user) => user.value).join(','),
            from_date:fromDate.format('DD-MM-YYYY'),
            upto_date:toDate.format('DD-MM-YYYY')
          }
    }
    const handleDownloadCsv = () => {
        // console.log(JSON.stringify(constructFormData())+"submit")
        const inputs = constructFormData()
        if ((inputs.user_ids)  &&
        (inputs.from_date) && (inputs.upto_date))
        {
            getUserReportCSV(cookies, constructFormData())
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'user_report.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.log(error.response.data);
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("user,from_date,upto_date must not be empty.", "", "info");  
        }
    };

    const handleSubmit=(event)=> 
    {
        event.preventDefault();
        console.log(JSON.stringify(constructFormData())+"submit")
        const inputs = constructFormData()
        if ((inputs.user_ids)  &&
        (inputs.from_date) && (inputs.upto_date))
        {
            getUserReport(cookies, constructFormData())
            .then(response => {
                // console.log(JSON.stringify(response.user_report_list)+"response.user_report_list");
                const tableData = response.user_report_list
                .map(user => ({
                    id:user.id,
                    user_ip_addr: user.user_ip_addr,
                    content_type: user.content_type,
                    action_done: user.action_done,
                    sl_no: user.sl_no,
                    module_name:user.module_name,
                    tracking_dtm: user.tracking_dtm,
                    plant: user.plant,
                    user_name: user.user_name,
                    location: user.location
                }));
                setData({
                    total: 0,
                    records: tableData
                });
                setIsLoading(false);
            }).catch((error) => {
                console.log(error+"error");
                // console.log(error.response.data);
                // displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("user,from_date,upto_date must not be empty.", "", "info");  
            setData({
                total: 0,
                records: []
            });
            // setIsLoading(true);
        }
    }

    return (
            <>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="container"> 
                        <Row> 
                       <Col xl={2} lg={12} md={12}></Col>
                            <Col xl={8} lg={12} md={12}>
                                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                                        <ReactSelect
                                            options={usersWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedUser}
                                            onChange={onUserSelect}
                                            className="react-select-dropdown col-sm-4"
                                            placeholder="Please select User"
                                        />
                                        <br/><br/>

                                        <RangePicker
                                            defaultValue={[initialFromDate, initialToDate]}
                                            format="DD-MM-YYYY"
                                            onChange={handleDateChange}
                                            style={{height:"40px",width:"270px"}}
                                        />
                                        &nbsp;&nbsp;&nbsp;

                                        <Button  type="submit"
                                            style={{backgroundColor: "RGB(188,232,253)",fontWeight:"bold",
                                            height:"30px",display: "flex",
                                            alignItems: "center",  
                                            justifyContent: "center"}}>
                                            <i className="fa fa-search fa-2x" id="myData" aria-hidden="true" style={{color:"rgb(93, 156, 233)"}}></i>
                                        </Button> 
                                        <Button color="primary" onClick={handleDownloadCsv} startIcon={<FileDownloadRoundedIcon/>}  variant="contained">CSV</Button>
                                        &nbsp;&nbsp; 
                                </div>
                             </Col>
                        </Row> 
                    </div> 
                </form>
                <div className="container item-list-table-container">
                    <div className="table-responsive">
                        <ReactTableWrapper
                            title='CustomerData Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            left_pinned={left_pinned}
                            right_pinned={right_pinned}
                            enableExportToolbar={false}
                        />
                    </div>
                </div> 
            </>
);
}

export default UserReport;