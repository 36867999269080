import React from 'react';
import { useState , useRef} from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import PasswordStrengthMeter from '../../PasswordStrengthMeter';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import {displayError, displayErrorToast} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getAllPermission } from '../../services/PermissionServices';
import { getAllPlants} from '../../services/PlantServices';
import { createUser, getAllUsers} from '../../services/UserServices';
import { isPasswordValid, isUserNameValid } from './UserUtils';
import { checkForDuplicateUsers } from './UserUtils';
const Option = (props) => {
    return (
        <>
            <components.Option {...props}>
                <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}/>{" "}
            <label>{props.label}</label>
            </components.Option>
        </>
    );
};

function User() {
  
  const [cookies] = useCookies(['myToken']);
  const [inputs, setInputs] = useState({
    userName:'',
    first_name:'',
    last_name:'',
    Password:'',
    userPassword:'',
    userEmail:'',
    is_superuser:'',
    userMobileNo:'',
    userCategory:'',
    uservalid_from:'',
    uservalid_upto:'',
    userRemarks:'',
    is_active:'true'
  });
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [items, setItems] = React.useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [categories, setCategories] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [userFormData, setUserFormData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [plants, setPlants] = React.useState([]);
  const [selectedPlant, setSelectedPlant] = useState(null);
    React.useEffect(() => {
      getAllPermission(cookies)
      .then (
          categoryList => {
            // .filter(obj => obj.status)
              const categories = categoryList.group_list.map(
                  category => {
                      return { value: category.id, label: category.name }
                  }
              );
              console.log(JSON.stringify(categories)+"categories")
              setCategories(categories);
          }
      )
  }, []);
  React.useEffect(() => {
        
    //Load data for Plant dropdown
    getAllPlants(cookies)
    .then (
        plantList => {
            
            const plants = plantList.plant_list.filter(obj => obj.status).map(
                plant => {
                    return { value: plant.id, label: plant.plant_alias }
                }
            );
            setPlants(plants);
        }
    )
}, []);

const onCategorySelect = (selected) => {
    setSelectedCategory(selected);
};

const onPlantSelect = (selected) => {
    setSelectedPlant(selected);
};
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
 try{  
        if(name === 'userName')         //User name must not be empty,otherwise showing error message 
        {
        
          if(!value.trim())
          {
            setError(values => ({...values, [name]: "User Name must not be empty."}))
            setIsSubmitting(false); 
          }
          else
          {

            if(!isUserNameValid(value))
            {
              setError(values => ({...values, [name]: "Invalid User Name.Only Uppercase,lowercase,numbers,_.+@ are allowed."}))
              setIsSubmitting(false); 
            } else {
              setError(values => ({...values, [name]: ''}))
              setIsSubmitting(true);
            }
              
                 
          }
        }
        else if(name === 'userEmail')
        {
          if(value.trim())
          {
            if(!/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value.trim()))
            {
              setError(values => ({...values, [name]: "Invalid Email Id."}))
              setIsSubmitting(false); 
            }
            else
            {
              setError(values => ({...values, [name]: ''}))
              setIsSubmitting(true);
            }
          }
          else
          {
            setError(values => ({...values, [name]: 'Please give Email id.'}))
            setIsSubmitting(false);
          }
        }
        setInputs(values => ({...values, [name]: value}))
     }
      catch(e)
      {
        displayErrorToast(e);
      }
        
  }
      

  const handlePasswordField = (event) => {
    const name = event.target.name;
    const value = event.target.value.split(" ").join("");
    setInputs(values => ({...values, [name]: value}))

  try{
      if(name === 'Password')   //User password must not be empty,otherwise showing error message 
      {
      
        if(event.target.value.split(" ").join("") === '')
        {
          console.log("psw data is empty");
          setError(values => ({...values, [name]: "Password must not be empty."}))
          setIsSubmitting(false); 
        }
        else
        {
          const passwordInvalidMessage = isPasswordValid(value)
          if (passwordInvalidMessage) {
              setError(values => ({...values, [name]: passwordInvalidMessage}))
              setIsSubmitting(true);
          }
          else
          {
              setError(values => ({...values, [name]: ''}))
              setIsSubmitting(true);
          }
        }
      }
      else if(name === 'userPassword')
      {
        if(event.target.value.split(" ").join("") === '')   //Userconfirm password must not be empty & mismatch password,otherwise showing error message 
        {
          console.log("confirm psw data is empty");
          setError(values => ({...values, [name]: "Confirm Password must not be empty."}))
          setIsSubmitting(false); 
        }
        else
        {
          let pass=inputs.Password;
          console.log(pass+"first pass");
          if(value != pass)
          {
            console.log("password mismatch");
            setError(values => ({...values, [name]: "Password mismatch."}))
            setIsSubmitting(false); 
          }
          else
          {
            setError(values => ({...values, [name]: ''}))
            setIsSubmitting(true);
          }
        }
      }
    }
    catch(e)
    {
      displayErrorToast(e);
    }
    }
  
  const [isStrength, setStrength] = useState(null);
  const dataHandler = async (childData) => {
    setStrength(childData);
  }

  
  const handleCreate=(event)=> {
   try{
    console.log(selectedCategory+"selectedCategory.length");
          if ((Object.values(error).every(x => !x)) && isSubmitting && (selectedCategory) && (selectedPlant)) {   //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
                  Swal.fire({title: 'Do you want to save?',  
                  showCancelButton: true,  
                  confirmButtonText: `Yes`,  
                  cancelButtonText: `No`,
                  }).then((result) => {  
                    if (result.isConfirmed) { 
                      createUser(cookies, {
                        username:inputs.userName,
                        first_name:inputs.first_name,
                        last_name:inputs.last_name,
                        password:inputs.userPassword,
                        email:inputs.userEmail,
                        is_mfa_needed:inputs.is_mfa,
                        is_location_auth_needed:inputs.is_location_auth_needed,
                        is_active:inputs.is_active,
                        user_mobile_no:inputs.userMobileNo,
                        groups:selectedCategory.map(category=>category.value),
                        user_valid_from:inputs.uservalid_from,
                        user_valid_upto:inputs.uservalid_upto,
                        user_remarks:inputs.userRemarks,
                        plants  :selectedPlant.map(plant=>plant.value),
                      })
                      .then((response) =>
                      {
                        console.log(JSON.stringify(response)+"response_user");
                        Swal.fire('Saved!', '', 'success') 
                      // Reload();
                        Cancel();
                      }).catch((error) =>
                      {
                        console.log(error.response.data);
                        displayError(error.response.data,"Save Failed");
                      })
                      
                    } 
                    else if (result.isDismissed) 
                    {    
                      Swal.fire('Not saved', '', 'info')  
                    }
                  });
            }
            else
            {
              Swal.fire('Please check * marked fields are filled and details given are valid...', '', 'info')   
            }
        }
          catch(e)
          {
            displayErrorToast(e);
          }
      } 

      const handleSubmit = (event) => {
        event.preventDefault();
       
        try{
          checkForDuplicateUsers(event.target.userName.value,event.target.userEmail.value,cookies,null)
          .then(isNameExists => {
              
            if (isNameExists) {
              setError(values => ({...values, [event.target.userName.name]: 'User name already exists.'}));
            } else {
    
              if (Object.values(error).every((x) => !x) && isSubmitting) {        //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
                
                  handleCreate();
                
              } else {
                Swal.fire("Please check * marked fields are filled and details given are valid...","","info" );
              }
            }
        }); 
      } catch (e) {
          displayErrorToast(e);
      } 
            
     } 

    const Reload = () => {
      window.location.reload();
    }      
    
    const view = () => {
      navigate('/UserTable')
      
    }        
    
    const Back = () => {
      navigate('/Home')
    }

    const Cancel = () => {
      setInputs({
        userName:'',
        first_name:'',
        last_name:'',
        Password:'',
        userPassword:'',
        userEmail:'',
        group_ids_list:'',
        userMobileNo:'',
        is_mfa:'',
        uservalid_from:'',
        uservalid_upto:'',
        userRemarks:'',
        is_active:'true',
        is_location_auth_needed:''
      })
}
  return (
    <>
    {/* <div id="outer-container"  className="App" >  */}
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
            <form className="form" onSubmit={handleSubmit}>
                <div id="header"> 
                <h3 className = "text font-weight-bold page-title">USER REGISTRATION FORM</h3>
                </div>
                <FloatingControls tableLink="/UserTable" onCancel={Cancel} enableCancel={true}/>
                <div className="container"  > 
                    <Row> 
                        <Col xl={6} lg={12} md={12}>
                            <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel" >
                            <h5 className='col-sm-11'><b>User Details</b></h5><br/><br/>
                                <label htmlFor="userName"className="form-group col-sm-4 text-right  ">User Name </label>
                                <input type="text" id="userName" name="userName"  onChange={handleChange} value={inputs.userName || ""} className="form-control col-sm-7 mandatory-form-control"  placeholder="Please fill the NAME field *"  required />
                                {error.userName && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.userName}</strong><br/></>)}
                               
                                <label htmlFor="first_name"className="form-group col-sm-4 text-right ">First Name</label>
                                <input type="text" id="first_name" name="first_name"   onChange={handleChange} value={inputs.first_name || ""} className="form-control col-sm-7 "  placeholder="Please fill the First Name Field *" />
                                
                                <label htmlFor="last_name"className="form-group col-sm-4 text-right  ">Last Name</label>
                                <input type="text" id="last_name" name="last_name"  onChange={handleChange} value={inputs.last_name || ""} className="form-control col-sm-7 "  placeholder="Please fill the Last Name Field *" />
                                
                                <label htmlFor="Password" className="form-group col-sm-4 text-right">Password </label>
                                <input type="password" className="form-control col-sm-7 mandatory-form-control" id="Password" onChange={handlePasswordField} value={inputs.Password || ""} name="Password" required maxLength="8" />
                                {error.Password && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.Password}</strong><br/></>)} 
                                <PasswordStrengthMeter password={inputs.Password} actions={dataHandler}/>
                                
                                <label htmlFor="userPassword" className="form-group col-sm-4 text-right">Confirm Password </label>
                                <input type="password" className="form-control col-sm-7 mandatory-form-control" id="userPassword" onChange={handlePasswordField} value={inputs.userPassword || ""} required name="userPassword" maxLength="8" />
                                {error.userPassword && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.userPassword}</strong><br/></>)} 

                                <label htmlFor="userEmail"className="form-group col-sm-4 text-right">Email id </label>
                                <input type="email" id="userEmail" name="userEmail" onChange={handleChange} value={inputs.userEmail || ""} className="form-control col-sm-7 mandatory-form-control" required />
                                {/* <div class="invalid-feedback d-block"><p id="invalid_email" style="display:none;">This E-mail id is already registered!</p></div> */}
                                {error.userEmail && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.userEmail}</strong><br/></>)}

                                <label htmlFor="is_location_auth_needed" className="form-group col-sm-4 text-right">Is Location Authentication Needed? </label>
                                <select className="browser-default custom-select form-control col-sm-7 mandatory-form-control" id="is_location_auth_needed" onChange={handleChange} value={inputs.is_location_auth_needed || ""}  name='is_location_auth_needed' required >
                                    <option value=''>Please Select</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={12}>
                            <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel " >
                            <h5 className='col-sm-11'><b>User Vaild Date</b></h5><br/><br/>
                              <label htmlFor="is_mfa" className="form-group col-sm-4 text-right">Is MFA Needed? </label>
                                <select className="browser-default custom-select form-control col-sm-7 mandatory-form-control" id="is_mfa" onChange={handleChange} value={inputs.is_mfa || ""}  name='is_mfa' required >
                                    <option value=''>Please Select</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>

                                <label htmlFor="Checkbox"className="form-group col-sm-4 text-right ">Plant Location </label>
                                  <div style={{width: '58%'}} >
                                  <ReactSelect
                                    options={plants}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedPlant}
                                    onChange={onPlantSelect}
                                    className={"mandatory-form-control"}
                                    placeholder="Please select Plant name"
                                  />
                                  </div>
                                
                                <label htmlFor="userMobileNo"className="form-group col-sm-4 text-right">Mobile No</label>
                                <input type="text" id="userMobileNo"name="userMobileNo" onChange={handleChange} value={inputs.userMobileNo || ""}   className="form-control col-sm-7 " />
                                {/* <input type="tel" name="userMobileNo"  pattern="[0-9]{10}"  className="form-control col-sm-7 " ><br>  */}
                               
                                <label htmlFor="userCategory"className="form-group col-sm-4 text-right">Category</label>
                                <div style={{width: '58%'}} >
                                <ReactSelect
                                    options={categories}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedCategory}
                                    onChange={onCategorySelect}
                                    className={"mandatory-form-control"}
                                    placeholder="Please select Category"
                                  />
                                  </div>

                                <label htmlFor="uservalid_from"className="form-group col-sm-4 text-right  ">Valid From Date</label>
                                <input type="date" id="uservalid_from" name="uservalid_from"onChange={handleChange} value={inputs.uservalid_from || ""}  className="form-control col-sm-7 rounded mandatory-form-control" required maxLength="50" />
                                
                                <label htmlFor="uservalid_upto" className="form-group col-sm-4 text-right  ">Valid Upto Date </label>
                                <input type="date" id="uservalid_upto" name="uservalid_upto" onChange={handleChange} value={inputs.uservalid_upto || ""} className="form-control col-sm-7 rounded mandatory-form-control" required  maxLength="50" />
                                
                                <label htmlFor="userRemarks"className="form-group col-sm-4 text-right  ">Remarks</label>
                                <input type="text" id="userRemarks" name="userRemarks"onChange={handleChange} value={inputs.userRemarks || ""}  className="form-control col-sm-7" />
                                
                                <label htmlFor="is_active"className="form-group col-sm-4 text-right  ">Status </label>
                                <select required name="is_active" id="is_active" onChange={handleChange} value={inputs.is_active || "true"} className="browser-default custom-select col-sm-7 mandatory-form-control">
                                    <option value='true'>Active</option>
                                    <option value='false'>Inactive</option>
                                </select> 
                            </div>
                        </Col>
                    </Row> 
                </div>
                
                <div className="footer text-center"><br/>
                    <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">Save</Button> &nbsp;&nbsp;
                    <Button type="button" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Cancel</Button> &nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                    <Button className="btn btn-twitter" type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
                {/* </div> */}
            </form><br/>
        </div><br/>
    {/* </div>  */}
    </>
  );
}
export default User;
