import React from 'react';
import { useState ,useEffect} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import {useLocation ,Link} from 'react-router-dom';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import { getAllEquipments} from '../../services/EquipmentServices';
import { getAllUsers, getUserDetails } from '../../services/UserServices';
import {getCustomerMainDetails } from '../../services/CustomerMainServices';
import { getDisplayDate } from '../../utils/DateUtils';
import {getUserAllowedPlants } from '../../services/PlantServices';
import{getWorkScheduleJobNumber,getAllWorkSchedulesForPumpClearance, getWorkScheduleDetails} from '../../services/WorkScheduleServices';
import { createPumpClearance } from '../../services/PumpClearanceServices';
import FloatingControls from '../../components/FloatingControls';
import { getAllCustomerSites,getCustomerSiteDetails } from '../../services/CustomerSiteServices';
import SummaryIcon from '../../components/SummaryIcon';
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import { getAllGangs } from "../../services/GangServices";
import LoadingOverlay from '../../components/LoadingOverlay';
function PumpClearance() {
    const location = useLocation();
    const [cookies] = useCookies(['myToken']);
    const [plant, setPlant] = React.useState([]);
    const [category] = useCookies(['myCategory']);
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const[selectedCustomer,setSelectedCustomer] = useState({});
    const[selectedCustomerSite,setSelectedCustomerSite] = useState({});
    const [superuser] = useCookies(['mySuperuser']);
    const [sites, setSites] = React.useState([]);
    const[workschedules,setAllWorkschedules] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [workschedule,setWorkschedule]=React.useState([]);
    const [gang, setGang] = useState([]);
    const [isLoading, setIsLoading] = useState(true);  
    const [towingvehicle, setTowingVehicle] = useState([]);
    const [userId] = useCookies(['myUserId']);
    const [filteredWorkschedule, setFilteredWorkschedule] = React.useState([]);
    const[selectedWorkschedule,setSelectedWorkshedule] = useState({});
   
   
    const [inputs, setInputs] = useState({});
   
    const[allWorkshedule,setAllWorkschedule] = useState([]);


    React.useEffect(() => {
        // getAllPlants(cookies)
        getUserAllowedPlants(cookies)
        .then (
            plantList => {
                
                const plant = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlant(plant);
            }
        )
    }, []);
    useEffect( () => {
        getAllEquipments(cookies)
        .then(response => {
      const equipment =response.equipment_list.filter(obj => ((obj.equip_type.name === 'PUMP')&&
      (obj.equip_name != 'MANUAL')&&( obj.status)))
      setEquipment(equipment);
    //   console.log(JSON.stringify(equipment)+"equipment")
        });
      
      }, []);
      useEffect( () => {
        getAllGangs(cookies)
        .then(response => {
      const gang =response.gang_list.filter(obj => ((obj.entity_name != 'MANUAL')&&
      ( obj.status)))
      setGang(gang);
    //   console.log(JSON.stringify(gang)+"gang")
        });
      
      }, []);
      useEffect( () => {
        getAllEquipments(cookies)
        .then(response => {
      const Vehicle =response.equipment_list.filter(obj => ((obj.equip_type.name === 'TOWING VEHICLE')&&
      ( obj.status)))
      setTowingVehicle(Vehicle);
    //   console.log(JSON.stringify(Vehicle)+"Vehicle")
        });
      
      }, []);



      const handleWorkscheduleFiltering = (siteId) => {

        setFilteredWorkschedule(workschedule
        .filter(salesorder => salesorder.consignee.id===parseInt(siteId))
        .map(
            salesorder => {
                    return { value: salesorder.id, label:'' }
                }
            )); 
             //Get the details of the customber using the Id
        
    }
    
    
      const navigate = useNavigate();
      const [inProgress, setInProgress] = useState(false);
      const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
       
        try{
            setInputs(values => ({...values, [name]: value}));
            if(name === 'plant_id' && value){
                setIsLoading(true);
                getAllWorkSchedulesForPumpClearance(cookies,value)
                .then( workscheduleList => {
                    //console.log(JSON.stringify(workscheduleList)+"HC_wrkshedulelist")
                
                        const workschedules = workscheduleList.work_schedule_list.map(
                            workschedule => {
                                return { value: workschedule.id,label: workschedule.schedule_no + "-" + workschedule.sales_order_detail.sales_order.prefix + workschedule.sales_order_detail.sales_order.order_no + "(" + workschedule.sales_order_detail.product.name + ")"}
                            }
                        );
                      
                        setWorkschedule(workschedules);
                        setAllWorkschedule(workscheduleList.work_schedule_list)
                
                })
                .catch((error) =>
                {
                    setIsLoading(false);
                  console.log(error.response.data);
                  displayError(error.response.data," Failed");
                })
            } else if (name === 'plant_id' && !value) {
                setInputs(values => ({...values, ['schedule_no']: ''}));
            }
          }
         catch (e) {
        
          displayErrorToast(e);
        }
      }
         
      const showCustomerDetails = () => {
         //console.log(selectedWorkschedule+"selectedWorksheduleShowCustomerDetails")
        if (selectedWorkschedule.id) {
            getCustomerMainDetails(cookies,selectedWorkschedule.sales_order_detail.sales_order.consignee.customer.id)
            .then(selectedCustomer => {
                //console.log(selectedCustomer+"selectedCustomer")
                Swal.fire({
                    html:getHTMLForSummaryPopup(
                        'BILLING DETAILS',
                        [
                        {label: 'BILLING NAME', value: selectedCustomer.name},
                        {label: 'SALES OFFICER', value: selectedCustomer.salesrep.name}
                        ])
                }, '', 'info');
            })
                 
        }
        
   }


   const showWorkscheduleDetails = () => {

    if (selectedWorkschedule.id) {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'WORKSCHEDULE DETAILS',
                    [
                    {label: 'SO NUMBER', value: selectedWorkschedule.sales_order_detail.sales_order.order_no},
                    {label: 'SO DATE', value: selectedWorkschedule.sales_order_detail.sales_order.order_date},
                    {label: 'DELIVERY DATE', value: selectedWorkschedule.delivery_date},
                    {label: 'DELIVERY TIME', value: selectedWorkschedule.delivery_time},
                    {label: 'SCHEDULE QTY', value: selectedWorkschedule.scheduled_qty},
                    {label: 'MODE OF DELIVERY', value: selectedWorkschedule.delivery_mode},
                    {label: 'GRADE', value: selectedWorkschedule.sales_order_detail.product.name},
                   
                    ])
            }, '', 'info');   
    }
    
}
const handleSubmit=(event)=> {
    event.preventDefault();

    if ((Object.values(error).every(x => !x)) && isSubmitting) //If all the required fields are field u can select the save button then choose the option yes or no
    {
        Swal.fire({title: 'Do you want to save?',  
          showCancelButton: true,  
          confirmButtonText: `Yes`,  
          cancelButtonText: `No`,
          }).then((result) => {  
            if (result.isConfirmed) { 
                //console.log(JSON.stringify(inputs)+"inputsubmit")
                createPumpClearance(cookies, {
                    
                    id:parseInt(inputs.id),
                    pump: {
                        id: parseInt(inputs.pump_id),
                        starting_hr: parseInt(inputs.pump_starting_hr)
                      },
                              
                                gang_id:parseInt(inputs.gang_id),
                                towing_vehicle: {
                                    id: parseInt(inputs.towing_vehicle_id),
                                    starting_km: parseFloat(inputs.towing_vehicle_starting_km)
                                  },
                       
                                operator_name:inputs.operator_name,
                                technician_name:inputs.technician_name?inputs.technician_name:'',
                                supervisor_name:inputs.supervisor_name?inputs.supervisor_name:'',
                                incharge_name:inputs.incharge_name,
                                incharge_mobile_number:((inputs.incharge_mobile_number)?  inputs.incharge_mobile_number:"" ),
                                no_of_bends:parseInt(inputs.no_of_bends),
                                no_of_pipes:parseInt(inputs.no_of_pipes),
                                clearance_date:getDisplayDate(inputs.clearance_date),
                                clearance_time:inputs.clearance_time,
                                consignee_starting_time:inputs.consignee_starting_time   
                             })
               //console.log(createPumpClearance+"createPumpClearance")
              .then(response => {
                  Swal.fire("Saved!", "", "success");
                  view();
              }).catch((error) => {
                  console.log(error.response.data+"error.response.data");
                  displayError(error.response.data,"Saved Failed");
              });
              
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not saved', '', 'info')  
            }
          });
    }

  }

const handleChangeCustomerDetails = (event) => {
       
    //console.log(event.target.value);
    const customerId = event.target.value;
    const propertyName = event.target.name;
    setInputs(values => ({...values, [propertyName]:customerId}));

    //Get the details of the customber using the Id
    getCustomerMainDetails(cookies,customerId)    
    .then(customerObject => {  
        //console.log(JSON.stringify(customerObject)+"customerObject")
                    //Set the details of the customer in the form fields
        setSelectedCustomer(customerObject)})  
        getAllCustomerSites(cookies)

        .then (
            siteList => {
                
                const sites = siteList.consignee_list.filter(obj => obj.status && obj.customer.id === parseInt(customerId)).map(
                    site => {
                        return { value: site.id, label: site.name }
                    }
                );
                setSites(sites);
            }
        )   
}

const handleChangeWorkscheduleDetails = (event) => {
    const salesorderId = event.target.value;
    const propertyName = event.target.name;
    if (salesorderId) {
        
        setInputs(values =>({...values,[propertyName]:salesorderId}));
        const selected_wrk = allWorkshedule.filter(obj => obj.id == salesorderId)[0];
        setSelectedWorkshedule(selected_wrk);
        //console.log(selected_wrk+"selected_wrk")
        //console.log(JSON.stringify(allWorkshedule)+"allWorkshedule")
    } 
    else {  
        setSelectedWorkshedule({}); 
    }
    setInputs(values => ({...values, [propertyName]:salesorderId}));
  } 


const showSiteDetails = () => {

    if (selectedWorkschedule.id) {
        getCustomerSiteDetails(cookies,selectedWorkschedule.sales_order_detail.sales_order.consignee.id)
        .then(consignee => {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'SITE DETAILS',
                    [
                    {label: 'SITE NAME', value: consignee.name},
                    {label: 'ADDRESS', value: consignee.address_1},
                    {value: consignee.address_2},
                    {value: consignee.address_3},
                    {label: 'PROJECT/SITE NAME', value: consignee.project_name}
                    ])
            }, '', 'info');   
        })
          
    }
    
}
const handleChangeSiteDetails = (event) => {
     
    //console.log(event.target.value);
    const siteId = event.target.value;
    const propertyName = event.target.name;
    setInputs(values => ({...values, [propertyName]:siteId}));

    //Get the details of the customber using the Id
    getCustomerSiteDetails(cookies,siteId)
    .then(siteObject => {                     //Set the details of the customer in the form fields
        setSelectedCustomerSite(siteObject)});
        handleWorkscheduleFiltering(siteId);
} 

const showWorksheduleDetails = () => {

    if (setSelectedWorkshedule.id) {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'SALESORDER DETAILS',
                    [
                    {label: 'SO DATE', value: setSelectedWorkshedule.order_date},
                    {label: 'MODE OF TRANSPORT', value: setSelectedWorkshedule.transport_mode},
                    {label: 'DELIVERY DATE', value: setSelectedWorkshedule.delivery_date},
                    {label: 'REMARKS', value: setSelectedWorkshedule.user_remarks}
                  
                    ])
            }, '', 'info');   
    }
    
}
        const view = () => {
          navigate('/PumpClearanceTable')
        }  

        const Reload = () => {
            window.location.reload();
          } 
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
            setInputs(() => "")
    
        }
    return (
    <>
     {/* <div id="outer-container"  className="App" >  */}
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit} > 
          <div id="header">
              <h3 className = "text font-weight-bold page-title" >PUMP CLEARANCE FORM</h3>
          </div>
         
          <FloatingControls tableLink="/PumpClearanceTable" onCancel={Cancel} enableCancel={true}/>
                
            <div className="container"> 
                <Row> 
                <Col xl={12} lg={12} md={6} >   
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                    <h5 className='col-sm-11'><b>Workshedule Details</b></h5><br/><br/>
                    <label htmlFor="plant_id" className="form-group col-sm-2 text-right"> Plant Location </label>
                        <select id="plant_id" name="plant_id" className="form-control col-sm-3  browser-default custom-select  mandatory-form-control"   onChange={handleChange} value={inputs.plant_id || ""}>
                          <option value="">Select Plant</option>
                                    {plant.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}</option>
                            ))}
                        </select>
                        <br></br>      <br></br>
                        <label htmlFor="id" className="form-group col-sm-2 text-right"> Job No </label>
                        <select id="id" name="id" className="form-control col-sm-3  browser-default custom-select  mandatory-form-control"  required onChange={handleChangeWorkscheduleDetails} value={inputs.id || ""}>
                      
                          <option value="">Select Job No</option>
                          {workschedule.map((item) => (
                                  <option key={item.value} value={item.value}>
                                  {(item.label)}
                                  </option>
                              ))}
                        </select>
                        <SummaryIcon onClickHandler={showWorkscheduleDetails}/>
                        <br/>         
                        {(selectedWorkschedule.id ? 
                        <table width="100%" style={{border: "0px solid black"}}>
                        <tr style={{border: "0px solid black"}}>
                        <td className="col-sm-2 text-right" style={{border: "0px solid black"}} onChange={handleChangeCustomerDetails}>Billing Name:</td>
                        <td style={{border: "0px solid black", fontWeight: "normal"}}> {selectedWorkschedule?.sales_order_detail?.sales_order?.consignee?.customer?.name} 
                        <SummaryIcon onClickHandler={showCustomerDetails}/></td>
                        
                        <td className="col-sm-2 text-right" style={{border: "0px solid black"}}  onChange={handleChangeSiteDetails}>Project/Site Name:</td>
                        <td style={{border: "0px solid black", fontWeight: "normal"}}> {selectedWorkschedule?.sales_order_detail?.sales_order?.consignee?.name} 
                        <SummaryIcon onClickHandler={showSiteDetails}/></td>
                        </tr>
                        </table>
                           : null)}  
                    </div>


                </Col>

            </Row>
            {/* <div id="header">
                <h4 className = "text font-weight-bold page-title"><b>PUMP BOOKING & SITE DETAILS </b></h4>
            </div> */}
            <Row> 
                <Col xl={6} lg={12} md={12}> 
                   <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel" >
                   <h5 className='col-sm-11'><b>Site Details</b></h5><br/><br/>
                        <label htmlFor="clearance_date" className="form-group col-sm-4 text-right">Clearance Date </label>
                        <input type="date" required name="clearance_date" id="clearance_date" onChange={handleChange} value={inputs.clearance_date || ""}className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="clearance_time" className="form-group col-sm-4 text-right">Time </label>
                        <input type="time" id="clearance_time"required name="clearance_time" onChange={handleChange} value={inputs.clearance_time || ""}className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="incharge_name" className="form-group col-sm-4 text-right">Incharge Name</label>
                        <input type="text"id="incharge_name" name="incharge_name" onChange={handleChange} value={inputs.incharge_name || ""}className="form-control col-sm-7"/>
                        
                        <label htmlFor="incharge_mobile_number" className="form-group col-sm-4 text-right">Mobile No.</label>
                        <input type="text" id="incharge_mobile_number"name="incharge_mobile_number" onChange={handleChange} value={inputs.incharge_mobile_number || ""}className="form-control col-sm-7"/>
                        
                        <label htmlFor="consignee_starting_time" className="form-group col-sm-4 text-right">Site Starting Time </label>
                        <input type="time" id="consignee_starting_time"name="consignee_starting_time"onChange={handleChange}  value={inputs.consignee_starting_time || ""} required className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="consignee_ending_time" className="form-group col-sm-4 text-right">Site Closing Time</label>
                        <input type="time" name="consignee_ending_time" className="form-control col-sm-7" readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}}/>
                    </div>  

                    <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel">
                    <h5 className='col-sm-11'><b>Pump Operator Details</b></h5><br/><br/>
                        <label htmlFor="operator_name" className="form-group col-sm-4 text-right">Operator Name </label>
                        <input type="text" id="operator_name" onChange={handleChange} value={inputs.operator_name || ""} required name="operator_name" className="form-control col-sm-7 mandatory-form-control"/>
                       
                        <label htmlFor="technician_name" className="form-group col-sm-4 text-right">Technician Name </label>
                        <input type="text"id="technician_name" onChange={handleChange} value={inputs.technician_name || ""}  name="technician_name" className="form-control col-sm-7"/>
                       
                        <label htmlFor="supervisor_name" className="form-group col-sm-4 text-right">Supervisor Name </label>
                        <input type="text"id="supervisor_name"onChange={handleChange}  value={inputs.supervisor_name || ""}  name="supervisor_name" className="form-control col-sm-7"/>
                    </div> 
                </Col>
                <Col xl={6} lg={12} md={12}> 
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel" >
                <h5 className='col-sm-11'><b>Pump Number Details</b></h5><br/><br/>
                        <label htmlFor="pump_id" className="form-group col-sm-4 text-right">Pump No </label>
                        <select required id="pump_id" onChange={handleChange} value={inputs.pump_id || ""} name="pump_id" className="form-control col-sm-7 browser-default custom-select mandatory-form-control" >
                            <option value="">Please select Pump</option>
                            {equipment.map((item) => (
                                <option key={item.id} value={item.id}>{item.equip_name}</option>
                            ))}
                        </select>
                        
                        <label htmlFor="gang_id" className="form-group col-sm-4 text-right">Gang Name </label>
                        <select required id="gang_id" onChange={handleChange}value={inputs.gang_id || ""} name="gang_id" className="form-control col-sm-7 browser-default custom-select mandatory-form-control" >
                            <option value="">Please select Gang</option>
                            {gang.map((item) => (
                                <option key={item.id} value={item.id}>{item.entity_name}</option>
                            ))}
                        </select>
                        
                        <label htmlFor="pipe_length"className="form-group col-sm-4 text-right">Total Pipes/Bends </label>
                        <input required type="number" min="0"value={inputs.no_of_pipes || 0} onChange={handleChange} className="form-control col-sm-4  mandatory-form-control" id="no_of_pipes"name="no_of_pipes"/>
                        <input  required type="number" min="0"value={inputs.no_of_bends || 0} onChange={handleChange} className="form-control col-sm-3  mandatory-form-control" id="no_of_bends" name="no_of_bends"/>
                       
                  
                        <label htmlFor="pump_starting_hr" className="form-group col-sm-4 text-right">Pump Starting Hour </label>
                        <input required type="number"id="pump_starting_hr"onChange={handleChange} value={inputs.pump_starting_hr || ""} name="pump_starting_hr" className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="pump_ending_hr" className="form-group col-sm-4 text-right">Pump Ending Hour</label>
                        <input  type="number"id="pump_ending_hr" name="pump_ending_hr" className="form-control col-sm-7" readOnly={true} style={{backgroundColor:"white", cursor:"not-allowed"}}/>
                        
                        <label htmlFor="towing_vehicle_id" className="form-group col-sm-4 text-right">Towing Vehicle No </label>
                        <select  id="towing_vehicle_id" name="towing_vehicle_id"onChange={handleChange}  value={inputs.towing_vehicle_id || ""}className="form-control col-sm-7 browser-default custom-select mandatory-form-control" required >
                            <option value="">Please select Towing Vehicle</option>
                            {towingvehicle.map((item) => (
                                <option key={item.id} value={item.id}>{item.equip_name}</option>
                            ))}
                        </select>
                        
                        <label htmlFor="towing_vehicle_starting_km" className="form-group col-sm-4 text-right">Towing Starting Km </label>
                        <input required id="towing_vehicle_starting_km"type="number" name="towing_vehicle_starting_km" value={inputs.towing_vehicle_starting_km || ""}onChange={handleChange}  className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="towing_vehicle_ending_km" className="form-group col-sm-4 text-right">Towing Closing Km</label>
                        <input  type="number" id="towing_vehicle_ending_km" name="towing_vehicle_ending_km" className="form-control col-sm-7" readOnly={true} style={{backgroundColor:"white", cursor:"not-allowed"}}/>
                </div>

                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel" >
                <h5 className='col-sm-11'><b>Customer Feedback Details</b></h5><br/><br/>
                    <label htmlFor="site_feedback" className="form-group col-sm-4 text-right">Customer Feed Back</label>
                    <input type="text" id="site_feedback"name="site_feedback" className="form-control col-sm-7" readOnly ={true} style={{backgroundColor: "white", cursor: "not-allowed"}}/>
                </div>
                </Col>
            </Row>                                
            </div>
                <div className="footer text-center">
                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
        </form>
        </div>
    {/* </div> */}
         </>
    );
  }
  export default PumpClearance;