import React from 'react';
import { useState,useEffect } from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import {displayErrorToast,displayError,parseBoolean} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import StatusDropDown from '../../components/StatusDropDown';
import { getAllCustomerMain,getCustomerMainDetails} from '../../services/CustomerMainServices';
import { createCustomerSite } from '../../services/CustomerSiteServices';
import { checkForDuplicateCustomerSites } from './CustomerSiteUtils';
import { getIndianStates } from '../../utils/PicklistProvider';

function CustomerSite() {
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [isChecked,setIsChecked] = useState({
      chkbx:false
    })
    const [inputs, setInputs] = useState({
      customer_id: '',
      name: '',
      address_1:'',
      address_2:'',
      address_3:'',
      pincode:'',
      phone_number:'',
      mobile_number:'',
      email_id:'',
      radius_distance:'',
      project_name:'',
      contact_person:'',
      contact_designation: '',
      contact_email_id:'' ,
      user_remarks:'',
      contact_mobile_no:'',
      status: ''
    });
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [CusSite, setCusSite] = React.useState([]);

    React.useEffect(() => {
      getAllCustomerMain(cookies)
      .then (
          customerList => {
              
              const customers = customerList.customer_list.filter(obj => obj.status).map(
                  customer => {
                      return { value: customer.id, label: customer.name }
                  }
              );
              setCustomers(customers);
          }
      )
  }, []);
  
    
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try{
    if(name == 'name') //Site name must not be empty ,it is already exist then create a new customer name
    {
      if(!value.trim())
      {
  
        setError(values => ({...values, [name]: "Site Name must not be empty..."}))
        setIsSubmitting(false); 
      }
      else
      {
        setError(values => ({...values, [name]: ''}))
        setIsSubmitting(true);
      }
    }
    else if (name === 'customer_id'){
      console.log(value+typeof(value))
      if (isChecked.chkbx && value){
        getCustomerMainDetails(cookies,value)
                .then((customerObject) =>{ 
                 setInputs(values =>({...values,
                  ['name']: customerObject.name,
                  ['address_1']:customerObject.address_1,
                  ['address_2']:customerObject.address_2,
                  ['address_3']:customerObject.address_3,
                  ['pincode']:customerObject.pincode,
                  ['phone_number']:customerObject.phone_number,
                  ['mobile_number']:customerObject.mobile_number,
                  ['email_id']:customerObject.email_id,
                 }))
                })
      }
      else{
        const selectedCustomer = customers.find((item) => item.value === parseInt(value));
        console.log(JSON.stringify(selectedCustomer)+JSON.stringify(customers)+"selectedCustomer");
        const customerName = selectedCustomer ? selectedCustomer.label : '';
        setInputs((values) => ({ ...values, ['name']: customerName }));
      }
    }
    // console.log(isSubmitting+"isSubmitting")
  setInputs(values => ({...values, [name]: event.target.value}))
  }
  catch (e)
  {
   displayErrorToast(e);
  }
  }

    const validate_items = (name,value,regular_expr,error_message) =>
    {
       try{
            if(value.trim())             //If regex is statisfied condition is valid it accept ,otherwise it is Invalid
            {
                if(!regular_expr.test(value.trim()))               
                 {
                    setError(values => ({...values, [name]: error_message}))
                    setIsSubmitting(false); 
                }
                else    
                {
                    setError(values => ({...values, [name]: ''}))
                    setIsSubmitting(true);
                }
            }
            else  
            {
              setError(values => ({...values, [name]: ''}))
              setIsSubmitting(true);
            //     setError(values => ({...values, [name]: 'must not be empty...'}))
            //     setIsSubmitting(false);
            }          
            setInputs(values => ({...values, [name]: value}))
        }
     
       catch (e)
          {
           displayErrorToast(e);
          }
     }  
    
      const handleCopyDetails = (event) => {
      const name = event.target.name;
      const value = event.target.checked;
      try{    
          if(value === true){
            setIsChecked(values => ({chkbx:true}))
              var customerCopyValue = document.getElementById('customer_id').value
              if(customerCopyValue)
              {
                getCustomerMainDetails(cookies,customerCopyValue)
                .then((customerObject) =>{ 
                 setInputs(values =>({...values,
                  ['name']: customerObject.name,
                  ['address_1']:customerObject.address_1,
                  ['address_2']:customerObject.address_2,
                  ['address_3']:customerObject.address_3,
                  ['pincode']:customerObject.pincode,
                  ['phone_number']:customerObject.phone_number,
                  ['mobile_number']:customerObject.mobile_number,
                  ['email_id']:customerObject.email_id,
                 }))
                })
                // Then set the values in input fields
              }
               else{
                Swal.fire("PLEASE SELECT THE BILL NAME...", '', 'info');
              }
          }
          else
            {
            const emptyvalues = {
              name: '',
              address_1:'',
              address_2:'',
              address_3:'',
              pincode:'',
              phone_number:'',
              mobile_number:'',
              email_id:''
            };
             setIsChecked(values => ({chkbx:false}))
            setInputs(values => ({...values, ...emptyvalues}));
         }
         
        } catch(e)
            {
              displayErrorToast(e);
            }  
      
}



  const handleCreate=(event)=> {
    event.preventDefault();
    // console.log(JSON.stringify(error)+"error"+isSubmitting+"isSubmitting");
    try{
         if ((Object.values(error).every(x => !x)) && isSubmitting) {  //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
                      Swal.fire({title: 'Do you want to save?',  
                      showCancelButton: true,  
                      confirmButtonText: `Yes`,  
                      cancelButtonText: `No`,
                      }).then((result) => {  
                        if (result.isConfirmed) { 
                          createCustomerSite(cookies,{
                            
                              customer_id: parseInt(inputs.customer_id),
                              name: inputs.name.trim().toUpperCase(),
                              address_1:inputs.address_1,
                              address_2:inputs.address_2,
                              address_3:inputs.address_3,
                              pincode:inputs.pincode,
                              phone_number:inputs.phone_number,
                              mobile_number:inputs.mobile_number,
                              email_id:inputs.email_id,
                              radius_distance:inputs.radius_distance,
                              project_name:inputs.project_name,
                              contact_person:inputs.contact_person,
                              contact_designation: inputs.contact_designation,
                              contact_email_id: inputs.contact_email_id,
                              user_remarks:inputs.user_remarks,
                              contact_mobile_no:inputs.contact_mobile_no,
                              status: parseBoolean(inputs.status),
                              state:inputs.state,
                              gst_no:inputs.gst_no?inputs.gst_no:'',
                              pan_no:inputs.pan_no?inputs.pan_no:''
              })  .then((response) =>
              {
                console.log(JSON.stringify(response)+"response_unit");
                Swal.fire('Saved!', '', 'success') 
              // Reload();
                Cancel();
              }).catch((error) =>
              {
                console.log(error.response.data);
                displayError(error.response.data,"Save Failed");
              })
              
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not saved', '', 'info')  
            }
          });
    }
    else
    {
      Swal.fire('Please check * marked fields are filled and details given are valid...', '', 'info')   
    }
 }
  catch(e)
  {
    displayErrorToast(e);
  }
}  
// const handleSubmit = (event) => {
//   event.preventDefault();
 
//   try{
//     checkForDuplicateCustomerSites(event.target.name.value,cookies,null)
//     .then(isNameExists => {
//       if (isNameExists) {
//         setError(values => ({...values, [event.target.name.name]: 'Site name already exists.'}));
//       } else {

//         if (Object.values(error).every((x) => !x) && isSubmitting) {        //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
          
//             handleCreate();
          
//         } else {
//           Swal.fire("Please check * marked fields are filled and details given are valid...","","info" );
//         }
//       }
//   }); 
// } catch (e) {
//     displayErrorToast(e);
// } 
      
// } 
 const view = () => {
      navigate('/CustomerSiteTable')
    }  
const Back = () => {
      navigate('/Home')
    }
const Cancel = () => {
        setIsChecked({chkbx:false})
        setInputs({
          customer_id: '',
          name: '',
          address_1:'',
          address_2:'',
          address_3:'',
          pincode:'',
          phone_number:'',
          mobile_number:'',
          email_id:'',
          radius_distance:'',
          project_name:'',
          contact_person:'',
          contact_designation: '',
          contact_email_id:'' ,
          user_remarks:'',
          contact_mobile_no:'',
          status: ''
        });
    }
    return (
    <>
     {/* <div id="outer-container"  className="App" >  */}
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleCreate}> 
          <div id="header">
              <h3 className = "text font-weight-bold page-title">CUSTOMER SITE REGISTRATION FORM </h3>
          </div>
         
          <FloatingControls tableLink="/CustomerSiteTable" onCancel={Cancel} enableCancel={true}/>
            <div className="container"> 
                <Row> 
                <Col xl={6} lg={12} md={12}>   
                        <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel "> 
                        <h5 className='col-sm-11'><b>Billing Details</b></h5><br/><br/>                          
                         <label htmlFor="customer_id"  className="form-group col-sm-4 text-right">Bill Name </label>
                            <select name="customer_id" id='customer_id' className="browser-default custom-select form-control col-sm-7 mandatory-form-control" onChange={handleChange} value={inputs.customer_id || ""}required >
                                <option value="">Select Billing Name</option>
                                {customers.map((item) => (
                                        <option key={item.value} value={item.value}>
                                        {item.label}
                                        </option>
                                    ))}
                            </select><br/><br/>
                            
                            <label htmlFor="chkbx" className="form-group col-sm-4 text-right">Same as Customer</label>
                            <label><input type="checkbox" id="copycustomerdetails"  name="copycustomerdetails" onChange={handleCopyDetails} checked={isChecked.chkbx || false}/></label>
                        </div>
                        
                        <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel">
                        <h5 className='col-sm-11'><b>Site Details</b></h5><br/><br/>
                            <label htmlFor="name" className="form-group col-sm-4 text-right">Name </label>
                            <input type="text" id='name' name="name" className="form-control col-sm-7 mandatory-form-control" placeholder="Please fill the name field *" required="" onChange={handleChange} value={inputs.name || ""}/>
                            {error.name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.name}</strong><br/></>)}

                            <label htmlFor="address_1" className="form-group col-sm-4 text-right">Address </label>
                            <input type="text" id='address_1' name="address_1"onChange={handleChange} value={inputs.address_1 || ""} className="form-control  col-sm-7" />
                           
                            <label htmlFor="address_2" className="form-group col-sm-4 text-right"></label>
                            <input type="text" id='address_2' name="address_2" onChange={handleChange} value={inputs.address_2 || ""}className="form-control  col-sm-7" />
                           
                            <label htmlFor="address_3" className="form-group col-sm-4 text-right"></label>
                            <input type="text" id='address_3' name="address_3" onChange={handleChange} value={inputs.address_3 || ""}className="form-control  col-sm-7" />
                           
                            <label htmlFor="pincode" className="form-group col-sm-4 text-right">Pincode</label>
                            <input name="pincode" id="pincode" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid pincode")} value={inputs.pincode || ""} className="form-control col-sm-7" type = "text" maxLength="6" />
                            {error.pincode && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pincode}</strong><br/></>)}
                            
                            <label htmlFor="state"className="form-group col-sm-4 text-right  ">State</label>
                            <select id="state"name="state" className="browser-default custom-select form-control col-sm-7 mandatory-form-control" onChange={handleChange} required value={inputs.state || ""}>
                            <option value="">Select State</option>
                                  {
                                    getIndianStates()
                                    .map(indian_state => <option key={indian_state.value} value={indian_state.value}>{indian_state.name}</option>
                                    )
                                  }
                              </select>
                        
                            <label htmlFor="phone_number" className="form-group col-sm-4 text-right">Phone No</label>
                            <input type="text" id='phone_number' name="phone_number"onChange={handleChange} value={inputs.phone_number || ""} placeholder="0123456789" className="form-control  col-sm-7"/>
                           
                            <label htmlFor="mobile_number" className="form-group col-sm-4 text-right">Mobile No</label>
                            <input type="text" id='mobile_number' name="mobile_number" onChange={handleChange} value={inputs.mobile_number || ""} placeholder="0123456789"className="form-control  col-sm-7"/>
                           
                            <label htmlFor="email_id" className="form-group col-sm-4 text-right">Email</label>
                            <input type="email" id="email_id" name="email_id"  onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid cusEmailid")} value={inputs.email_id|| ""} className="form-control col-sm-7 " />
                            {error.email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.email_id}</strong><br/></>)}

                        </div>
                </Col>
                <Col xl={6} lg={12} md={12}>   
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel">
                  <h5 className='col-sm-11'><b>Site Contact Details</b></h5><br/><br/>
                        <label htmlFor="radius_distance" className="form-group col-sm-4 text-right">Radial Distance(1 Way) </label>
                        <input type="number" id="radius_distance"name="radius_distance" onChange={handleChange} value={inputs.radius_distance || ""}required className="form-control  col-sm-7 mandatory-form-control" />
                                               
                        <label htmlFor="project_name" className="form-group col-sm-4 text-right">Project Name </label>
                        <input type="text" name="project_name" id="project_name"onChange={handleChange} value={inputs.project_name || ""}required className="form-control  col-sm-7 mandatory-form-control" placeholder="Please fill the project name field *" />
                        
                        <label htmlFor="contact_person" className="form-group col-sm-4 text-right">Contact Person</label>
                        <input type="text" id='contact_person'name="contact_person"onChange={handleChange} value={inputs.contact_person || ""} className="form-control  col-sm-7"/>
                        
                        <label htmlFor="contact_designation" className="form-group col-sm-4 text-right">Designation</label>
                        <input type="text"id='contact_designation' name="contact_designation"onChange={handleChange} value={inputs.contact_designation || ""} className="form-control  col-sm-7"/>
                        
                        <label htmlFor="contact_email_id" className="form-group col-sm-4 text-right">Email</label>
                        <input type="text" id='contact_email_id' name="contact_email_id"  onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid CuscontactEmailId")} value={inputs.contact_email_id || ""}className="form-control  col-sm-7"/>
                        {error.contact_email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.contact_email_id}</strong><br/></>)}

                        <label htmlFor="contact_mobile_no" className="form-group col-sm-4 text-right" >Mobile No</label>
                        <input type="text" id='contact_mobile_no' name="contact_mobile_no"onChange={handleChange} value={inputs.contact_mobile_no || ""} placeholder="0123456789" className="form-control  col-sm-7"/>
                  </div>
                        

                  <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel " >
                  <h5 className='col-sm-11'><b>Statutory Details</b></h5><br/>
                  <label htmlFor="pan_no" className="form-group col-sm-4 text-right">PAN No.</label>
                            <input type="text"name="pan_no" id="pan_no" className="form-control col-sm-7" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,"invalid PANNo")}  value={inputs.pan_no || ""}  placeholder="Please fill the PAN field *"  /><br/>
                            {error.pan_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pan_no}</strong><br/></>)}
                            

                            <label htmlFor="gst_no"className="form-group col-sm-4 text-right  ">GST No </label>
                            <input type="text" id="gst_no" name="gst_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,"invalid GSTNo")}  value={inputs.gst_no || ""}className="form-control col-sm-7 "  placeholder="Please fill the Gst field *" /><br/>
                            {error.gst_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.gst_no}</strong><br/></>)}

                  <h5 className='col-sm-11'><b>Site Status</b></h5><br/><br/>
                        <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                        <input type="text" name="user_remarks" onChange={handleChange} value={inputs.user_remarks || ""}className="form-control  col-sm-7" placeholder="IF ANY.."/>
                        
                        <StatusDropDown status={inputs.status} onChange={handleChange}/><br/><br/>
                  </div>
                    </Col>
                </Row>             
            </div>
            <div className="footer text-center">
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_entity_consignee_detail") === true)) && (
              <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button>
            )} &nbsp;&nbsp;
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_entity_consignee_detail") === true)) && (
            <Button type="button" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Cancel</Button>
            )} &nbsp;&nbsp;
            {((superuser['mySuperuser']) || (category['myCategory'].includes("view_entity_consignee_detail") === true)|| (category['myCategory'].includes("change_entity_consignee_detail") === true) || (category['myCategory'].includes("delete_entity_consignee_detail") === true)) &&(  
              <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
            )}&nbsp;&nbsp;
            <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
            </div>
        </form>
        </div>
    {/* </div> */}
         </>
    );
  }
  export default CustomerSite;