import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import React from 'react';
import {Row,Col} from "reactstrap";
import { getAllPlants} from '../../services/PlantServices';
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import { getAllBank } from "../../services/BankService";
import { getAllSalesRepresentatives } from '../../services/SalesRepresentativeServices';
import { getReceiptReport} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import moment  from 'moment';
const {RangePicker} = DatePicker;
function ReceiptReportTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
   
    const [plants, setPlants] = React.useState([]);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [selectedSalesrep, setSelectedSalesRep] = useState(null);
    const [salesreps, setSalesreps] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [banks, setBanks] = useState([]);
    const currentMonthStartDate = moment().startOf('month');
    const currentDate = moment();
    const [dates, setDates] = useState([currentMonthStartDate, currentDate]);


 
    const Option = (props) => {
        return (
            <>
                <components.Option {...props}>
                    <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}/>{" "}
                <label>{props.label}</label>
                </components.Option>
            </>
        );
    };

React.useEffect(() => {
        
    //Load data for Plant dropdown
    getAllPlants(cookies)
    .then (
        plantList => {
            
            const plants = plantList.plant_list.filter(obj => obj.status).map(
                plant => {
                    return { value: plant.id, label: plant.entity_name }
                }
            );
            setPlants(plants);
        }
    )
}, []);
React.useEffect(() => {
    getAllCustomerMain(cookies)
    .then (
        customerList => {
            
            const customers = customerList.customer_list.filter(obj => obj.status).map(
                customer => {
                    return { value: customer.id, label: customer.name }
                }
            );
            setCustomers(customers);
        }
    )
}, []);
React.useEffect(() => {
    getAllSalesRepresentatives(cookies)
    .then (
        salesRepList => {
            
            const salesrep = salesRepList.salesrep_data.filter(obj => obj.status).map(
              sales => {
                    return { value: sales.id, label: sales.entity_name }
                }
               
            );
            //console.log(JSON.stringify(salesRepList)+"salesRepList")
        
            setSalesreps(salesrep);
          
        }
    )
}, []);
React.useEffect(() => {
    getAllBank(cookies)
    .then (
        bankList => {
            
            const banks = bankList.bank_list.filter(obj => obj.status).map(
                bank => {
                    return { value: bank.id, label: bank.bank_alias }
                }
            );
            setBanks(banks);
        }
    )
}, []);
const onPlantSelect = (selected) => {
    setSelectedPlant(selected);
};
const onCustomerSelect = (selected) => {
    setSelectedCustomer(selected);
};

const onSalesRepSelect = (selected) => {
    setSelectedSalesRep(selected);
};
const onBankSelect = (selected) => {
    setSelectedBank(selected);
};
    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Plant Name'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Receipt Number'
            },
           
            {
                fieldName: "address",
                headerName: 'Receipt Date'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Customer Name'
            },
          
            {
                fieldName: "mobile_number",
                headerName: 'Receipt Amount'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Mode of receipt'
            },
            {
                fieldName: "address",
                headerName: 'Bank/Cash'
            },
            {
                fieldName: "address",
                headerName: 'Marketing Name'
            },
          
          
        ],
        [],);

   
    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                   
                    phone_number:r.phone_number,
                    mobile_number:r.mobile_number,
                    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Receipt Report',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                
                phone_number:r.phone_number,
                mobile_number:r.mobile_number,
               
            })),
            ' Receipt.pdf'
        );
    };

    useEffect(() => {

        getReceiptReport(cookies)
        .then( customerList => {
            const tableData = customerList.salesrep_data
            .map(customer => ({
                entity_name: customer.entity_name,
                phone_number:customer.phone_number,
                mobile_number:customer.mobile_number,
                
                id: customer.id,    
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}> Receipt Report</h3>
            <br/>
            <form className="form" onSubmit={""}>
        <div className="container">
        <Row>
          <Col xl={12} lg={12} md={12}>
            </Col>
           
            <Col xl={12} lg={12} md={12}>
            <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
            <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                  {/* <div style={{width: '58%'}} > */}
                                  <ReactSelect
                                    options={plants}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedPlant}
                                    onChange={onPlantSelect}
                                    className="browser-default custom-select col-sm-3"
                                    placeholder="Please select Plant name"
                                  />
                                  {/* </div> */}
                                <br/><br/>&nbsp;&nbsp;&nbsp;

                             
             <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                 <ReactSelect
                                   options={customers}
                                   isMulti= {true}
                                   closeMenuOnSelect={false}
                                   hideSelectedOptions={false}
                                   components={{Option}}
                                   isClearable={true}
                                   // defaultValue={selectedOption}
                                   value={selectedCustomer}
                                   onChange={onCustomerSelect}
                                   className="browser-default custom-select col-sm-3"
                                   placeholder="Please select Customer name"
                                 />
                              
                               <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      
                               <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                 <ReactSelect
                                   options={salesreps}
                                   isMulti= {true}
                                   closeMenuOnSelect={false}
                                   hideSelectedOptions={false}
                                   components={{Option}}
                                   isClearable={true}
                                   // defaultValue={selectedOption}
                                   value={selectedSalesrep}
                                   onChange={onSalesRepSelect}
                                   className="browser-default custom-select col-sm-4"
                                   placeholder="Please Select Sales Representative"
                                 />
                               
                               <br/><br/>&nbsp;&nbsp;&nbsp;
                  
                            
                            <br></br>          
                            <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                 <ReactSelect
                                   options={banks}
                                   isMulti= {true}
                                   closeMenuOnSelect={false}
                                   hideSelectedOptions={false}
                                   components={{Option}}
                                   isClearable={true}
                                   // defaultValue={selectedOption}
                                   value={selectedBank}
                                   onChange={onBankSelect}
                                   className="browser-default custom-select col-sm-4"
                                   placeholder="Please Select Mode of Transaction"
                                 />
                   
                            <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-left"></label>
                        <RangePicker
                            defaultValue={dates}
                                    format="DD-MM-YYYY"
                                    onChange={(values) => {
                                    if (values.length === 2) {
                                        setDates(values);
                                    }
                                    }}
                                />
 
                    <div>    
                        <Link  to={'/Search'} >
                            <i className=" form-group col-sm-3  fa fa-search fa-2x" id="myData" aria-hidden="true"></i>
                         </Link>
                    </div> 


                    <div>    
                        <Link  to={'/Home'} >
                            <i className=" form-group col-sm-3  fa fa-home fa-2x"id="myTable" aria-hidden="true"></i>
                        </Link>
                    </div> 

            </div>
        </Col>
     </Row> 
        </div>
    
        </form>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='Receipt Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 

            </div>
                {/* </div> */}
                </>
        
    );

}

export default ReceiptReportTable;