import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllEquipments, deleteEquipments } from "../../services/EquipmentServices";
function EquipmentTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'equip_type', 
                headerName: 'Vehicle Type'
            },
           
            {
                fieldName: "equip_name",
                headerName: 'Vehicle No./SI.No.'
            },
            {
                fieldName: "capacity",
                headerName: 'Capacity'
            },
            {
                fieldName: "vendor",
                headerName: 'Vendor'
            },
            {
                fieldName: "insurance_date",
                headerName: 'Insurance Due Date'
            },
            {
                fieldName: "permit_date",
                headerName: 'Permit Due Date'
            },
            {
                fieldName: "fc_date",
                headerName: 'FC Due Date'
            },
            {
                fieldName: "meter_status",
                headerName: 'Meter Status'
            },
            {
                fieldName: "user_remarks",
                headerName: 'Remarks'
            },
            {
                fieldName: "status",
                headerName: 'Status'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
       
         
        ],
        [],);

    const onEditEquipment = (equipmentId, event) => {
       
       navigate(`/EquipmentEdit/${equipmentId}/`);
    };

    const onDeleteEquipment = (equipmentId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deleteEquipments(cookies, equipmentId); 
            console.log(`Eqipment with id ${equipmentId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    equip_type: r.equip_type.name,
                    equip_name: r.equip_name,
                    capacity:r.capacity,
                    vendor:r.vendor.name,
                    insurance_date:r.insurance_date,
                    permit_date:r.permit_date,
                    fc_date:r.fc_date,
                    meter_status:r.meter_status ? "working":"not_working",
                    user_remarks:r.user_remarks,
                    status:r.status ? "active":"inactive",
                    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Equipment Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                equip_type: r.equip_type.name,
                equip_name: r.equip_name,
                capacity:r.capacity,
                vendor:r.vendor.name,
                insurance_date:r.insurance_date,
                permit_date:r.permit_date,
                fc_date:r.fc_date,
                meter_status:r.meter_status ? "working":"not_working",
                user_remarks:r.user_remarks,
                status:r.status ? "active":"inactive",
            })),
            'Equipment_Forms.pdf'
        );
    };

    useEffect(() => {

        getAllEquipments(cookies)
        .then( equipmentsList => {
            const tableData = equipmentsList.equipment_list
            .map(r => ({
                id :r.id,
                equip_type: r.equip_type.name,
                equip_name: r.equip_name,
                capacity:r.capacity,
                vendor:r.vendor.name,
                insurance_date:r.insurance_date,
                permit_date:r.permit_date,
                fc_date:r.fc_date,
                meter_status:r.meter_status ? "working":"not_working",
                user_remarks:r.user_remarks,
                status:r.status ? "active":"inactive" ,
                hide_row_edit:r.system_field?"Yes":"No",
                hide_row_delete:r.system_field?"Yes":"No"
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Equipment Form</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Group Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditEquipment}
                            onRowDelete={onDeleteEquipment}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/Equipment")}}>Back</button>
        {/* </div> */}
        </>
        
    );

}



export default EquipmentTable;