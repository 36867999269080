import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import {displayError} from '../../helpers';
import { useCookies } from 'react-cookie';
import { getAllCustomerSites, deleteCustomerSite } from "../../services/CustomerSiteServices";
function CustomerSiteTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: 'name', 
                headerName: 'Customer Name'
            },
            {
                fieldName: "address",
                headerName: 'Address'
            },
            {
                fieldName: 'project_name', 
                headerName: 'Project'
            },
            {
                fieldName: 'contact_mobile_no', 
                headerName: 'Contact No'
            },
            {
                fieldName: 'gst_no', 
                headerName: 'GST No'
            },
            {
                fieldName: 'pan_no', 
                headerName: 'PAN No'
            },
            {
                fieldName: 'email_id', 
                headerName: 'E-mail'
            },
            {
                fieldName: 'mobile_number', 
                headerName: 'Mobile No'
            },
            {
                fieldName: 'user_remarks', 
                headerName: 'Remarks'
            },
            {
                fieldName: "contact_person",
                headerName: 'Contact Person'
            },
            {
                fieldName: "salesrep",
                headerName: 'Sales Representative'
            },
            {
                fieldName: 'status', 
                headerName: 'Status'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditCustomerSite = (siteId, event) => {
       
       navigate(`/CustomerSiteEdit/${siteId}/`);
    };

    const onDeleteCustomerSite = (siteId, event) => {
        
        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 

            setIsLoading(true);
            deleteCustomerSite(cookies, siteId)
            .then(response => {
                console.log(`site with id ${siteId} deleted`);
                Swal.fire('Deleted Successfully!', '', 'success');
                setRefreshKey(oldKey => oldKey +1)
            })
            .catch(error => {
                console.log(error.response.data);
                displayError(error.response.data,'Delete Failed');
            });  
           
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };


    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(site => {

                const csvRow = {
                    name: site.name,
                    address: `${site.address_1},\n${site.address_2},\n${site.address_3}`,
                       mobile_number:site.mobile_number,
                       project_name:site.project_name,
                       customer: site.name,
                       salesrep:site.name,
                       status:site.status,
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Customer Sites / Projects',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(site => ({
                name: site.name,
                address: `${site.address_1},\n${site.address_2},\n${site.address_3}`,
                   mobile_number:site.mobile_number,
                   project_name:site.project_name,
                   customer: site.name,
                   salesrep:site.name,
                   status:site.status,
            })),
            'Site.pdf'
        );
    };

    useEffect(() => {

        getAllCustomerSites(cookies)
        .then( siteList => {
            const tableData = siteList.consignee_list
            .map(site => ({
                name: site.name,
                 address: `${site.address_1},\n${site.address_2},\n${site.address_3}`,
                    mobile_number:site.mobile_number,
                    project_name:site.project_name,
                    customer: site.customer.name,
                    contact_person:site.contact_person,
                    salesrep:site.customer.salesrep.name,
                    status:(site.status ? 'Active': 'Inactive'),
                    id:site.id,
                    contact_mobile_no:site.contact_mobile_no,
                    gst_no:site.gst_no,
                    pan_no:site.pan_no,
                    email_id:site.email_id,
                    user_remarks:site.user_remarks 
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Customer Sites/Projects</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Customer Sites / Projects'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditCustomerSite}
                            onRowDelete={onDeleteCustomerSite}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/CustomerSite")}}>Back</button>
        {/* </div> */}
        </>
    );

}

export default CustomerSiteTable;