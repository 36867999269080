import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllMoisture, deleteMoisture} from "../../services/MoistureCorrectionServices";
function MoistureCorrectionTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: "moisture_msand",
                headerName: 'MOISTURE MSAND'
            },
            {
                fieldName: "moisture_csand",
                headerName: 'MOISTURE CSAND'
            },
            {
                fieldName: "moisture_rsand",
                headerName: 'MOISTURE RSAND'
            },
            {
                fieldName: "moisture_12mm",
                headerName: 'MOISTURE 12mm'
            },
            {
                fieldName: "moisture_20mm",
                headerName: 'MOISTURE 20mm'
            },
            {
                fieldName: "absorption_msand",
                headerName: 'ABSORPTION MSAND'
            },
            {
                fieldName: "absorption_csand",
                headerName: 'ABSORPTION CSAND'
            },
            {
                fieldName: "absorption_rsand",
                headerName: 'ABSORPTION RSAND'
            },
            {
                fieldName: "absorption_12mm",
                headerName: 'ABSORPTION 12mm'
            },
            {
                fieldName: "absorption_20mm",
                headerName: 'ABSORPTION 20mm'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditMoisturecorr = (moistureId, event) => {
       navigate(`/MoistureCorrectionEdit/${moistureId}/`);
    };

    const onDeleteMoisturecorr  = (moistureId, event) => {
        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deleteMoisture(cookies, moistureId); 
            console.log(`Moisture with id ${moistureId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    moisture_msand: r.moisture_msand,
                    moisture_csand: r.moisture_csand,
                    moisture_rsand: r.moisture_rsand,
                    moisture_12mm:r.moisture_12mm,
                    moisture_20mm:r.moisture_20mm,
                    absorption_msand:r.absorption_msand,
                    absorption_csand:r.absorption_csand,
                    absorption_rsand:r.absorption_rsand,
                    absorption_12mm: r.absorption_12mm,
                    absorption_20mm: r.absorption_20mm,
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Moisture Correction',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
            
                moisture_msand: r.moisture_msand,
                    moisture_csand: r.moisture_csand,
                    moisture_rsand: r.moisture_rsand,
                    moisture_12mm:r.moisture_12mm,
                    moisture_20mm:r.moisture_20mm,
                    absorption_msand:r.absorption_msand,
                    absorption_csand:r.absorption_csand,
                    absorption_rsand:r.absorption_rsand,
                    absorption_12mm: r.absorption_12mm,
                    absorption_20mm: r.absorption_20mm,
            })),
            'Moisture.pdf'
        );
    };

    useEffect(() => {

        getAllMoisture(cookies)
        .then( moistureList => {
            const tableData = moistureList.moisture_correction_list
            .map(mc => ({
                id:mc.id,
                moisture_msand: parseFloat(mc.moisture['msand']),
                    moisture_csand: parseFloat(mc.moisture['csand']),
                    moisture_rsand: parseFloat(mc.moisture['rsand']),
                    moisture_12mm:parseFloat(mc.moisture['12mm']),
                    moisture_20mm:parseFloat(mc.moisture['20mm']),
                    absorption_msand:parseFloat(mc.absorption['msand']),
                    absorption_csand:parseFloat(mc.absorption['csand']),
                    absorption_rsand:parseFloat(mc.absorption['rsand']),
                    absorption_12mm: parseFloat(mc.absorption['12mm']),
                    absorption_20mm: parseFloat(mc.absorption['20mm']),
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Moisture Correction</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Moisture Correction'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditMoisturecorr }
                            onRowDelete={onDeleteMoisturecorr}
                            hideDelete={true}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 

            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/MoistureCorrection")}}>Back</button>
        {/* </div> */}
        </>
    );
}

export default MoistureCorrectionTable;