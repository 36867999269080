import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllDesignMix, deleteDesignMix } from "../../services/DesignMixServices";
function DesignMixTable() {
    const InitialData = {
					   
        records: []
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'plant', 
                headerName: 'Plant'
            },
            {
                fieldName: 'sales_order_no', 
                headerName: 'SO No'
            },
            {
                fieldName: "sales_order_date",
                headerName: 'SO Date'
            },
            {
                fieldName: 'work_schedule_no', 
                headerName: 'Work schedule No'
            },
            {
                fieldName: "work_schedule_date",
                headerName: 'Work schedule Date'
            },
            {
                fieldName: "customer_id",
                headerName: 'Customer'
            },
            {
                fieldName: "consignee_id",
                headerName: 'Site'
            },
            {
                fieldName: "grade",
                headerName: 'Grade'
            },
            {
                fieldName: "rate",
                headerName: 'Rate'
            },
            {
                fieldName: "ordered_quantity",
                headerName: 'Order Qty'
            },
            {
                fieldName: "scheduled_quantity",
                headerName: 'Scheduled Qty'
            },
            {
                fieldName: "delivery_mode",
                headerName: 'Delivery Mode'
            },
            {
                fieldName: "concrete_structure_id",
                headerName: 'Concrete Structure'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditDesignMix = (designmixId, event) => {
       
       navigate(`/DesignMixEdit/${designmixId}/`);
    };

    const onDeleteDesignMix = (designmixId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deleteDesignMix(cookies, designmixId); 
            console.log(`DesignMix with id ${designmixId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
		  

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    plant:r.plant,
                    sales_order_no: r.sales_order_no,
                    sales_order_date: r.sales_order_date,
                    customer_id:r.customer_id,
                    consignee_id: r.consignee_id,
                    grade: r.grade,
                    rate:r.rate,
                    ordered_quantity: r.ordered_quantity,
                    delivery_mode:r.delivery_mode,
                    concrete_structure_id: r.concrete_structure_id,
                    work_schedule_no :r.work_schedule_no,
                    work_schedule_date:r.work_schedule_date,
                    scheduled_quantity:r.scheduled_quantity
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Design Mix Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                plant:r.plant,
                sales_order_no: r.sales_order_no,
                sales_order_date: r.sales_order_date,
                customer_id:r.customer_id,
                consignee_id: r.consignee_id,
                grade: r.grade,
                rate:r.rate,
                ordered_quantity: r.ordered_quantity,
                delivery_mode:r.delivery_mode,
                concrete_structure_id: r.concrete_structure_id,
                work_schedule_no :r.work_schedule_no,
                work_schedule_date:r.work_schedule_date,
                scheduled_quantity:r.scheduled_quantity
            })),
            'DesignMixList.pdf'
        );
    };

    useEffect(() => {

        getAllDesignMix(cookies)
        .then( designmixList => {
            const tableData = designmixList.design_mix_list
            .map(r => ({
                id :r.id,
                sales_order_no: r.work_schedule.sales_order_detail.sales_order.order_no,
                sales_order_date: r.work_schedule.sales_order_detail.sales_order.order_date,
                work_schedule_no :r.work_schedule.schedule_no,
                work_schedule_date:r.work_schedule.schedule_date,
                scheduled_quantity:r.work_schedule.scheduled_qty,
                customer_id:r.work_schedule.sales_order_detail.sales_order.consignee.customer.name,
                consignee_id: r.work_schedule.sales_order_detail.sales_order.consignee.name,
                grade: r.work_schedule.sales_order_detail.product.name,
                rate:r.work_schedule.sales_order_detail.rate,
                ordered_quantity: r.work_schedule.sales_order_detail.quantity,
                delivery_mode:r.work_schedule.sales_order_detail.delivery_mode,
                concrete_structure_id: r.work_schedule.sales_order_detail.concrete_structure.name,
                plant:r.work_schedule.plant.alias_name
            }));

            setData({
								  
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of DesignMix Form</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Group Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditDesignMix}
                            onRowDelete={onDeleteDesignMix}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            hideDelete={true}
                        
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/DesignMix")}}>Back</button>
        {/* </div> */}
        </>
        
    );

}



export default DesignMixTable;