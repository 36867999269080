import React from 'react';
import { useState ,useEffect,useMemo} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import QuestionIcon from '../../components/QuestionIcon';
import ReactTablePopUpMultiSelect from '../../components/ReactTablePopUpMultiSelect';
import { useCookies } from 'react-cookie';
import TableRowsSalesPosting from "./TableRowsSalesPosting";
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getUserAllowedPlants} from '../../services/PlantServices';
import { getAllCustomerMain,getCustomerMainDetails} from '../../services/CustomerMainServices';
import {getAllCustomerSites,getCustomerSiteDetails} from '../../services/CustomerSiteServices';
import { getSalesOrdersForInvoice } from '../../services/SalesOrderServices';
import { getDeliveryChallansForInvoice } from '../../services/DeliveryChallanServices';
import { getInvoicenumber,createSalesInvoice} from '../../services/SalesInvoiceServices';
import { showBillingPop, showCustomerSitePop} from './SalesPostingUtils';
import SummaryIcon from '../../components/SummaryIcon';
import { getDisplayDate } from '../../utils/DateUtils';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';
import { getCurrentDate, getCurrentTime} from '../../utils/DateUtils';
import NumberSetting from '../../components/NumberSetting';

function SalesInvoice() {
  const [showModal, setShowModal] = useState(false);
  const [showModalDc, setShowModalDc] = useState(false);
  const initial_table_data = {
      records: [],
  };
  const [data, setData] = useState(initial_table_data);
  const location = useLocation(); 
  const [cookies] = useCookies(['myToken']);
  const [category] = useCookies(['myCategory']);
  const [superuser] = useCookies(['mySuperuser']);
  const [Dcrows, setDcrows] = useState([]);
  const [dcList, setDcList] = useState([]);
  const [plants, setPlants] = React.useState([]);
  const [customers, setCustomers] = useState([]);
  const [sites, setSites] = React.useState([]);
  const [allSalesOrders,setAllSalesOrders] = useState([]);
  const [selectedSalesOrder,setSelectedSalesOrder] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(true);  
  const navigate = useNavigate();

  React.useEffect(() => {
    setInputs(values => ({...values,
        ['invoice_date']: getCurrentDate(),
        ['invoice_time']: getCurrentTime(),
    }))
  }, []);

  React.useEffect(() => {
    //Load data for Plant dropdown
    getUserAllowedPlants(cookies)
    .then (
      plantList => {
        const plants = plantList.plant_list.filter(obj => obj.status).map(
            plant => {
                return { value: plant.id, label: plant.plant_alias }
            }
        );
        setPlants(plants);
      }
    )
  }, []);

  React.useEffect(() => {
    getAllCustomerMain(cookies)
    .then (
        customerList => {
          const customers = customerList.customer_list.filter(obj => obj.status).map(
              customer => {
                  return { value: customer.id, label: customer.name }
              }
          );
          setCustomers(customers);
        }
    )
  }, []);

  React.useEffect(() => {
  getAllCustomerSites(cookies)
  .then (
      response => {
        const sites = response.consignee_list.filter(obj => obj.status).map(
            site => {
                return { value: site.id, label: site.name }
            }
        );
        setSites(sites);
      }
  )
  }, []);

  const handleInvoiceDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(name === "creation_date"){
        setInputs(values => ({...values, 'invoice_date': event.target.value}))
    } 
    else {
        setInputs(values => ({...values, [name]: event.target.value}))
    }
  }

  const [checkedSoIds, setCheckedSoIds] = useState([]);
  const handleChangeSalesOrderDetails = (value, event) => {
    // console.log(value,"value",event.target.checked,"event.target.checked")
    const isChecked = event.target.checked;
    if (isChecked) 
    {
        setCheckedSoIds(prevIds => [...prevIds, value]);
    } 
    else {
        setCheckedSoIds(prevIds => prevIds.filter(item => item !== value));
    }
    // Update the data to reflect the checkbox state
    setData(prevData => ({
      ...prevData,
      records: prevData.records.map(record => 
          record.id === value ? { ...record, isChecked } : record
      )
    }));
  } 

  const showCustomerDetails = () => {
    if (selectedSalesOrder.id) 
    {
      getCustomerMainDetails(cookies,selectedSalesOrder.consignee.customer.id)
      .then(selectedCustomer => {
          showBillingPop(selectedCustomer)
      })
    }
  }

  const showSiteDetails = () => {
    if (selectedSalesOrder.id) 
    {
      getCustomerSiteDetails(cookies,selectedSalesOrder.consignee.id)
      .then(consignee => {
        showCustomerSitePop(consignee);
      })
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try
    {
      if (name === 'plant_id') 
      {
        var moment = require('moment');
        var d=moment(inputs.invoice_date).format("DD-MM-YYYY");
        getInvoicenumber(cookies,value,d)
        .then(response => {
          setIsLoading(false);
          setInputs(values => ({...values, ['invoice_no']: response.invoice_no,['prefix']:response.prefix}));
        })
        .catch((error) =>
        {
          setIsLoading(false);
          // console.log(error.response.data);
          displayError(error.response.data," Failed");
        })
      }
      setInputs(values => ({ ...values, [name]: event.target.value }))
    }
    catch(e)
    {
      displayErrorToast(e);
    } 
  }
  
  const showDcdetails = (index,evnt) =>{
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    console.log(JSON.stringify(dcList)+"dcList on product click")
    setDcrows(dcList.filter(dc=>dc.work_schedule.sales_order_detail.id == rowsInput[index]['id']).map(dc => ({
      vehicle_no:dc.customer_vehicle_no?dc.customer_vehicle_no:dc.vehicle.name,
      id:dc.id,
      dc_qty:dc.dc_qty,
      accepted_qty:dc.accepted_qty,
      customer_status:dc.customer_status?dc.customer_status.toUpperCase():"WAITING FOR CHECKIN",
      customer_dc_status:dc.customer_dc_status?dc.customer_dc_status.toUpperCase():"WAITING FOR CHECKIN",
      delivery_challan_no:dc.prefix+dc.delivery_challan_no,
      delivery_challan_date:dc.delivery_challan_date,
      checkout_no:dc.checkout_no,
      order_no:dc.work_schedule.sales_order_detail.sales_order.prefix+dc.work_schedule.sales_order_detail.sales_order.order_no,
      order_date:dc.work_schedule.sales_order_detail.sales_order.order_date,
      unit:dc.work_schedule.sales_order_detail.product.unit.symbol,
      delivery_mode:dc.work_schedule.delivery_mode.toUpperCase(),
      rate:dc.work_schedule.sales_order_detail.rate,
      grade:dc.work_schedule.sales_order_detail.product.name,
      isChecked:dc.isChecked,
      isDisabled:dc.accepted_qty>0?false:true,
      so_dtl_id:dc.work_schedule.sales_order_detail.id
    })))
    setShowModalDc(true);
  }

  const [rowsData, setRowsData] = useState([]);

  const handleSubmit = (event) => {
      const EMPTY_STRING = '';
      event.preventDefault();
      console.log(JSON.stringify(checkedDcIds)+"checkedDcIds");
      console.log(JSON.stringify(rowsData)+"rowsData");
      console.log(JSON.stringify(dcList)+"dclist")
      if((inputs.invoice_amt > 0 ) && (rowsData.length>0)){
        try{
            Swal.fire({
              title: 'Do you want to save?',
              showCancelButton: true,
              cancelButtonText: `No`,
              showDenyButton: true, // Add a third button
              confirmButtonText: `Save only`,
              denyButtonText: `Save and Printout`, // Text for the third button
            }).then((result) => {
              if ((result.isConfirmed) || (result.isDenied)) {
                    createSalesInvoice(cookies, {  
                      "save_only":result.isConfirmed?true:false,
                      "plant_id": parseInt(inputs.plant_id),
                      "invoice_no": inputs.invoice_no,
                      "invoice_date": inputs.invoice_date ? getDisplayDate(inputs.invoice_date) : EMPTY_STRING,
                      "invoice_time": getCurrentTime(),
                      "prefix": inputs.prefix,
                      "invoice_qty": rowsData.reduce((sum, row) => {
                        return (
                          sum + parseFloat(row.accepted_qty)
                        )
                      }, 0),
                      "cgst_amt": parseFloat(inputs.cgst_amt),
                      "sgst_amt": parseFloat(inputs.sgst_amt),
                      "igst_amt": parseFloat(inputs.igst_amt),
                      "gst_amt":parseFloat(inputs.gst_amt),
                      "gross_amt": parseFloat(inputs.gross_amt),
                      "rounded_off":parseFloat(inputs.rounded_off),
                      "invoice_amt": parseFloat(inputs.invoice_amt),
                      "tcs_amt": parseFloat(inputs.tcs_amt),
                      "taxable_amt": parseFloat(inputs.taxable_amt),
                      "user_remarks": inputs.remarks?inputs.remarks:EMPTY_STRING,
                      "detail_list": rowsData.map(so => {
                        const dc_ids_list = dcList
                            .filter(dc => dc.work_schedule.sales_order_detail.id === so.id)
                            .map(dc => dc.id)
                            .filter(id => checkedDcIds.includes(id));
                        return {
                            "sales_order_detail_id": parseInt(so.id),
                            "invoice_qty": parseFloat(so.accepted_qty),
                            "accepted_qty": parseFloat(so.accepted_qty),
                            "dc_ids_list": dc_ids_list
                        };
                    })})
                    .then(response => {
                      if (result.isConfirmed){
                        Swal.fire("Saved!", "", "success");
                      }
                      else{
                        const file = new Blob([response], { type: "application/pdf" });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        const pdfWindow = window.open();
                        pdfWindow.location.href = fileURL;
                      }
                      // Refresh the current window
                      Reload();
                    })
                    .catch((error) =>
                    {
                        // console.log(error.response.data);
                        displayError(error.response.data,"Save Failed");
                    })
              }
              else if (result.isDismissed) {
                Swal.fire('Not saved', '', 'info')
              }
            });
        }
        catch(e)
        {
          displayErrorToast(e);
        }
      }
      else if (!(inputs.invoice_amt > 0 )){
        Swal.fire('Invoice amount must be greater than zero.', '', 'info')
      }
  }

  const Reload = () => {
    window.location.reload();
  }     
    
  const ShowOrderDetails = () => {
    if (inputs.plant_id)
    {
      setCheckedSoIds([])
      setRowsData([])
      getSalesOrdersForInvoice(cookies,inputs.plant_id)
      .then (sales_order_list => {
        setAllSalesOrders(sales_order_list.sales_order_list)
        const tableData = sales_order_list.sales_order_list
        .map(so_dtl=> ({
            id :so_dtl.id,
            order_no:so_dtl.sales_order.prefix+so_dtl.sales_order.order_no,  
            order_date: so_dtl.sales_order.order_date,                             
            customer:so_dtl.sales_order.consignee.customer.name, 
            company:so_dtl.sales_order.company.name,
            project_name:so_dtl.sales_order.consignee.project_name,
            grade:so_dtl.product.name,
            delivery_mode:so_dtl.delivery_mode,
            total_dc_qty:so_dtl.total_dc_qty,
            total_accepted_qty:so_dtl.total_accepted_qty,
            so_rate:so_dtl.rate,
            isChecked:false,
            isDisabled:false
        }));
        setData({
            records: tableData
        });
        setIsLoading(false);
        setShowModal(true)
      })
    }
    else{
      Swal.fire("Please choose Plant.", '', 'info')
    }
  }

  const Close = () => {
    // console.log(JSON.stringify(checkedSoIds)+"checkedSoIds Close)");
    setShowModal(false)
    if ((inputs.plant_id) && (checkedSoIds.length>0)){
      let tcs_per = 0; // Initialize tcs_per in the outer scope
      getDeliveryChallansForInvoice(cookies,inputs.plant_id,checkedSoIds.join(','),inputs.invoice_date)
      .then(response => {
        setIsLoading(false);
        setDcList(response.delivery_challan_list)
        response.delivery_challan_list.map(dc => {
          setInputs(values => ({...values,
            sales_order_id: dc.work_schedule.sales_order_detail.sales_order.id,
            company_id:dc.work_schedule.sales_order_detail.sales_order.company.id,
            customer_id:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.id,
            consignee_id:dc.work_schedule.sales_order_detail.sales_order.consignee.id,
            sales_order_no: dc.work_schedule.sales_order_detail.sales_order.prefix+dc.work_schedule.sales_order_detail.sales_order.order_no,
            sales_order_date: getDisplayDate(dc.work_schedule.sales_order_detail.sales_order.order_date),
            is_tcs_applicable:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.is_tcs_applicable,
            tcs_per:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.tcs_per
          }))
          setSelectedSalesOrder(dc.work_schedule.sales_order_detail.sales_order);
          if(dc.accepted_qty > 0){
            setCheckedDcIds(values => ([...values,dc.id]))
          }
           tcs_per = dc.work_schedule.sales_order_detail.sales_order.consignee.customer.tcs_per;
          setDcList(prevData => (
            prevData.map(record => 
                record.id === dc.id ? { ...record, isChecked:dc.accepted_qty>0?true:false} : record
            )
          ));
        })
        const filteredRows = allSalesOrders.filter(order => checkedSoIds.includes(order.id)).map(order => ({
          id: order.id,
          isChecked:true,
          product: order.product.name,
          unit: order.product.unit.symbol,
          dc_qty: order.total_dc_qty,
          accepted_qty:order.total_accepted_qty,
          delivery_mode:order.delivery_mode.toUpperCase(),
          tax:order.tax.name,
          taxable_amt:order.taxable_amt,
          basic_rate:order.basic_rate,
          cgst_amt:order.cgst_amt,
          sgst_amt:order.sgst_amt,
          igst_amt:order.igst_amt,
          cgst_per:order.cgst_per,
          sgst_per:order.sgst_per,
          igst_per:order.igst_per,
          gst_amt:(parseFloat(order.cgst_amt)+parseFloat(order.sgst_amt)+parseFloat(order.igst_amt)).toFixed(2),
          gross_amt:(parseFloat(order.cgst_amt)+parseFloat(order.sgst_amt)+parseFloat(order.igst_amt)+parseFloat(order.taxable_amt)).toFixed(2)
        }))
        setRowsData(filteredRows);
        const totalAmounts = filteredRows.reduce((totals, row) => {
          return {
            taxable_amt: totals.taxable_amt + parseFloat(row.taxable_amt),
            cgst_amt: totals.cgst_amt + parseFloat(row.cgst_amt),
            sgst_amt: totals.sgst_amt + parseFloat(row.sgst_amt),
            igst_amt: totals.igst_amt + parseFloat(row.igst_amt),
            gst_amt: totals.gst_amt + parseFloat(row.gst_amt),
            gross_amt: totals.gross_amt + parseFloat(row.gross_amt)
          };
        }, { taxable_amt: 0, cgst_amt: 0, sgst_amt: 0, igst_amt: 0 ,gst_amt:0,gross_amt:0});
        // console.log(tcs_per+"tcs_per close")
        var tcs_amt = parseFloat(tcs_per/100)*parseFloat(totalAmounts.taxable_amt + totalAmounts.gst_amt)
        var gross_amt=(parseFloat(totalAmounts.taxable_amt)+ parseFloat(totalAmounts.gst_amt)+parseFloat(tcs_amt))
        // console.log(tcs_amt+"tcs_amt close")
        // Update the state with the calculated sums
        setInputs(prevInputs => ({
          ...prevInputs,
          taxable_amt: totalAmounts.taxable_amt.toFixed(2),
          cgst_amt: totalAmounts.cgst_amt.toFixed(2),
          sgst_amt: totalAmounts.sgst_amt.toFixed(2),
          igst_amt: totalAmounts.igst_amt.toFixed(2),
          gst_amt: totalAmounts.gst_amt.toFixed(2),
          tcs_amt:tcs_amt.toFixed(2),
          gross_amt: gross_amt.toFixed(2),
          invoice_amt:Math.round(gross_amt).toFixed(2),
          rounded_off:(Math.round(gross_amt)-(gross_amt)).toFixed(2)
        }));
        // console.log(Math.round(2128.5).toFixed(2)+"Math.round(gross_amt).toFixed(2)")
      })
      .catch((error) =>
      {
        setIsLoading(false);
        // console.log(error.response.data);
        displayError(error.response.data," Failed");
      })
        // console.log(JSON.stringify(allSalesOrders)+"allSalesOrders Close")
        // console.log(JSON.stringify(inputs)+"inputs close")
    }
  }

  const CloseDc = () => {
    // console.log(JSON.stringify(Dcrows)+"Dcrows CloseDc");
    const isChecked = Dcrows.some(row => row.isChecked);
    if (!isChecked) {
      const so_dtl_id = Dcrows.reduce((so_id, item) => {
        return item.so_dtl_id;
      }, 0);
      // console.log(so_dtl_id+"so_dtl_id");
      if (rowsData.length == 1){
        setRowsData([])
        setInputs(prevInputs => ({
          ...prevInputs,
          taxable_amt: 0,
          cgst_amt: 0,
          sgst_amt: 0,
          igst_amt: 0,
          gst_amt: 0,
          gross_amt: 0,
          invoice_amt:0,
          rounded_off:0,
          tcs_amt:0
        }));
      }
      else
      {
        // console.log(JSON.stringify(rowsData)+"rowsData CloseDc");
        const totalAmounts = rowsData.reduce((totals, row) => {
          if(row.id != so_dtl_id){
            // console.log("row.id != so_dtl_id"+JSON.stringify(row))
          return {
            taxable_amt: totals.taxable_amt + parseFloat(row.taxable_amt),
            cgst_amt: totals.cgst_amt + parseFloat(row.cgst_amt),
            sgst_amt: totals.sgst_amt + parseFloat(row.sgst_amt),
            igst_amt: totals.igst_amt + parseFloat(row.igst_amt),
            gst_amt: totals.gst_amt + parseFloat(row.gst_amt),
            gross_amt: totals.gross_amt + parseFloat(row.gross_amt)
          };
        }return totals;
        }, { taxable_amt: 0, cgst_amt: 0, sgst_amt: 0, igst_amt: 0 ,gst_amt:0,gross_amt:0});
        // console.log(JSON.stringify(totalAmounts)+"totalAmounts close Dc")
        var tcs_amt = parseFloat(inputs.tcs_per/100)*parseFloat(totalAmounts.taxable_amt + totalAmounts.gst_amt);
        var gross_amt = (parseFloat(totalAmounts.taxable_amt)+ parseFloat(totalAmounts.gst_amt)+parseFloat(tcs_amt))
        // Update the state with the calculated sums
        setInputs(prevInputs => ({
          ...prevInputs,
          taxable_amt: totalAmounts.taxable_amt.toFixed(2),
          cgst_amt: totalAmounts.cgst_amt.toFixed(2),
          sgst_amt: totalAmounts.sgst_amt.toFixed(2),
          igst_amt: totalAmounts.igst_amt.toFixed(2),
          gst_amt: totalAmounts.gst_amt.toFixed(2),
          gross_amt: gross_amt.toFixed(2),
          tcs_amt:tcs_amt.toFixed(2),
          invoice_amt:Math.round(gross_amt).toFixed(2),
          rounded_off:(Math.round(gross_amt)-(gross_amt)).toFixed(2)
        }));
        
        setRowsData(prevRows => prevRows.filter(item => item.id !== so_dtl_id));
        setShowModalDc(false)
      }
      // Swal.fire('No dcs are selected.', '', 'info')
    } 
    else{
    // To calculate total accepted qty
    const acceptedQtyMap = Dcrows.reduce((map, item) => {
      if (item.isChecked) {
        // console.log("if");
        const id = item.so_dtl_id;
        map[id] = (map[id] || 0) + item.accepted_qty;
        map['dc_qty'] = (map['dc_qty'] || 0) + item.dc_qty;
      }
      return map;
    }, {});
    console.log(JSON.stringify(acceptedQtyMap)+"acceptedQtyMap");

    // Update rowsData based on the acceptedQtyMap
    rowsData.forEach(row => {
      if (acceptedQtyMap.hasOwnProperty(row.id)) {
        console.log(JSON.stringify(row)+"row");
        row.accepted_qty = acceptedQtyMap[row.id];
        row.dc_qty = acceptedQtyMap['dc_qty'];
        var taxable_amt = parseFloat(row.basic_rate)*parseFloat(acceptedQtyMap[row.id]);
        // console.log(row.cgst_per+"row.cgst_per")
        var cgst_amt = ((taxable_amt*row.cgst_per)/100).toFixed(2);
        row.taxable_amt=(taxable_amt).toFixed(2)
        row.cgst_amt=cgst_amt
        row.sgst_amt=((taxable_amt*row.sgst_per)/100).toFixed(2)
        row.igst_amt=((taxable_amt*row.igst_per)/100).toFixed(2)
        row.gst_amt=(parseFloat(row.cgst_amt)+parseFloat(row.sgst_amt)+parseFloat(row.igst_amt)).toFixed(2)
        row.gross_amt=(parseFloat(row.gst_amt)+parseFloat(row.taxable_amt)).toFixed(2)
      }
    });

    const totalAmounts = rowsData.reduce((totals, row) => {
      return {
        taxable_amt: totals.taxable_amt + parseFloat(row.taxable_amt),
        cgst_amt: totals.cgst_amt + parseFloat(row.cgst_amt),
        sgst_amt: totals.sgst_amt + parseFloat(row.sgst_amt),
        igst_amt: totals.igst_amt + parseFloat(row.igst_amt),
        gst_amt: totals.gst_amt + parseFloat(row.gst_amt),
        gross_amt: totals.gross_amt + parseFloat(row.gross_amt)
      };
    }, { taxable_amt: 0, cgst_amt: 0, sgst_amt: 0, igst_amt: 0 ,gst_amt:0,gross_amt:0});
    // console.log(JSON.stringify(totalAmounts)+"totalAmounts close Dc")
    // Update the state with the calculated sums
    var tcs_amt = parseFloat(inputs.tcs_per/100)*parseFloat(totalAmounts.taxable_amt + totalAmounts.gst_amt)
    var gross_amt = (parseFloat(totalAmounts.taxable_amt)+ parseFloat(totalAmounts.gst_amt)+parseFloat(tcs_amt));
    setInputs(prevInputs => ({
      ...prevInputs,
      taxable_amt: totalAmounts.taxable_amt.toFixed(2),
      cgst_amt: totalAmounts.cgst_amt.toFixed(2),
      sgst_amt: totalAmounts.sgst_amt.toFixed(2),
      igst_amt: totalAmounts.igst_amt.toFixed(2),
      gst_amt: totalAmounts.gst_amt.toFixed(2),
      tcs_amt:tcs_amt.toFixed(2),
      gross_amt:  gross_amt.toFixed(2),
      invoice_amt:Math.round(gross_amt).toFixed(2),
      rounded_off:(Math.round(gross_amt)-(gross_amt)).toFixed(2)
    }));
    setShowModalDc(false)
  }
  }

  const [checkedDcIds, setCheckedDcIds] = useState([]);

  const handleChangeDc = (value, event) => {
    // console.log(value,"value",event.target.checked,"event.target.checked handleChangeDc")
    const isChecked = event.target.checked;
    if (isChecked) {
        setCheckedDcIds(prevIds => [...prevIds, value]);
    } else {
        setCheckedDcIds(prevIds => prevIds.filter(item => item !== value));
    }
    setDcrows(prevData => (
    prevData.map(record => 
        record.id === value ? { ...record, isChecked } : record
    )
    ));
    setDcList(prevData => (
    prevData.map(record => 
        record.id === value ? { ...record, isChecked} : record
    )
    ));
  }

  const columns =
      useMemo(
          () => [
              {
                  fieldName: "id",
                  headerName: "ROW_ACTIONS",
                  size:40
              },
              {
                  fieldName: 'order_no',
                  headerName: 'Order No',
                  size:200
              },
              {
                  fieldName: 'order_date',
                  headerName: 'Order Date',
                  size:100
              },
              {
                  fieldName: "company",
                  headerName: 'Company',
                  size:180
              },
              {
                  fieldName:"customer",
                  headerName: 'Customer',
                  size:180
              },
              {
                  fieldName:"project_name",
                  headerName: 'Project Name',
                  size:180
              },
              {
                fieldName:"grade",
                headerName: 'Grade',
                size:80
              },
              {
                fieldName:"so_rate",
                headerName: 'Rate',
                size:100
              },
              {
                fieldName:"delivery_mode",
                headerName: 'Delivery Mode',
                size:100
              },
              {
                fieldName:"total_dc_qty",
                headerName: 'Dc Qty',
                size:100
              },
              {
              fieldName:"total_accepted_qty",
              headerName: 'Accepted Qty',
              size:100
              }
          ],
  [],);

  const columnsDc =
      useMemo(
        () => [
          {
            fieldName: "id",
            headerName: "ROW_ACTIONS",
            size:40
          },
          {
            fieldName: 'checkout_no',
            headerName: 'Check out No.',
            size:50
          },
          {
            fieldName: 'delivery_challan_no',
            headerName: 'Dc No',
            size:200
          },
          {
            fieldName: 'vehicle_no',
            headerName: 'Vehicle No.',
            size:180
          },
          {
            fieldName: 'customer_dc_status',
            headerName: 'DC Status',
            size:180
          },
          {
            fieldName: 'customer_status',
            headerName: 'Customer Status',
            size:180
          },
          {
            fieldName: "delivery_challan_date",
            headerName: 'DC Date',
            size:100
          },
          {
            fieldName: 'order_no',
            headerName: 'SO No',
            size:180
          },
          {
            fieldName: "order_date",
            headerName: 'SO Date',
            size:100
          },
          {
            fieldName:"unit",
            headerName: 'Unit',
            size:50
          },
          {
            fieldName:"grade",
            headerName: 'Grade',
            size:100
          },
          {
            fieldName:"rate",
            headerName: 'Rate',
            size:100
          },
          {
            fieldName:"delivery_mode",
            headerName: 'Delivery Mode',
            size:100
          },
          {
            fieldName:"dc_qty",
            headerName: 'Dc Qty',
            size:100
          },
          {
          fieldName:"accepted_qty",
          headerName: 'Accepted Qty',
          size:100
          }
        ],
  [],);

  const Cancel = () => {
      setInputs(() => "")
      setRowsData([])
  }
        
  const view = () => {
    navigate('/SalesInvoiceTable')
  } 
      
  const Back = () => {
    navigate('/Home')
  }

  const updateInvoiceNumberAndPrefix = (prefix, serial_no) => {
    setInputs(values => ({...values,
        ['invoice_num_prefix']:(prefix+serial_no),
        ['invoice_no']:serial_no,
        ['prefix']:prefix}));
  }

return (
  <>
    <div id="outer-container"  className="App" > 
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
      <ErrorToast/>
      <LoadingOverlay inProgress={inProgress}/>
      
      <div id="page-wrap">
        <form onSubmit={handleSubmit} > 
          <div id="header">
            <h3 className = "text font-weight-bold page-title" >TAX INVOICE </h3>
          </div>
          
          <FloatingControls tableLink="/SalesInvoiceTable" onCancel={Reload} enableCancel={true}/>
          <div className="container form-control-panel">
                   
            <Row>
              <Col xl={6} lg={12} md={12}>
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
                  <label htmlFor="plant_id" className="form-group col-sm-5 text-right">Plant Location</label>
                  <select id="plant_id" name="plant_id" className="form-control col-sm-6  browser-default custom-select  mandatory-form-control"  required onChange={handleChange} value={inputs.plant_id || ""}>
                    <option value="">Select Plant</option>
                    {plants.map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.label}</option>
                    ))}
                  </select>
                  <br/>
                
                  <label htmlFor="sales_order_no" className="form-group col-sm-5 text-right">Sales order No</label>
                  <input type="text" className="form-control col-sm-6"   id="sales_order_no" value={inputs.sales_order_no || ""} disabled={true} style={{cursor: "not-allowed" }} /><br />
                  <QuestionIcon onClickHandler={ShowOrderDetails}/><br />  
                   
                  <label htmlFor="sales_order_date" className="form-group col-sm-5 text-right">Sales order Date</label>
                  <input type="date" className="form-control col-sm-6"   id="sales_order_date" value={inputs.sales_order_date || ""} style={{cursor: "not-allowed" }} disabled={true}/><br />

                  <label htmlFor="customer_id" className="form-group col-sm-5 text-right">Billing Name</label>
                  <select  id="customer_id" name="customer_id" className="form-control col-sm-6"   value={inputs.customer_id || ""} disabled={true} style={{cursor:"not-allowed"}}>
                    <option value=""></option>
                    {customers.map((item) => (
                            <option key={item.value} value={item.value}>
                            {item.label}
                            </option>
                    ))}
                  </select>
                  <br />
                  <SummaryIcon onClickHandler={showCustomerDetails}/>
                  
                  <label htmlFor="consignee_id" className="form-group col-sm-5 text-right">Ship to</label>
                  <select  id="consignee_id" name="consignee_id" className="form-control col-sm-6"  value={inputs.consignee_id || ""} disabled={true} style={{cursor:"not-allowed"}}>
                    <option value=""></option>
                    {sites.map((item) => (
                            <option key={item.value} value={item.value}>
                            {item.label}
                            </option>
                    ))}
                  </select><br />
                  <SummaryIcon onClickHandler={showSiteDetails}/>
                  <br />
                </div>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel ">
                  <NumberSetting 
                      handleDateChange={handleInvoiceDetails}
                      serial_no={inputs.invoice_no} 
                      creation_date={inputs.invoice_date}
                      creation_time={inputs.invoice_time}
                      prefix={inputs.prefix}
                      company_id={inputs.plant_id}
                      voucher_type={"invoice"}
                      handleNumberAndPrefixUpdate={updateInvoiceNumberAndPrefix}
                      cookies={cookies}
                      serial_no_title={'Invoice No'}
                      hideTime={false}
                  />

                  {/* <label htmlFor="invoice_time" className="form-group col-sm-4 text-right">Invoice Time</label>
                  <input type="time" id="invoice_time"  name="invoice_time"  value={inputs.invoice_time || ""} className="form-control col-sm-7" disabled={true} style={{cursor: "not-allowed"}}/>  
                   */}
                  <label htmlFor="is_tcs_applicable" className="form-group col-sm-4 text-right">Is TCS Applicable </label>
                  <select name="is_tcs_applicable" id="is_tcs_applicable"  value={inputs.is_tcs_applicable} className=" form-control col-sm-7 "  disabled={true} style={{cursor: "not-allowed"}} >
                    <option value=""></option> 
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <br/>
                  
                  <ViewOnlyTextbox 
                    name="project_name" 
                    value={selectedSalesOrder?.consignee?.project_name|| ""}
                    labelClassName="form-group col-sm-4 text-right"
                    label="Project Name"
                    inputClassName="form-control col-sm-7"
                  /> 
                  
                  <label htmlFor="is_einvoice_needed" className="form-group col-sm-4 text-right">E - invoice</label>
                  <select name="is_einvoice_needed" id="is_einvoice_needed" onChange={handleChange} value={inputs.is_einvoice_needed || ""} className="browser-default custom-select form-control col-sm-7 mandatory-form-control" required >
                    <option value="">Please select</option>
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <br/>
                   
                  <div className="modal" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                      <div className="modal-content">
                        <div className="modal-body display">
                          <div className="container item-list-table-container">
                            <ReactTablePopUpMultiSelect
                              title='List of Group Form'
                              columns={columns}
                              data={data.records}
                              onRowSubmit={handleChangeSalesOrderDetails}
                              isLoading={isLoading}
                              onClose = {Close}
                            />
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> 

                  <div className="modal" tabIndex="-1" style={{ display: showModalDc ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                      <div className="modal-content">
                        <div className="modal-body display">
                          <div className="container item-list-table-container">
                            <ReactTablePopUpMultiSelect
                              title='List of Group Form'
                              columns={columnsDc}
                              data={Dcrows}
                              onRowSubmit={handleChangeDc}
                              isLoading={isLoading}
                              onClose = {CloseDc}
                            />
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </Col>
            </Row>

            {rowsData.length>0 && (
              <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table-bordered table-responsive searchTable table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)",border:'4px solid rgb(235, 73, 73)'}}>
                    <thead>
                      <tr style={{backgroundColor:"RGB(188,232,253)"}}>
                          <th style={{display:"None"}}>Id</th>
                          <th>Product Code</th>
                          <th>Unit</th>
                          <th>Mode Of Delivery</th>
                          <th>DC Qty</th>
                          <th>Acc/Invoice Qty</th>
                          <th>Basic Rate</th>
                          <th>Taxable Amount</th>
                          <th>GST %</th>
                          <th>CGST Amount</th>
                          <th>SGST Amount</th>
                          <th>IGST Amount</th>
                          <th>GST Amount</th>
                          <th>Gross Amount</th>
                      </tr>
                    </thead>
                    <tbody >
                    {/* handleChange4={handleChange4} */}
                    <TableRowsSalesPosting rowsData={rowsData}     showDcdetails={showDcdetails} />
                    </tbody>
                </table>
              </div> 
            )}
            
            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
              <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"white",border:'4px solid rgb(235, 73, 73)'}}>
                <tbody>
                  <tr >
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Taxable Amount </b></td>
                    <td colSpan="4"><input type="text" className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right"}} value={inputs.taxable_amt || 0}></input></td>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>CGST Amount </b></td>
                    <td colSpan="4"><input type="text" className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.cgst_amt || 0}></input></td>
                  </tr>                          
                  <tr>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total GST Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.gst_amt || 0}></input></td>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b> SGST Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.sgst_amt || 0}></input></td>
                  </tr>
                  <tr>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Gross Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.gross_amt || 0}></input></td>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>IGST Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.igst_amt || 0}></input></td>
                  </tr>
                  <tr>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Round off  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control'style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.rounded_off || 0}></input></td>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>TCS Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.tcs_amt || 0}></input></td>
                  </tr>
                  <tr>
                      <td colSpan="14"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Invoice Amount  </b></td>
                      <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.invoice_amt || 0}></input></td>
                  </tr>
                </tbody> 
              </table>
            </div>

            <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel ">
                <label htmlFor="remarks" className="form-group col-sm-2 text-right">Remarks</label>
                <textarea rows="2" cols="50" className="form-control col-sm-10" id="remarks" name="remarks" onChange={handleChange} value={inputs.remarks || ""}></textarea>
            </div>
          </div>

          <div className="footer text-center">
            <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> 
            <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Reload}>Reset</Button>
            <Button className="btn btn-twitter" type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
            <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
          </div>
        </form>
      </div>
    </div>
  </>
);
}
export default SalesInvoice;