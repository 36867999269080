import {
    Row,
    Col,
    Button,
  } from 'reactstrap';
import { useNavigate } from 'react-router';
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useState ,useEffect,useRef  } from "react";
import { Link, useParams } from 'react-router-dom';
import React, { Component, Fragment} from 'react';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { AiOutlineHome } from "react-icons/ai";
import PasswordStrengthMeter from '../../PasswordStrengthMeter';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useCookies } from 'react-cookie';
import {displayErrorToast, displayError,parseBoolean} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getAllPermission} from '../../services/PermissionServices';
import { getAllPlants} from '../../services/PlantServices';
import { updateUser,getUserDetails } from '../../services/UserServices';
import { isPasswordValid, isUserNameValid } from './UserUtils';
import { checkForDuplicateUsers } from './UserUtils';
const axios = require('axios').default;

const Option = (props) => {
    return (
        <>
            <components.Option {...props}>
                <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}/>{" "}
            <label>{props.label}</label>
            </components.Option>
        </>
    );
};

  function UserEdit() 
  {
    const [cookies] = useCookies(['myToken']);
    const {id}=useParams()
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    //const [user, setUser] = useState({});
    const [error, setError] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [items, setItems] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [plants, setPlants] = React.useState([]);
    const [userFormData, setUserFormData] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [isdisabled,setIsdisabled] =useState(false);
    const selectInputRef = useRef();
    React.useEffect(() => {
        
        //Load data for Category dropdown
        getAllPermission(cookies)
        .then (
            categoryList => {
              // filter(obj => obj.status).
                const categories = categoryList.group_list.map(
                    category => {
                        return { value: category.id, label: category.name }
                    }
                );
                setCategories(categories);
            }
        )
    }, []);

    React.useEffect(() => {
        
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                
                const plants = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlants(plants);
            }
        )
    }, []);

    React.useEffect(() => {
        
        //Load data for user Edit form details
        getUserDetails(cookies, id)
        .then((userObject) => {
          console.log(JSON.stringify(userObject)+"userObject")
          setUserFormData({...userObject});
          // , password: ''
          if (userObject.is_superuser){
            console.log("superuser")
            // setSelectedCategory(null);
            // selectInputRef.current.setValue("");
            setIsdisabled(true)
          }
            setSelectedCategory(userObject.group_list.map(category=>({
              value:category.id,
              label:category.name,
          })))
            setSelectedPlant(userObject.plant_list.map(plant => ({
              value:plant.id,
              label:plant.alias,
          })))
        })
    }, []);

    const onCategorySelect = (selected) => {
        setSelectedCategory(selected);
    };

    const onPlantSelect = (selected) => {
        setSelectedPlant(selected);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        try{      
            if(name === 'username')           //User name must not be empty,otherwise showing error message 
            {
            
                if(!value.trim())
                {
                setError(values => ({...values, [name]: "User Name must not be empty..."}))
                setIsSubmitting(false); 
                }
                else
                {
                    
                    setError(values => ({...values, [name]: ''}))
                    setIsSubmitting(true);
                    
                }
            }
            else if(name === 'email')
            {
                if(value.trim())
                {
                    if(!/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value.trim()))
                    {
                        setError(values => ({...values, [name]: "Invalid Email Id..."}))
                        setIsSubmitting(false); 
                    }
                    else
                    {
                        
                        setError(values => ({...values, [name]: ''}))
                        setIsSubmitting(true);
                    }
                } else
                {
                    
                    setError(values => ({...values, [name]: 'Please give Email id..'}))
                    setIsSubmitting(false);
                }
            }
            
            setUserFormData(values => ({...values, [name]: value}))
        }
        catch(e)
        {
        displayErrorToast(e);
        }
    }
        

    
const handlePasswordField= (event) => {
    const name = event.target.name;
    const value = event.target.value.split(" ").join("");
    setUserFormData(values => ({...values, [name]: value}));
    try{      
        if(name === 'newPassword')      //User password must not be empty,otherwise showing error message 
        {
            if(event.target.value.split(" ").join("") === '')
            {
                setError(values => ({...values, [name]: "Password must not be empty..."}))
                setIsSubmitting(false); 
            }
            else
            {
              const passwordInvalidMessage = isPasswordValid(value)
              if (passwordInvalidMessage) {
                  setError(values => ({...values, [name]: passwordInvalidMessage}))
                  setIsSubmitting(true);
              }
              else
              {
                  setError(values => ({...values, [name]: ''}))
                  setIsSubmitting(true);
              }
            }
          }
          else if(name === 'userPassword')
          {
            if(event.target.value.split(" ").join("") === '')   //Userconfirm password must not be empty & mismatch password,otherwise showing error message 
            {
              console.log("confirm psw data is empty");
              setError(values => ({...values, [name]: "Confirm Password must not be empty..."}))
              setIsSubmitting(false); 
            }
            else
            {
              if(value != userFormData.newPassword)
              {
                console.log("password mismatch");
                setError(values => ({...values, [name]: "password mismatch..."}))
                setIsSubmitting(false); 
              }
              else
              {
                setError(values => ({...values, [name]: ''}))
                setIsSubmitting(true);
              }
            }
          }
        }
        catch(e)
        {
          displayErrorToast(e);
        }
        }
      
      const [isStrength, setStrength] = useState(null);
      const dataHandler = async (childData) => {
        setStrength(childData);
      }

const handleUpdate = () => {
  console.log(JSON.stringify(userFormData)+"userformdata")
    Swal.fire({
      title: "Do you want to Update?",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
       }).then((result) => {
        if (result.isConfirmed) {
          console.log(JSON.stringify(userFormData)+"userFormData");
       updateUser(
        cookies,
        {
            username:userFormData.username,
            first_name:userFormData.first_name,
            last_name:userFormData.last_name,
            password:userFormData.password,
            email:userFormData.email,
            is_mfa_needed:parseBoolean(userFormData.is_mfa_needed),
            is_location_auth_needed:parseBoolean(userFormData.is_location_auth_needed),
            is_active:userFormData.is_active,
            user_mobile_no:userFormData.user_mobile_no,
            groups:selectedCategory.map(category=>category.value),
            user_valid_from:userFormData.user_valid_from,
            user_valid_upto:userFormData.user_valid_upto,
            user_remarks:userFormData.user_remarks,
            plants:selectedPlant.map(plant=>plant.value),
            password: userFormData.newPassword?userFormData.newPassword:userFormData.password
          }
        ,id)
        .then(response => {
            Swal.fire("Updated!", "", "success");
            Reload();
        })
        .catch((error) => {
            console.log(error.response.data);
            displayError(error.response.data,"Update Failed");
        });

        
      } else if (result.isDismissed) {
        Swal.fire("Not Updated", "", "info");
      }
    });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
   
    try{
        checkForDuplicateUsers(event.target.username.value, event.target.userEmail.value, cookies, id)
      .then(isNameExists => {
          
        if (isNameExists) {
          setError(values => ({...values, [event.target.username.name]: 'User name already exists.'}));
        } else {
console.log(JSON.stringify(error)+"error"+isSubmitting);
          if (Object.values(error).every((x) => !x) && isSubmitting ) {        //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
            if (id) {
              if (userFormData.is_superuser){
                handleUpdate();
                console.log(" superuser")
              }
              else{
                if ((selectedCategory.length>0) && (selectedPlant.length>0)){
                  console.log("plant and category not superuser");
                  console.log(JSON.stringify(selectedCategory)+"selectedCategory")
                  console.log(JSON.stringify(selectedPlant)+"selectedPlant")
                  handleUpdate();
                }
                else{
                  console.log(" not plant and categorynot superuser")
                  Swal.fire("Plants and Category must not be empty.","","info" );
                }
              }
            } 
          } else {
            Swal.fire("Please check * marked fields are filled and details given are valid...","","info" );
          }
        }
    }); 
  } catch (e) {
      displayErrorToast(e);
  } 
        
 } 
    const Reload = () => {
      navigate('/UserTable')
    } 
                        
    const view = () => {
      navigate('/UserTable')
    }
    
    const Back = () => {
      navigate('/Home')
    }
      
                         
    return(
      <>
      {/* <div id="outer-container"  className="App" >  */}
          <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
          <ErrorToast/>
          <LoadingOverlay inProgress={inProgress}/>
          <div id="page-wrap">
          <form className="form" onSubmit={handleSubmit} data-id={inputs.id}>
                <div id="header"> 
                    <h3 className = "text font-weight-bold page-title">USER REGISTRATION EDIT FORM</h3>
                </div>
                <FloatingControls tableLink="/UserTable" enableCancel={false}/>
                <div className="container"  > 
                    <Row> 
                        <Col xl={6} lg={12} md={12}>
                            <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel">
                            <h5 className='col-sm-11'><b>User Details</b></h5><br/><br/>
                                <label htmlFor="username"className="form-group col-sm-4 text-right  ">User Name </label>
                                <input type="text" id="username" name="username"  onChange={handleChange} value={userFormData.username || ""} className="form-control col-sm-7 mandatory-form-control"  placeholder="Please fill the NAME field *"  required />
                                {error.username && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.username}</strong><br/></>)}
                               
                                <label htmlFor="first_name"className="form-group col-sm-4 text-right ">First Name</label>
                                <input type="text" id="first_name" name="first_name"   onChange={handleChange} value={userFormData.first_name || ""} className="form-control col-sm-7 "  placeholder="Please fill the First Name Field *" />
                                
                                <label htmlFor="last_name"className="form-group col-sm-4 text-right  ">Last Name</label>
                                <input type="text" id="last_name" name="last_name"  onChange={handleChange} value={userFormData.last_name || ""} className="form-control col-sm-7 "  placeholder="Please fill the Last Name Field *" />
                                
                                <label htmlFor="newPassword" className="form-group col-sm-4 text-right">Password</label>
                                <input type="Password" className="form-control col-sm-7 " id="newPassword" onChange={handlePasswordField} value={userFormData.newPassword || ""} name="newPassword" maxLength="8" />
                                {error.newPassword && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.newPassword}</strong><br/></>)} 
                                <PasswordStrengthMeter password={userFormData.newPassword} actions={dataHandler}/>
                                
                                <label htmlFor="userPassword" className="form-group col-sm-4 text-right">Confirm Password </label>
                                <input type="Password" className="form-control col-sm-7" id="userPassword" onChange={handlePasswordField} value={userFormData.userPassword || ""} name="userPassword" maxLength="8" />
                                {error.userPassword && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.userPassword}</strong><br/></>)} 
                                                            
                                <label htmlFor="userEmail"className="form-group col-sm-4 text-right">Email id </label>
                                <input type="userEmail" id="userEmail" name="email" onChange={handleChange} value={userFormData.email || ""} className="form-control col-sm-7 mandatory-form-control" required />
                                {error.email && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.email}</strong><br/></>)}

                                <label htmlFor="is_location_auth_needed" className="form-group col-sm-4 text-right">Is Location Authentication Needed? </label>
                                <select className="browser-default custom-select form-control col-sm-7 mandatory-form-control" id="is_location_auth_needed" onChange={handleChange} value={userFormData.is_location_auth_needed}  name='is_location_auth_needed' required >
                                  <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                               
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={12}>
                            <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel ">
                            <h5 className='col-sm-11'><b>User Vaild Date</b></h5><br/><br/>
                            <label htmlFor="is_mfa_needed" className="form-group col-sm-4 text-right">Is MFA? </label>
                                <select className="browser-default custom-select form-control col-sm-7 mandatory-form-control"id="is_mfa_needed" onChange={handleChange} value={userFormData.is_mfa_needed}  name='is_mfa_needed' required >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                                
                                <label htmlFor="Checkbox"className="form-group col-sm-4 text-right">Plant Location </label>
                                  <div style={{width: '58%'}} >
                                   <ReactSelect
                                    options={plants}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedPlant}
                                    onChange={onPlantSelect}
                                    className={"mandatory-form-control"}
                                    placeholder="Please select Plant name"
                                    isDisabled={isdisabled}
                                  />
                                </div>
                                
                                <label htmlFor="user_mobile_no"className="form-group col-sm-4 text-right  ">Mobile No</label>
                                <input type="text" id="user_mobile_no"name="user_mobile_no" onChange={handleChange} value={userFormData.user_mobile_no || ""}   className="form-control col-sm-7 " />
                                
                                <label htmlFor="userCategory"className="form-group col-sm-4 text-right">Category </label>
                                <div style={{width: '58%'}} >
                                   <ReactSelect
                                    options={categories}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedCategory}
                                    onChange={onCategorySelect}
                                    className={"mandatory-form-control"}
                                    placeholder="Please select Category name"
                                    // ref={selectInputRef}
                                    isDisabled={isdisabled}
                                  />
                                </div>

                                <label htmlFor="user_valid_from"className="form-group col-sm-4 text-right  ">Valid From Date </label>
                                <input type="date" id="user_valid_from" name="user_valid_from"onChange={handleChange} value={userFormData.user_valid_from || ""}  className="form-control col-sm-7 rounded mandatory-form-control" required maxLength="50" />
                                
                                <label htmlFor="user_valid_upto" className="form-group col-sm-4 text-right  ">Valid Upto Date </label>
                                <input type="date" id="user_valid_upto" name="user_valid_upto" onChange={handleChange} value={userFormData.user_valid_upto || ""} className="form-control col-sm-7 rounded mandatory-form-control" required  maxLength="50" />
                                
                                <label htmlFor="user_remarks"className="form-group col-sm-4 text-right  ">Remarks</label>
                                <input type="text" id="user_remarks" name="user_remarks"onChange={handleChange} value={userFormData.user_remarks || ""}  className="form-control col-sm-7" />
                                
                                <label htmlFor="is_active"className="form-group col-sm-4 text-right">Status </label>
                                <select name="is_active" id="is_active" onChange={handleChange} value={userFormData.is_active} className="browser-default custom-select col-sm-7 mandatory-form-control" disabled={isdisabled}>
                                    <option value=''>Please Select</option>
                                    <option value='true'>Active</option>
                                    <option value='false'>Inactive</option>
                                </select> 
                            </div>
                        </Col>
                    </Row> 
                </div>    
                    
                <div className="footer text-center"><br/>
                    <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
            </form><br/>
            </div><br/>
            {/* </div>  */}
            
        </>
    );
  }
  export default UserEdit;