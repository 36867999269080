import React from 'react';
import { useState } from "react";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import {displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { createPermission, getAllPermission } from '../../services/PermissionServices';
import { updateSectionColor} from './PermissionUtils';

function Permission() {
    const [cookies] = useCookies(['myToken']);
    const [inputs, setInputs] = useState({
        name:''
    });
    const [isChecked, setIsChecked] = useState({
        is_rmc_admin:false,
        approve_po : false,
        approve_so : false,
        add_entity_customer_detail  : false,
        change_entity_customer_detail : false,
        view_entity_customer_detail : false,
        delete_entity_customer_detail : false,
        add_entity_consignee_detail : false,
        change_entity_consignee_detail : false,
        view_entity_consignee_detail : false,
        delete_entity_consignee_detail : false,
        add_quotation  : false,
        view_quotation  : false,
        edit_quotation: false,
        delete_quotation  : false,
        add_sales_order_master  : false,
        view_sales_order_master  : false,
        change_sales_order_master: false,
        delete_sales_order_master     : false,
        view_marketting_reports   : false,
        add_work_schedule_master   : false,
        view_work_schedule_master  : false,
        change_work_schedule_master       : false,
        delete_work_schedule_master     : false,
        view_planning_reports      : false,
        add_entity_vendor_detail   : false,
        view_entity_vendor_detail  : false,
        change_entity_vendor_detail : false,
        delete_entity_vendor_detail : false,
        add_purchase_order   : false,
        view_purchase_order     : false,
        edit_purchase_order    : false,
        delete_purchase_order    : false,
        add_purchase_invoice   : false,
        view_purchase_invoice    : false,
        edit_purchase_invoice   : false,
        delete_purchase_invoice   : false,
        add_design_mix_master: false,
        view_design_mix_master          : false,
        change_design_mix_master         : false,
        delete_design_mix_master         : false,
        add_moisture_correction_master       : false,
        view_moisture_correction_master   : false,
        change_moisture_correction_master  : false,
        delete_moisture_correction_master  :false,
        add_compressive_strength_master   : false,
        view_compressive_strength_master   : false,
        change_compressive_strength_master  :false,
        delete_compressive_strength_master  : false,
        add_pump_clearance: false,
        view_pump_clearance  : false,
        edit_pump_clearance  : false,
        delete_pump_clearance  : false,
        add_del_challan_master: false,
        view_del_challan_master   : false,
        change_del_challan_master  : false,
        delete_del_challan_master  : false,
        view_dispatch_reports: false,
        add_ledger     : false,
        view_ledger    : false,
        edit_ledger    : false,
        delete_ledger  : false,
        add_journal_master   : false,
        view_journal_master  : false,
        change_journal_master  : false,
        delete_journal_master: false,
        add_grouping        : false,
        view_grouping       : false,
        edit_grouping       : false,
        delete_grouping     : false,
        add_entity_bank_detail : false,
        view_entity_bank_detail : false,
        change_entity_bank_detail : false,
        delete_entity_bank_detail : false,
        add_sales_invoice       : false,
        view_sales_invoice      : false,
        edit_sales_invoice      : false,
        delete_sales_invoice   : false,
        add_receipt_master    : false,
        view_receipt_master   : false,
        change_receipt_master   : false,
        delete_receipt_master : false,
        view_accounts_reports : false,
        add_grn: false,
        view_grn: false,
        change_grn   : false,
        delete_grn       : false,
        view_store_reports     : false,
        add_gate_pass_master      : false,
        view_gate_pass_master    : false,
        change_gate_pass_master        : false,
        delete_gate_pass_master       : false,
        view_gate_reports       : false,
        add_equipment_master    : false,
        view_equipment_master     : false,
        change_equipment_master    : false,
        delete_equipment_master    : false,
        view_transport_reports  : false
    });
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);
    const [section, setSection] = useState({
        marketting:false,
        planning:false,
        quality_control:false,
        purchase:false,
        dispatch:false,
        accounts:false,
        gate:false,
        transport:false,
        rmc_admin:false
    })
 
    const handleCheckboxes = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        if(name === 'is_rmc_admin')
        {
            var c_len = document.querySelectorAll( '.is_rmc_admin').length;
            for (var i = 0; i < c_len; i++) 
            {
                document.getElementsByClassName("is_rmc_admin")[i].checked = value;
            }
        }
        setIsChecked(values => ({...values, [name]: value}))
        // Get the class name of the clicked checkbox
            const checkboxClass = event.target.className;
        // Update section color based on checked checkboxes
            updateSectionColor(`.${checkboxClass}`,setSection);
    }
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name==='name')
        {
            if(!value.trim())
            {
                setError(values => ({...values, [name]: "Category name must not be empty."}))
                setIsSubmitting(false); 
            }
            else
            {
        //    getAllPermission(cookies)
        //           .then((data) => {
        //             var err=0;
        //             console.log(inputs+"inputs")
        //             for(var i=0; i<data.group_list.length; i++) 
        //             {
        //                 if((data.group_list[i].name.toUpperCase()) === value.trim().toUpperCase()){
        //                 setError(values => ({...values, [name]: "Given Category Name already exists..Please give new one.."}))
        //                 console.log(setError)
        //                 setIsSubmitting(false);
        //                 var err= 1;
        //                 break;
        //               }
        //             }
        //             if(err === 0)
        //               {
                        setError(values => ({...values, [name]: ''}))
                        setIsSubmitting(true);
                    }
    //           });
    //       }
        }
        setInputs(values => ({...values, [name]: value}))
    }
  
    const handleSubmit=(event)=> {
        event.preventDefault();
        console.log((Object.values(error).every(x => !x))+JSON.stringify(error)+ isSubmitting+"submit");
        if ((Object.values(error).every(x => !x)) && isSubmitting) {   //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
            Swal.fire({title: 'Do you want to save?',  
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
            if (result.isConfirmed) { 
                var filteredObj = Object.keys(isChecked).reduce((p,c) => {    
                    if (isChecked[c]) p[c] = c;
                    return p;
                }, {});
                console.log(JSON.stringify(filteredObj)+"filterobj");
                const propertyNames = Object.keys(filteredObj);
                console.log(propertyNames+"propertynames");
                console.log(JSON.stringify(inputs)+"inputsubmit")
                createPermission(cookies, {
                    id:inputs.id,
                    name: (inputs.name).toUpperCase(),
                    permissions:propertyNames,
                })
                //console.log(createPermission+"update")
                .then(response => {
                    Swal.fire('Saved!', '', 'success') 
                    view();
                }).catch((error) => {
                    console.log(error.response.data);
                    displayError(error.response.data,"Saved Failed");
                });
            } 
            else if (result.isDismissed) {
                Swal.fire("Not Saved", "", "info");
            }
            });
        }
    }

    const Reload = () => {
            window.location.reload();
    }      
      
    const view = () => {
        navigate('/PermissionTable')
    } 
      
    const cancel = () => {
        // setInputs(() => "");
        setInputs({
            name:''
            });
        setIsChecked(() => false)
    }
      
    const Back = () => {
        navigate('/Home')
    }
      
return (
    <>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <ErrorToast/>
            <LoadingOverlay inProgress={inProgress}/>
            <div id="page-wrap">
                <form className="form" onSubmit={handleSubmit} >
                    <div id="header">
                        <h3 className = "text font-weight-bold page-title" >PERMISSION FORM</h3>
                    </div>
                    <FloatingControls tableLink="/PermissionTable" onCancel={cancel} enableCancel={true}/>
                    <div className="container">
                        <Row>
                            <Col xl={4} lg={12} md={12}>
                            </Col>
                            <Col xl={4} lg={12} md={12}>
                                <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                                    <h5 className='col-sm-11'><b>Category Details</b></h5><br/><br/>
                                    
                                    <label htmlFor="name" className="form-group col-sm-4 text-left">Category Name </label>
                                    <input type="text" id="name" className="form-control col-sm-8 mandatory-form-control"required name="name" onChange={handleChange} value={inputs.name || ""} /><br/><br/>
                                    {error.name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.name}</strong><br/></>)}
                                </div>
                            </Col>
                        </Row> 
                    </div>
                    <div className="container"  > 
                        <Row> 
                            <Col xl={6} lg={12} md={12}>
                                <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel" >
                                    <Table striped bordered hover>
                                        <tbody>
                {/* ADMIN */}
                                        <tr data-toggle="collapse" data-target=".multi-collapse6" aria-controls="multiCollapseExample6" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                            <td colSpan="5" id="rmc_admin" style={{fontWeight:"900",color:"blue"}}><b>ADMIN</b></td>  
                                        </tr>  

                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td><input type="checkbox"  className="is_rmc_admin" id="is_rmc_admin" name="is_rmc_admin" checked={isChecked.is_rmc_admin || false} onChange={handleCheckboxes}  /> Modules </td> 
                                            <td> Create</td> 
                                            <td> View  </td>  
                                            <td> Edit  </td>  
                                            <td> Delete</td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Company Registration </td> 
                                            <td> <input type="checkbox" className="is_rmc_admin" style={{pointerEvents: "none"}}name="is_rmc_admin" value="is_rmc_admin"id="" /></td>
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}}name="is_rmc_admin" value="is_rmc_admin"id="" /></td>
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}}name="is_rmc_admin"value="is_rmc_admin" id="" /></td>
                                            <td> <input type="checkbox" className="is_rmc_admin"style={{pointerEvents: "none"}}name="is_rmc_admin"value="is_rmc_admin" id="" /></td>
                                        </tr> 
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Plant Registration </td> 
                                            <td> <input type="checkbox" className="is_rmc_admin"style={{pointerEvents: "none"}}name="Is_plant_add" value="Is_plant_add"id="" /></td>
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}}name="Is_plant_view" value="Is_plant_view"id="" /></td>
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}}name="Is_plant_edit"value="Is_plant_edit" id="" /></td>
                                            <td> <input type="checkbox" className="is_rmc_admin"style={{pointerEvents: "none"}}name="Is_plant_delete"value="Is_plant_delete" id="" /></td>
                                        </tr> 
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Plant Configuration </td> 
                                            <td> <input type="checkbox" className="is_rmc_admin"style={{pointerEvents: "none"}}name="Is_plant_configuration_add" value="Is_plant_configuration_add" id="" /></td>
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}}name="Is_plant_configuration_view" value="Is_plant_configuration_view" id="" /></td>
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}}name="Is_plant_configuration_edit"value="Is_plant_configuration_edit" id="" /></td>
                                            <td> <input type="checkbox" className="is_rmc_admin"style={{pointerEvents: "none"}}name="Is_plant_configuration_delete"value="Is_plant_configuration_delete" id="" /></td>
                                        </tr>  
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Number Setting</td>
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}} name="Is_numbersetting_add" value="Is_numbersetting_add"id="" /></td>   
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}} name="Is_numbersetting_view" value="Is_numbersetting_view" id="" /></td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"style={{pointerEvents: "none"}} name="Is_numbersetting_edit" value="Is_numbersetting_edit" id="" /></td>  
                                            <td> <input type="checkbox" className="is_rmc_admin" style={{pointerEvents: "none"}}name="Is_numbersetting_delete" value="Is_numbersetting_delete" id="" /></td> 
                                        </tr>  
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Approval Setting</td>
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}} name="Is_approvalsetting_add" value="Is_approvalsetting_add"id="" /></td>   
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}} name="Is_approvalsetting_view" value="Is_approvalsetting_view" id="" /></td>  
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}} name="Is_approvalsetting_edit" value="Is_approvalsetting_edit"id="" /></td>   
                                            <td> <input type="checkbox"className="is_rmc_admin" style={{pointerEvents: "none"}} name="Is_approvalsetting_delete" value="Is_approvalsetting_delete" id="" /></td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Unit</td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id=""style={{pointerEvents: "none"}} name="Is_unit_add" value="Is_unit_add" /></td> 
                                            <td> <input type="checkbox"  className="is_rmc_admin"id=""style={{pointerEvents: "none"}} name="Is_unit_view" value="Is_unit_view" /></td>  
                                            <td> <input type="checkbox" className="is_rmc_admin" id=""style={{pointerEvents: "none"}} name="Is_unit_edit" value="Is_unit_edit" /></td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id=""style={{pointerEvents: "none"}}name="Is_unit_delete" value="Is_unit_delete" /></td> 
                                        </tr> 
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Tax</td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_tax_add" value="Is_tax_add" /></td> 
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_tax_view" value="Is_tax_view" /></td>  
                                            <td> <input type="checkbox" className="is_rmc_admin" id="" style={{pointerEvents: "none"}}name="Is_tax_edit" value="Is_tax_edit" /></td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_tax_delete" value="Is_tax_delete" /></td> 
                                        </tr> 
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Product</td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_product_add" value="Is_product_add" /></td> 
                                            <td> <input type="checkbox"  className="is_rmc_admin"id=""style={{pointerEvents: "none"}} name="Is_product_view" value="Is_product_view" /></td>  
                                            <td> <input type="checkbox" className="is_rmc_admin" id="" style={{pointerEvents: "none"}}name="Is_product_edit" value="Is_product_edit" /></td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_product_delete" value="Is_product_delete" /></td> 
                                        </tr> 
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Sales Representative</td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_salesrep_add" value="Is_salesrep_add" /></td> 
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_salesrep_view" value="Is_salesrep_view" /></td>  
                                            <td> <input type="checkbox" className="is_rmc_admin" id="" style={{pointerEvents: "none"}}name="Is_salesrep_edit" value="Is_salesrep_edit" /></td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_salesrep_delete" value="Is_salesrep_delete" /></td> 
                                        </tr> 
                                        <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td> Gang</td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_gang_add" value="Is_gang_add" /></td> 
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_gang_view" value="Is_gang_view" /></td>  
                                            <td> <input type="checkbox" className="is_rmc_admin" id="" style={{pointerEvents: "none"}}name="Is_gang_edit" value="Is_gang_edit" /></td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_gang_delete" value="Is_gang_delete" /></td> 
                                        </tr>
                                        {/* <tr className="collapse multi-collapse6" id="multiCollapseExample6">  
                                            <td>IP Address</td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_ipaddress_add" value="True" /></td> 
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_ipaddress_view" value="True" /></td>  
                                            <td> <input type="checkbox" className="is_rmc_admin" id="" style={{pointerEvents: "none"}}name="Is_ipaddress_edit" value="True" /></td>  
                                            <td> <input type="checkbox"  className="is_rmc_admin"id="" style={{pointerEvents: "none"}}name="Is_ipaddress_delete" value="True" /></td> 
                                        </tr> */}
                {/* MARKETTING  */}
                                        <tr data-toggle="collapse" data-target=".multi-collapse1" aria-controls="multiCollapseExample1" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>
                                            <td colSpan="5" id="marketting" style={{fontWeight:"900",color:"blue" }}><b>MARKETING</b></td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse1" id="multiCollapseExample1">
                                            <td>  </td> 
                                            <td> Create</td> 
                                            <td> View  </td>  
                                            <td> Edit  </td>  
                                            <td> Delete</td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse1" id="multiCollapseExample1">  
                                            <td> Billing registration</td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="add_entity_customer_detail" checked={isChecked.add_entity_customer_detail || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="view_entity_customer_detail" checked={isChecked.view_entity_customer_detail || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="change_entity_customer_detail" checked={isChecked.change_entity_customer_detail || false} onChange={handleCheckboxes} /></td> 
                                            <td> <input type="checkbox" className="is_marketting" id="" name="delete_entity_customer_detail" checked={isChecked.delete_entity_customer_detail || false} onChange={handleCheckboxes} /></td> 
                                        </tr>  
                                        <tr className="collapse multi-collapse1" id="multiCollapseExample1">  
                                            <td> Site registration</td>  
                                            <td> <input type="checkbox"className="is_marketting"  id="" name="add_entity_consignee_detail" checked={isChecked.add_entity_consignee_detail || false} onChange={handleCheckboxes} /></td> 
                                            <td> <input type="checkbox" className="is_marketting" id="" name="view_entity_consignee_detail" checked={isChecked.view_entity_consignee_detail || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="change_entity_consignee_detail" checked={isChecked.change_entity_consignee_detail || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="delete_entity_consignee_detail" checked={isChecked.delete_entity_consignee_detail || false} onChange={handleCheckboxes} /></td> 
                                        </tr>
                                        <tr className="collapse multi-collapse1" id="multiCollapseExample1">  
                                            <td> Quotation</td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="add_quotation" checked={isChecked.add_quotation || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="view_quotation" checked={isChecked.view_quotation || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="edit_quotation" checked={isChecked.edit_quotation || false} onChange={handleCheckboxes} /></td> 
                                            <td> <input type="checkbox" className="is_marketting" id="" name="delete_quotation" checked={isChecked.delete_quotation || false} onChange={handleCheckboxes} /></td> 
                                        </tr>  
                                        <tr className="collapse multi-collapse1" id="multiCollapseExample1">  
                                            <td> Sales Order</td>  
                                            <td> <input type="checkbox"className="is_marketting"  id="" name="add_sales_order_master" checked={isChecked.add_sales_order_master || false} onChange={handleCheckboxes} /></td> 
                                            <td> <input type="checkbox" className="is_marketting" id="" name="view_sales_order_master" checked={isChecked.view_sales_order_master || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="change_sales_order_master" checked={isChecked.change_sales_order_master || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_marketting" id="" name="delete_sales_order_master" checked={isChecked.delete_sales_order_master || false} onChange={handleCheckboxes} /></td> 
                                        </tr>
                                        {/* <tr className="collapse multi-collapse1" id="multiCollapseExample1">  
                                            <td>Marketing Reports</td>  
                                            <td> <input type="checkbox"className="is_marketting"  id="" name="view_marketting_reports" checked={isChecked.view_marketting_reports || false} onChange={handleCheckboxes} /></td> 
                                            <td colSpan="3">  </td> 
                                        </tr> */}
                    {/* APPROVAL  */}
                                        {/* <tr data-toggle="collapse" data-target=".multi-collapse5" aria-controls="multiCollapseExample5" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                            <td colSpan="5" style={{fontWeight:"900",color:"blue" }}>  APPROVAL</td>  
                                        </tr> 
                                        <tr className="collapse multi-collapse5" id="multiCollapseExample5">  
                                            <td colSpan="5"><input type="checkbox"  id=""name="approve_so" checked={isChecked.approve_so || false} onChange={handleCheckboxes} />  Sales Order</td>  
                                        </tr>
                                        <tr className="collapse multi-collapse5" id="multiCollapseExample5">  
                                            <td colSpan="5"><input type="checkbox"  id="" name="approve_po" checked={isChecked.approve_po || false} onChange={handleCheckboxes} />  Purchase Order</td> 
                                        </tr>   */}
                    {/* PLANNING */}
                                        <tr data-toggle="collapse" data-target=".multi-collapse2" aria-controls="multiCollapseExample2" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                            <td colSpan="5" id="planning" style={{fontWeight:"900",color:"blue"}}>  PLANNING</td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse2" id="multiCollapseExample2">  
                                            <td></td>
                                            <td> Create</td>  
                                            <td> View</td>  
                                            <td>Edit </td>  
                                            <td> Delete</td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse2" id="multiCollapseExample2">  
                                            <td>  Work Schedule</td>  
                                            <td> <input type="checkbox" className="is_planning" id="" name="add_work_schedule_master" checked={isChecked.add_work_schedule_master || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox"  className="is_planning"id="" name="view_work_schedule_master" checked={isChecked.view_work_schedule_master || false} onChange={handleCheckboxes} /> </td>  
                                            <td> <input type="checkbox" className="is_planning" id="" name="change_work_schedule_master" checked={isChecked.change_work_schedule_master || false} onChange={handleCheckboxes} /> </td> 
                                            <td> <input type="checkbox" className="is_planning" id="" name="delete_work_schedule_master" checked={isChecked.delete_work_schedule_master || false} onChange={handleCheckboxes} /> </td>
                                        </tr> 
                                        <tr className="collapse multi-collapse2" id="multiCollapseExample2">  
                                            <td>Planning Reports</td>  
                                            <td> <input type="checkbox" className="is_planning" id="" name="view_planning_reports" checked={isChecked.view_planning_reports || false} onChange={handleCheckboxes} /></td>  
                                            <td colSpan="3">  </td> 
                                        </tr> 

                    {/* QUALITY CONTROL */}
                                        <tr data-toggle="collapse" data-target=".multi-collapse4" aria-controls="multiCollapseExample4" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                            <td colSpan="5" id="qty_ctrl" style={{fontWeight:"900",color:"blue"}}> QUALITY CONTROL</td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse4" id="multiCollapseExample4">  
                                            <td> </td>
                                            <td> Create</td>  
                                            <td> View</td>  
                                            <td> Edit </td>  
                                            <td> Delete</td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse4" id="multiCollapseExample4">  
                                            <td> Design Mix </td>  
                                            <td> <input type="checkbox" className="is_qty_ctrl" id="" name="add_design_mix_master" checked={isChecked.add_design_mix_master || false} onChange={handleCheckboxes}  /></td>  
                                            <td> <input type="checkbox" className="is_qty_ctrl" id="" name="view_design_mix_master"checked={isChecked.view_design_mix_master || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox" className="is_qty_ctrl" id="" name="change_design_mix_master" checked={isChecked.change_design_mix_master || false} onChange={handleCheckboxes} /></td> 
                                            <td> <input type="checkbox" className="is_qty_ctrl" id="" name="delete_design_mix_master" checked={isChecked.delete_design_mix_master || false} onChange={handleCheckboxes} /></td>
                                        </tr>  
                                        <tr className="collapse multi-collapse4" id="multiCollapseExample4">  
                                            <td>Moisture Correction</td>  
                                            <td><input type="checkbox" className="is_qty_ctrl" id="" name="add_moisture_correction_master" checked={isChecked.add_moisture_correction_master || false} onChange={handleCheckboxes} /></td>  
                                            <td><input type="checkbox" className="is_qty_ctrl" id="" name="view_moisture_correction_master" checked={isChecked.view_moisture_correction_master || false} onChange={handleCheckboxes} /></td>  
                                            <td><input type="checkbox" className="is_qty_ctrl" id="" name="change_moisture_correction_master" checked={isChecked.change_moisture_correction_master || false} onChange={handleCheckboxes} /></td> 
                                            <td><input type="checkbox" className="is_qty_ctrl" id="" name="delete_moisture_correction_master" checked={isChecked.delete_moisture_correction_master || false} onChange={handleCheckboxes} /></td>
                                        </tr>  
                                        {/* <tr className="collapse multi-collapse4" id="multiCollapseExample4">  
                                            <td>  Cube Test Report</td>  
                                            <td><input type="checkbox" className="is_qty_ctrl" id="" name="add_compressive_strength_master" checked={isChecked.add_compressive_strength_master || false} onChange={handleCheckboxes} /></td>  
                                            <td><input type="checkbox" className="is_qty_ctrl" id="" name="view_compressive_strength_master" checked={isChecked.view_compressive_strength_master || false} onChange={handleCheckboxes} /></td>  
                                            <td><input type="checkbox" className="is_qty_ctrl" id="" name="change_compressive_strength_master" checked={isChecked.change_compressive_strength_master || false} onChange={handleCheckboxes} /></td> 
                                            <td><input type="checkbox" className="is_qty_ctrl" id="" name="delete_compressive_strength_master" checked={isChecked.delete_compressive_strength_master || false} onChange={handleCheckboxes} /></td>
                                        </tr>  */}

                    {/* PURCHASE */}
                                        <tr data-toggle="collapse" data-target=".multi-collapse3" aria-controls="multiCollapseExample3" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                            <td colSpan="5" id="purchase" style={{fontWeight:"900",color:"blue"}}>  PURCHASE</td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse3" id="multiCollapseExample3">  
                                            <td> </td>
                                            <td> Create</td>  
                                            <td> View</td>  
                                            <td> Edit </td>  
                                            <td> Delete</td>  
                                        </tr>  
                                        <tr className="collapse multi-collapse3" id="multiCollapseExample3">  
                                            <td>  Vendor</td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="add_entity_vendor_detail" checked={isChecked.add_entity_vendor_detail || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="view_entity_vendor_detail" checked={isChecked.view_entity_vendor_detail || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="change_entity_vendor_detail" checked={isChecked.change_entity_vendor_detail || false} onChange={handleCheckboxes} /></td> 
                                            <td> <input type="checkbox"  className="is_purchase"id="" name="delete_entity_vendor_detail" checked={isChecked.delete_entity_vendor_detail || false} onChange={handleCheckboxes} /></td>
                                        </tr>  
                                        {/* <tr className="collapse multi-collapse3" id="multiCollapseExample3">  
                                            <td>  Purchase Order</td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="add_purchase_order" checked={isChecked.add_purchase_order || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="view_purchase_order" checked={isChecked.view_purchase_order || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="edit_purchase_order" checked={isChecked.edit_purchase_order || false} onChange={handleCheckboxes} /></td> 
                                            <td> <input type="checkbox"  className="is_purchase"id="" name="delete_purchase_order" checked={isChecked.delete_purchase_order || false} onChange={handleCheckboxes} /></td>
                                        </tr>  
                                        <tr className="collapse multi-collapse3" id="multiCollapseExample3">  
                                            <td>  Purchase Invoice</td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="add_purchase_invoice" checked={isChecked.add_purchase_invoice || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="view_purchase_invoice" checked={isChecked.view_purchase_invoice || false} onChange={handleCheckboxes} /></td>  
                                            <td> <input type="checkbox"  className="is_purchase" id="" name="edit_purchase_invoice" checked={isChecked.edit_purchase_invoice || false} onChange={handleCheckboxes} /></td> 
                                            <td> <input type="checkbox"  className="is_purchase"id="" name="delete_purchase_invoice" checked={isChecked.delete_purchase_invoice || false} onChange={handleCheckboxes} /></td>
                                        </tr> */}
                                        </tbody>  
                                    </Table>
                                </div>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel" >    
                                    <Table striped bordered hover>
                                        <tbody> 
                    {/* DISPATCH */}
                                            <tr data-toggle="collapse" data-target=".multi-collapse7" aria-controls="multiCollapseExample7" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                                <td colSpan="5" id="dispatch" style={{fontWeight:"900",color:"blue"}}>  DISPATCH</td>  
                                            </tr>  
                                            <tr className="collapse multi-collapse7" id="multiCollapseExample7">  
                                                <td></td>
                                                <td> Create</td>  
                                                <td> View</td>  
                                                <td> Edit </td>  
                                                <td> Delete</td>  
                                            </tr>  
                                            <tr className="collapse multi-collapse7" id="multiCollapseExample7">  
                                                <td>  Pump Clearance</td>  
                                                <td> <input type="checkbox" className="is_dispatch" name="add_pump_clearance"id=""  checked={isChecked.add_pump_clearance || false} onChange={handleCheckboxes} /></td>  
                                                <td> <input type="checkbox" className="is_dispatch" name="view_pump_clearance" id="" checked={isChecked.view_pump_clearance || false} onChange={handleCheckboxes}  /></td>  
                                                <td> <input type="checkbox" className="is_dispatch" name="edit_pump_clearance" id="" checked={isChecked.edit_pump_clearance || false} onChange={handleCheckboxes} /></td> 
                                                <td> <input type="checkbox" className="is_dispatch" name="delete_pump_clearance" id=""  checked={isChecked.delete_pump_clearance || false} onChange={handleCheckboxes} /></td>
                                            </tr>  
                                            <tr className="collapse multi-collapse7" id="multiCollapseExample7">  
                                                <td> Delivery Challan</td>  
                                                <td> <input type="checkbox" className="is_dispatch" name="add_del_challan_master"id="" checked={isChecked.add_del_challan_master || false} onChange={handleCheckboxes} /></td>  
                                                <td> <input type="checkbox" className="is_dispatch" name="view_del_challan_master" id="" checked={isChecked.view_del_challan_master || false} onChange={handleCheckboxes} /></td>  
                                                <td> <input type="checkbox" className="is_dispatch" name="change_del_challan_master" id=""checked={isChecked.change_del_challan_master || false} onChange={handleCheckboxes} /></td> 
                                                <td> <input type="checkbox" className="is_dispatch" name="delete_del_challan_master" id="" checked={isChecked.delete_del_challan_master || false} onChange={handleCheckboxes} /></td> 
                                            </tr> 
                                            <tr className="collapse multi-collapse7" id="multiCollapseExample7">  
                                                <td> Dispatch Reports</td>  
                                                <td> <input type="checkbox" className="is_dispatch" name="view_dispatch_reports"id="" checked={isChecked.view_dispatch_reports || false} onChange={handleCheckboxes} /></td>  
                                                <td colSpan="3">  </td> 
                                            </tr> 

                        {/* ACCOUNTS  */}
                                            <tr data-toggle="collapse" data-target=".multi-collapse8" aria-controls="multiCollapseExample8" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                                <td colSpan="5" id="accounts" style={{fontWeight:"900",color:"blue"}}>  ACCOUNTS</td>  
                                            </tr>  
                                            <tr className="collapse multi-collapse8" id="multiCollapseExample8">  
                                                <td></td>
                                                <td> Create</td>  
                                                <td> View</td>  
                                                <td> Edit </td>  
                                                <td> Delete</td>  
                                            </tr> 
                                            <tr className="collapse multi-collapse8" id="multiCollapseExample8">  
                                                <td>Group Form</td>  
                                                <td> <input type="checkbox" className="is_accounts" name="add_grouping"  checked={isChecked.add_grouping || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="view_grouping"  checked={isChecked.view_grouping || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="edit_grouping"  checked={isChecked.edit_grouping || false} onChange={handleCheckboxes} id="" /></td> 
                                                <td> <input type="checkbox" className="is_accounts" name="delete_grouping" checked={isChecked.delete_grouping || false} onChange={handleCheckboxes}  id="" /></td>
                                            </tr>
                                            {/* <tr className="collapse multi-collapse8" id="multiCollapseExample8">  
                                                <td> General Ledger</td>  
                                                <td> <input type="checkbox" className="is_accounts" name="add_ledger"  checked={isChecked.add_ledger || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="view_ledger"  checked={isChecked.view_ledger || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="edit_ledger"  checked={isChecked.edit_ledger || false} onChange={handleCheckboxes} id="" /></td> 
                                                <td> <input type="checkbox" className="is_accounts" name="delete_ledger" checked={isChecked.delete_ledger || false} onChange={handleCheckboxes}  id="" /></td>
                                            </tr>  */}
                                            <tr className="collapse multi-collapse8" id="multiCollapseExample8">  
                                                <td> Bank</td>  
                                                <td> <input type="checkbox" className="is_accounts" name="add_entity_bank_detail"  checked={isChecked.add_entity_bank_detail || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="view_entity_bank_detail"  checked={isChecked.view_entity_bank_detail || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="change_entity_bank_detail"  checked={isChecked.change_entity_bank_detail || false} onChange={handleCheckboxes} id="" /></td> 
                                                <td> <input type="checkbox" className="is_accounts" name="delete_entity_bank_detail" checked={isChecked.delete_entity_bank_detail || false} onChange={handleCheckboxes}  id="" /></td>
                                            </tr> 
                                            {/* <tr className="collapse multi-collapse8" id="multiCollapseExample8">  
                                                <td> Credit Note</td>  
                                                <td> <input type="checkbox" className="is_accounts" name="add_journal_master"  checked={isChecked.add_journal_master || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="view_journal_master"  checked={isChecked.view_journal_master || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="change_journal_master"  checked={isChecked.change_journal_master || false} onChange={handleCheckboxes} id="" /></td> 
                                                <td> <input type="checkbox" className="is_accounts" name="delete_journal_master" checked={isChecked.delete_journal_master || false} onChange={handleCheckboxes}  id="" /></td>
                                            </tr>
                                            <tr className="collapse multi-collapse8" id="multiCollapseExample8">  
                                                <td> Invoice</td>  
                                                <td> <input type="checkbox" className="is_accounts" name="add_sales_invoice"  checked={isChecked.add_sales_invoice || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="view_sales_invoice"  checked={isChecked.view_sales_invoice || false} onChange={handleCheckboxes} id="" /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="edit_sales_invoice"  checked={isChecked.edit_sales_invoice || false} onChange={handleCheckboxes} id="" /></td> 
                                                <td> <input type="checkbox" className="is_accounts" name="delete_sales_invoice" checked={isChecked.delete_sales_invoice || false} onChange={handleCheckboxes}  id="" /></td>
                                            </tr>
                                            <tr className="collapse multi-collapse8" id="multiCollapseExample8">  
                                                <td>  Receipt Voucher</td>  
                                                <td> <input type="checkbox" className="is_accounts" name="add_receipt_master" id=""checked={isChecked.add_receipt_master || false} onChange={handleCheckboxes}  /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="view_receipt_master"  id="" checked={isChecked.view_receipt_master || false} onChange={handleCheckboxes}  /></td>  
                                                <td> <input type="checkbox" className="is_accounts" name="change_receipt_master"  id="" checked={isChecked.change_receipt_master || false} onChange={handleCheckboxes}  /></td> 
                                                <td> <input type="checkbox" className="is_accounts" name="delete_receipt_master"  id=""checked={isChecked.delete_receipt_master || false} onChange={handleCheckboxes} /></td>
                                            </tr> */}
                                            {/* <tr className="collapse multi-collapse8" id="multiCollapseExample8">  
                                                <td>Accounts Reports</td>  
                                                <td> <input type="checkbox" className="is_accounts" name="view_accounts_reports" id=""checked={isChecked.view_accounts_reports || false} onChange={handleCheckboxes}  /></td>  
                                                <td colSpan="3">  </td> 
                                            </tr> */}
                        {/*  STORE */}
                                            {/* <tr data-toggle="collapse" data-target=".multi-collapse9" aria-controls="multiCollapseExample9" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                                <td colSpan="5" id="store" style={{fontWeight:"900",color:"blue"}}>  STORE</td>  
                                            </tr>  
                                            <tr className="collapse multi-collapse9" id="multiCollapseExample9">  
                                                <td> </td>
                                                <td> Create</td>  
                                                <td> View</td>  
                                                <td> Edit </td>  
                                                <td> Delete</td>  
                                            </tr>  
                                            <tr className="collapse multi-collapse9" id="multiCollapseExample9">  
                                                <td>  Goods Receipt Note</td>  
                                                <td> <input type="checkbox" className="is_store" name="add_grn" id="" checked={isChecked.add_grn || false} onChange={handleCheckboxes}  /></td>  
                                                <td> <input type="checkbox" className="is_store" name="view_grn" id="" checked={isChecked.view_grn || false} onChange={handleCheckboxes}  /></td>  
                                                <td> <input type="checkbox" className="is_store" name="change_grn" id="" checked={isChecked.change_grn || false} onChange={handleCheckboxes}  /></td> 
                                                <td> <input type="checkbox" className="is_store" name="delete_grn" id="" checked={isChecked.delete_grn || false} onChange={handleCheckboxes}  /></td>
                                            </tr>  
                                            <tr className="collapse multi-collapse9" id="multiCollapseExample9">  
                                                <td> Store Reports</td>  
                                                <td> <input type="checkbox" className="is_store" name="view_store_reports" id="" checked={isChecked.view_store_reports || false} onChange={handleCheckboxes}  /></td>  
                                                <td colSpan="3">  </td> 
                                            </tr>  */}
                        {/*  GATE  */}
                                            <tr data-toggle="collapse" data-target=".multi-collapse10" aria-controls="multiCollapseExample10" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                                <td colSpan="5" id="gate" style={{fontWeight:"900",color:"blue"}}>  GATE</td>  
                                            </tr>  
                                            <tr className="collapse multi-collapse10" id="multiCollapseExample10">  
                                                <td> </td>
                                                <td> Create</td>  
                                                <td> View</td>  
                                                <td> Edit </td>  
                                                <td> Delete</td>  
                                            </tr>  
                                            <tr className="collapse multi-collapse10" id="multiCollapseExample10">  
                                                <td>  CheckIn,CheckOut,GatePass </td>  
                                                <td> <input type="checkbox" className="is_gate" name="add_gate_pass_master" id="" checked={isChecked.add_gate_pass_master || false} onChange={handleCheckboxes}  /></td>  
                                                <td> <input type="checkbox" className="is_gate" name="view_gate_pass_master" id=""checked={isChecked.view_gate_pass_master || false} onChange={handleCheckboxes}  /></td>  
                                                <td> <input type="checkbox" className="is_gate" name="change_gate_pass_master" id="" checked={isChecked.change_gate_pass_master || false} onChange={handleCheckboxes}  /></td> 
                                                <td> <input type="checkbox" className="is_gate" name="delete_gate_pass_master" id="" checked={isChecked.delete_gate_pass_master || false} onChange={handleCheckboxes}  /></td>
                                            </tr>  
                                            {/* <tr className="collapse multi-collapse10" id="multiCollapseExample10">  
                                                <td>  Gate Reports </td>  
                                                <td> <input type="checkbox" className="is_gate" name="view_gate_reports" id="" checked={isChecked.view_gate_reports || false} onChange={handleCheckboxes}  /></td>  
                                                <td colSpan="3"></td>
                                            </tr> */}
                                            
                        {/* TRANSPORTS  */}
                                            <tr data-toggle="collapse" data-target=".multi-collapse11" aria-controls="multiCollapseExample11" title="Click to Expand/Collapse" style={{cursor: "pointer"}}>  
                                                <td colSpan="5" style={{fontWeight:"900",color:"blue"}} id="transport"> TRANSPORTS</td> 
                                            </tr>  
                                            <tr className="collapse multi-collapse11" id="multiCollapseExample11">  
                                                <td></td>
                                                <td>Create</td>  
                                                <td> View</td>  
                                                <td>Edit </td>  
                                                <td> Delete</td>  
                                            </tr>  
                                            <tr className="collapse multi-collapse11" id="multiCollapseExample11">  
                                                <td> Equipments</td>  
                                                <td><input type="checkbox" className="is_transport" name="add_equipment_master" id="" checked={isChecked.add_equipment_master || false} onChange={handleCheckboxes}  /></td>  
                                                <td><input type="checkbox" className="is_transport" name="view_equipment_master" id="" checked={isChecked.view_equipment_master || false} onChange={handleCheckboxes}  /></td>  
                                                <td><input type="checkbox" className="is_transport" name="change_equipment_master" id="" checked={isChecked.change_equipment_master || false} onChange={handleCheckboxes}  /></td> 
                                                <td><input type="checkbox" className="is_transport" name="delete_equipment_master" id="" checked={isChecked.delete_equipment_master || false} onChange={handleCheckboxes}  /></td>
                                            </tr>
                                            <tr style={{visibility:"hidden"}}>
                                                <td colSpan="5"></td>
                                            </tr>
                                           <br/> 
                                           {/* <tr className="collapse multi-collapse11" id="multiCollapseExample11">  
                                                <td>Equipment Reports</td>  
                                                <td><input type="checkbox" className="is_transport" name="view_transport_reports" id="" checked={isChecked.view_transport_reports || false} onChange={handleCheckboxes}  /></td>  
                                                <td colSpan="3">  </td> 
                                            </tr> */}
                                        </tbody> 
                                        
                                    </Table>
                                </div>
                            </Col>
                        </Row> 
                    </div>
                    <div className="footer text-center"><br/>
                        <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter">Save</Button> &nbsp;&nbsp;
                        <Button type="button"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}}className="btn btn-twitter" >Reset</Button> &nbsp;&nbsp;
                        <Button   type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={view}>View</Button>&nbsp;&nbsp;
                        <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={Back}>Home</Button>
                    </div>
                </form> 
            </div><br/>
        {/* </div>  */}
    </>
);
}
export default Permission;
