import { getApiBaseUrl, getApiClient } from "./serviceconfig";
var apiBaseUrl = getApiBaseUrl();

export async function getInvoicenumber(cookies, plant_id, invoice_date) {
    let url = `${apiBaseUrl}/sales_invoices/invoice_number/?plant_id=${plant_id}&invoice_date=${invoice_date}`;
    return getApiClient(cookies).get(url)
        .then(response => response.data);
} 

export async function createSalesInvoice(cookies, inputs) {
    return getApiClient(cookies)
    .post(`${apiBaseUrl}/sales_invoices/`, inputs)
    .then(response => response.data)
    }

export async function printSalesInvoice(cookies, invoiceId) {
    return getApiClient(cookies).get(`${apiBaseUrl}/sales_invoices/${invoiceId}/print_invoice/`)
    .then(response => response.data)
    }

export async function getAllInvoices(cookies,inputs) {
    let url= `${apiBaseUrl}/sales_invoices/?plant_id=${inputs.plant_id}&customer_ids=${inputs.customer_ids}&from_date=${inputs.from_date}&upto_date=${inputs.upto_date}`;
        return getApiClient(cookies).get(url)
        .then(response => response.data);
    }

export async function getInvoiceDetails(cookies, invoiceId) {
    return getApiClient(cookies).get(`${apiBaseUrl}/sales_invoices/${invoiceId}/`)
                .then(response => response.data)
    }

export async function updateSalesInvoice(cookies, inputs,  invoiceId) {
    return getApiClient(cookies)
    .put(`${apiBaseUrl}/sales_invoices/${invoiceId}/`,inputs)
    .then(response => response.data)
    }

export function deleteSalesInvoice(cookies, invoiceId) {
    return getApiClient(cookies).delete(`${apiBaseUrl}/sales_invoices/${invoiceId}/`)
    }

export function cancelSalesInvoice(cookies, invoiceId) {
    return getApiClient(cookies).patch(`${apiBaseUrl}/sales_invoices/${invoiceId}/`)
    }