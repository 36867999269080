import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllTax, deleteTax } from "../../services/TaxServices";
import { displayError } from "../../helpers";
function TaxTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'name', 
                headerName: 'Name'
            },
           
    
            {
                fieldName: "cgst",
                headerName: 'Central GST'
            },
            {
                fieldName: "sgst",
                headerName: "State GST"
            },
            {
                fieldName: "igst",
                headerName: "integrated"
            },
            {
                fieldName: "tcs",
                headerName: "TCS"
            },
            {
                fieldName: "tds",
                headerName: "TDS"
            },
            {
                fieldName: "valid_from",
                headerName: "Valid From Date"
            },
            {
                fieldName: "valid_upto",
                headerName: "Valid To"
            },
            {
                fieldName: "user_remarks",
                headerName: "Remarks"
            },
            {
                fieldName: "status",
                headerName: "Status"
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
       
         
        ],
        [],);

    const onEditTax = (taxId, event) => {
       
       navigate(`/TaxEdit/${taxId}/`);
    };

    const onDeleteTax = (taxId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
            if (result.isConfirmed) { 

                setIsLoading(true);
                deleteTax(cookies, taxId)
                .then(response => {
                    console.log(`Tax with id ${taxId} deleted`);
                    setRefreshKey(oldKey => oldKey +1);
                    Swal.fire('Deleted Successfully!', '', 'success');
                })
                .catch(error => {
                    console.log(error.response.data);
                    displayError(error.response.data,'Delete Failed');
                }); 
                
                
              } else if (result.isDismissed) {   
                
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    name: r.name,
                    cgst:((r.tax_type === 'gst')?  r.tax_value1: 0),
                    sgst:((r.tax_type === 'gst')?  r.tax_value2: 0),
                    igst:((r.tax_type === 'igst')?  r.tax_value1: 0),
                    tcs:((r.tax_type === 'tcs')?  r.tax_value1: 0),
                    tds:((r.tax_type === 'tds')?  r.tax_value1: 0),
                    valid_from:r.valid_from,
                    valid_upto:r.valid_upto,
                    user_remarks:r.user_remarks,
                    status:r.status ? "active":"inactive" ,
                    //status:(r.is_active ? "Active" : "Inactive"),
                    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Tax Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                name: r.name,
                    cgst:((r.tax_type === 'gst')?  r.tax_value1: 0),
                    sgst:((r.tax_type === 'gst')?  r.tax_value2: 0),
                    igst:((r.tax_type === 'igst')?  r.tax_value1: 0),
                    tcs:((r.tax_type === 'tcs')?  r.tax_value1: 0),
                    tds:((r.tax_type === 'tds')?  r.tax_value1: 0),
                    valid_from:r.valid_from,
                    valid_upto:r.valid_upto,
                    user_remarks:r.user_remarks,
                    status:r.status ? "active":"inactive" ,
            })),
            'Tax_Forms.pdf'
        );
    };

    useEffect(() => {

        getAllTax(cookies)
        .then( tax => {
            console.log(JSON.stringify(tax)+"data2")
            const tableData = tax.tax_list
            .map(r => ({
                id :r.id,
                name : r.name,
                cgst:((r.tax_type === 'gst')?  r.tax_value1: 0),
                sgst:((r.tax_type === 'gst')?  r.tax_value2: 0),
                igst:((r.tax_type === 'igst')?  r.tax_value1: 0),
                tcs:((r.tax_type === 'tcs')?  r.tax_value1: 0),
                tds:((r.tax_type === 'tds')?  r.tax_value1: 0),
                valid_from:r.valid_from,
                valid_upto:r.valid_upto,
                user_remarks:r.user_remarks,
                status:r.status ? "active":"inactive",
                hide_row_edit:r.system_field?"Yes":"No",
                hide_row_delete:r.system_field?"Yes":"No"
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Tax Form</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Group Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditTax}
                            onRowDelete={onDeleteTax}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/Tax")}}>Back</button>
        {/* </div> */}
        </>
        
    );

}



export default TaxTable;