import React from 'react';
import { useState,useEffect } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
//import TableRows from "../../TableRows";
import { Link,useParams } from 'react-router-dom';
import '../../button.css';
import TableRowsIPAddress from './TableRowsIPAddress';
import {displayErrorToast,displayError} from '../../helpers';
import LoadingOverlay from '../../components/LoadingOverlay';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import { updateIPAddress,getIPAddressDetails,getAllIPAddress,deleteIPAddress } from '../../services/IPAddressServices';
//import TableRowsIPAddressEdit from './TableRowsIPAddressEdit';
function IpAddressEdit() {
  const [cookies] = useCookies(['myToken']);
  const [inProgress, setInProgress] = useState(false);
  const {ipaddressId}=useParams()
  const [ipFormData, setIpFormData] = useState({});
  const [inputs, setInputs] = useState({
    ip_addr: '',
    user_remarks: '',
    status: ''
});

const navigate = useNavigate();
const [toDeleteIPIds,settoDelete] = useState([]);
const handle_IPcategoryField = (event) => {   
  const name = event.target.name;
  const value = event.target.value;
  let ip = document.getElementById("myTable");
  
  setInputs(values => ({...values, [name]: value}))
 
  // getAllIPAddress(cookies).then(ipaddress =>{
     
  //   const iplength = ipaddress.ip_list.length
  //   if(iplength !=0)
  //       {
  //         console.log(iplength+"iplength");
  //         // Swal.fire("Already created entry ..If you want to change, please edit..", "",'info')
  //       }
    // else{
        if(value === "all"){
          ip.style.display="none";
        }else{
        //  if (value ==="selected") {
            ip.style.display ="";
        //  }
           
      // }
       
      }
    // }) 
 
}

  const [rowsData, setRowsData] = useState([]);
  
  const addTableRows = () => {
    const count=rowsData.length +1;
    const rowsInput={
      id:'',
      slNo:count,
      ip_addr: '',
      user_remarks: '',
      status: '1'
        }
        setRowsData([...rowsData, rowsInput])
  }
  
  const deleteTableRows = (index)=>{
    const rows = [...rowsData];
    console.log((rows[index].id)+"rowsData.id");
    if((rows[index].id))
    {
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => { 
            if (result.isConfirmed)
            {
                settoDelete([...toDeleteIPIds, rows[index].id])
                // deleteIPAddress(cookies, parseInt(rows[index].id))
                rows.splice(index, 1);
                for(var i=0; i<rows.length; i++) 
                {
                    rows[i]['slNo']= i+1
                }
                setRowsData(rows)
            } 
        });
    }
    else
    {
        rows.splice(index, 1);
        for(var i=0; i<rows.length; i++) 
        {
            rows[i]['slNo']= i+1
        }
        setRowsData(rows)
    }
}

   const {id}=useParams()
 
   React.useEffect(() => {
    if (id) {
      setInProgress(true);
      getIPAddressDetails(cookies, id)
        .then(response => {
          setInProgress(false);
          setInputs({
            ip_addr_category : response.ip_list[0].ip_addr_category
            });
          setRowsData(response.ip_list.map((ipentry,index)=>({...ipentry,slNo: index+1})));
          if(response.ip_list[0].ip_addr_category === "selected" ){
            document.getElementById("myTable").style.display="";
          }
        })
        .catch(error => {
          setInProgress(false);
          displayError(error.response.data, "Loading Failed");
        });
      }
    }, []);
 
  const handleChange = (index, evnt)=>{
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
  }

  const handleSubmit=(event)=> {  
    event.preventDefault();
    try {
    console.log(JSON.stringify(rowsData)+"rowsData");
          Swal.fire({title: 'Do you want to update?',  
          showCancelButton: true,  
          confirmButtonText: `Yes`,  
          cancelButtonText: `No`,
          }).then((result) => {  
            if (result.isConfirmed) { 
              updateIPAddress(
                cookies, 
                {
                  ip_addr_category:inputs.ip_addr_category,
                  ip_list:rowsData.map(ipentry=>(
                    { id: ipentry.id,
                      ip_addr:ipentry.ip_addr?(ipentry.ip_addr).trim():ipentry.ip_addr,
                      user_remarks:ipentry.user_remarks,
                      status:parseInt(ipentry.status)}
                      //status:ipentry.status}
                  )),
                  to_delete_ip_ids_list:toDeleteIPIds
                }, 
                id)
                .then((response) =>
                {
                 
                  Swal.fire('Updated!', '', 'success') 
                   view();
                })
                .catch((error) =>
                {
                console.log(error.response.data);
                displayError(error.response.data,"Update Failed");
              });
              
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not saved', '', 'info')  
            }
          });
        
    
  }
  catch(e)
  {
    displayErrorToast(e);
  }
}  
      const Reload = () => {
        window.location.reload();
      }      
      
      const view = () => {
        navigate('/IpAddressTable')
      }        
      
      const Back = () => {
        navigate('/Home')
      }

      const Cancel = () => {
         setInputs(() => "");
        const rowsInput={
          slNo:'',
          ip_addr: '',
          user_remarks: '',
          status: ''
        }
        setRowsData([rowsInput]);
      }

  return (
    <>
    {/* <div id="outer-container"  className="App" >  */}
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
            <form className="form" onSubmit={handleSubmit}>
                <div id="header">
                    <h3 className = "text font-weight-bold page-title" >IP ADDRESS PERMISSION--EDIT FORM</h3>
                </div>

                <FloatingControls tableLink="/IPAddressTable"  enableCancel={false}/>
          <div className="container-fluid">
          <Row>
          <Col xl={4} lg={12} md={12}>
                      </Col>
              <Col xl={4} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                        <label htmlFor="ip_addr_category"className="form-group col-sm-4 text-right ">IP address permission</label>
                        <select  id="ip_addr_category" required name="ip_addr_category" onChange={handle_IPcategoryField} value={inputs.ip_addr_category || ""}className="form-control  col-sm-7 browser-default custom-select">
                        <option value=""></option>
                        <option value="all">All</option>
                        <option value="selected">Selected</option>
                        </select><br/>

                  </div>
                  </Col>
          </Row> 
          {/*  */}
          <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)",borderColor:"white",display:"none"}}>
                    <thead>
                        <tr>
                            <th><button type="button"className="btn btn-success" onClick={addTableRows}>+</button></th>
                            <th colSpan="1" >S.No.</th>
                            <th colSpan="1">IP Address</th>
                            <th colSpan="1">Remarks</th>
                           <th colSpan="1">Status</th>
                        </tr>
                    </thead>
                    <tbody >
                      <TableRowsIPAddress rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} /> 
                    </tbody>
                </table>
            </div>    
          </div>
          <div className="footer text-center"><br/>
                    <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
              </form> 
          </div><br/>
          {/* </div>  */}
      </>
  );
}

export default IpAddressEdit;
