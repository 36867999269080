import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllProducts, deleteProducts } from "../../services/ProductServices";
function ProductTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'category_name', 
                headerName: 'Product Group'
            },
            {
                fieldName: "name",
                headerName: 'Product Name'
            },
            {
                fieldName: "unit_name",
                headerName: "Unit"
            },
            {
                fieldName: 'hsn_sac_code', 
                headerName: 'HSN/SAC Code'
            },
            {
                fieldName: 'prod_gst_type', 
                headerName: 'GST Type'
            },
            {
                fieldName: 'tax_code', 
                headerName: 'Tax Code'
            },
            {
                fieldName: "user_remarks",
                headerName: "Remarks"
            },
            {
                fieldName: "status",
                headerName: "Status"
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
            // {
            //     fieldName: 'vendor_part_code', 
            //     headerName: 'VendorPart Code'
            // },
            // {
            //     fieldName: 'hsn_sac_description', 
            //     headerName: 'HSN/SAC Description'
            // },
            // {
            //     fieldName: "quantity",
            //     headerName: "Default Qty"
            // }
        ],
        [],);

    const onEditProduct = (productId, event) => {
       
       navigate(`/ProductEdit/${productId}/`);
    };

    const onDeleteProduct = (productId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deleteProducts(cookies, productId); 
            console.log(`GroupForm with id ${productId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {            
                    category_name: r.category_name,
                    name: r.name,
                    unit_name:r. unit_name,
                    quantity:r.quantity,
                    vendor_part_code:r.vendor_part_code,
                    hsn_sac_code:r.hsn_sac_code,
                    hsn_sac_description:r.hsn_sac_description,
                    prod_gst_type:r.prod_gst_type,
                    tax_code:r.tax_code,
                    user_remarks:r.user_remarks,
                    status:r.status ? "active":"inactive" ,      
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Product Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                    
                category_name: r.category_name,
                name: r.name,
                unit_name:r.unit_name,
                quantity:r.quantity,
                vendor_part_code:r.vendor_part_code,
                hsn_sac_code:r.hsn_sac_code,
                hsn_sac_description:r.hsn_sac_description,
                prod_gst_type:r.prod_gst_type,
                tax_code:r.tax_code,
                user_remarks:r.user_remarks,
                status:r.status ? "active":"inactive" ,
            })),
            'Product_Forms.pdf'
        );
    };

    useEffect(() => {

        getAllProducts(cookies)
        .then( groupsList => {
            const tableData = groupsList.product_list
            .map(r => ({
                id :r.id,
                category_name: r.category.name,
                name: r.name,
                vendor_part_code:r.vendor_part_code,
                hsn_sac_code:r.hsn_sac_code,
                hsn_sac_description:r.hsn_sac_description,
                prod_gst_type:r.prod_gst_type,
                tax_code:r.tax.name,
                 unit_name:r.unit.name,
                quantity:r.quantity,
                user_remarks:r.user_remarks,
                status:r.status ? "active":"inactive" ,
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Product</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Group Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditProduct}
                            onRowDelete={onDeleteProduct}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/Product")}}>Back</button>
        {/* </div> */}
</>
        
    );

}



export default ProductTable;