import React from 'react';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { moment } from 'moment';
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast,displayError, parseBoolean} from '../../helpers';
import { createBank } from '../../services/BankService';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import StatusDropDown from '../../components/StatusDropDown';
import { getIndianStates } from '../../utils/PicklistProvider';
import { checkForDuplicateBank } from './BankUtils';
function Bank() {
    const location = useLocation(); 
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState({});
    const [inputs, setInputs] = useState({});
    const [plant, setPlant] = React.useState([]);
    const [inProgress, setInProgress] = useState(false);
    const [Appr_so_dtl, setAppr_so_dtl] = React.useState([]);
    const navigate = useNavigate();
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      try{   
        if(name === 'name')
        {
        if(!value.trim())                //Unit Name must not be empty,otherwise showing error message 
          {
            setError(values => ({...values, [name]: "Bank Name must not be empty..."}))
            setIsSubmitting(false); 
          } else {
            setError(values => ({...values, [name]: ''}))
            setIsSubmitting(true); 
          }
        }
          setInputs(values => ({...values, [name]: value}))
      }
       catch(e)
       {
        displayErrorToast(e);
       } 
    }
    const validate_items = (name,value,regular_expr,error_message) =>
    {
       try{
         
            if(value.trim())
            {
                if(!regular_expr.test(value.trim()))
                {
                    setError(values => ({...values, [name]: error_message}))
                    setIsSubmitting(false); 
                }
                else
                {
                    setError(values => ({...values, [name]: ''}))
                    setIsSubmitting(true);
                }
            }
            else
            {
                setError(values => ({...values, [name]: 'must not be empty...'}))
                setIsSubmitting(false);
            }
            setInputs(values => ({...values, [name]: value}))
        }
     
       catch (e)
          {
           displayErrorToast(e);
          }
     }  
    
  //   const handleChange4 = (index, evnt)=>{
  //     const { name, value } = evnt.target;
  //     const rowsInput = [...rowsData];
  //     rowsInput[index][name] = value;
  
  //     setRowsData(rowsInput);
  // }
    const [rowsData, setRowsData] = useState([]);
    const handleSubmit=(event)=> {
      event.preventDefault();
  
      checkForDuplicateBank(event.target.name.value, cookies, null)
          .then(isNameExists => {
  
            if (isNameExists) {
              setError(values => ({...values, [event.target.name.name]: 'Bank name already exists.'}))
            } else {
              setError({});
              try {   
                if ((Object.values(error).every(x => !x)) && isSubmitting) {  //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
                        Swal.fire({title: 'Do you want to save?',  
                        showCancelButton: true,  
                        confirmButtonText: `Yes`,  
                        cancelButtonText: `No`,
                        }).then((result) => {  
                          if (result.isConfirmed) { 
                            createBank(cookies,{
                              
                                name: (inputs.name).toUpperCase(),
                                bank_alias: inputs.bank_alias,
                                account_no:inputs.account_no,
                                address_1:inputs.address_1,
                                address_2:inputs.address_2,
                                address_3:inputs.address_3,
                                pincode: inputs.pincode,
                                state:inputs.state,
                                micr:inputs.micr,
                                opening_balance:parseFloat(inputs.opening_balance),
                                closing_balance:parseFloat(inputs.closing_balance),
                                ifsc: inputs.ifsc,
                                email_id:inputs.email_id,
                                phone_number:inputs.phone_number,
                                customer_id:inputs.customer_id,
                                account_type:inputs.account_type,
                                contact_person:inputs.contact_person,
                                contact_designation:inputs.contact_designation,
                                contact_mobile_no: inputs.contact_mobile_no,
                                user_remarks:inputs.user_remarks,
                                status:parseBoolean(inputs.status)
                            })
                            
                            Swal.fire('Saved!', '', 'success') 
                            // Reload();
                            Cancel();
                          } 
                          else if (result.isDismissed) 
                          {    
                            Swal.fire('Not saved', '', 'info')  
                          }
                        });
                }
                else
                {
                  Swal.fire('Please check * marked fields are filled and details given are valid...', '', 'info')   
                }
              }
              catch(e)
              {
                displayErrorToast(e);
              }
            }
          });
        }
  
    const Cancel = () => {
        setInputs(() => "")
        setRowsData([])
      }
      const view = () => {
        navigate('/BankTable')
      } 
    
      const Back = () => {
        navigate('/Home')
      }

return (
        <>
            {/* <div id="outer-container"  className="App" >  */}
         <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
         <ErrorToast/>
         <LoadingOverlay inProgress={inProgress}/>
         <div id="page-wrap">
         <form onSubmit={handleSubmit} > 
         <div id="header">
              <h3  className = "text font-weight-bold page-title" >CASH / BANK REGISTRATION FORM </h3>
              </div>
              <FloatingControls tableLink="/BankTable" onCancel={Cancel} enableCancel={true}/>

              <div className="container p-2 "> 
          
              </div>
              <div className="container">

              <Row>
                <Col xl={6} lg={12} md={12}>
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel  " >
                <h5 className='col-sm-11'><b>Bank Details</b></h5><br/><br/>
                <label htmlFor="name" className="form-group col-sm-5 text-right">Bank Name</label>
                    <input type="text" className="form-control col-sm-6" name="name" placeholder="Please fill the NAME field *"  onChange={handleChange}  id="Bank_Name" value={inputs.name || ""} style={{ backgroundColor: "white" }} /><br /><br/>
                    {error.name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.name}</strong><br/></>)}

                    <label htmlFor="bank_alias" className="form-group col-sm-5 text-right">Alias Name</label>
                    <input type="text" className="form-control col-sm-6 mandatory-form-control" name="bank_alias" onChange={handleChange} id="bank_alias" value={inputs.bank_alias || ""} style={{ backgroundColor: "white" }} /><br /><br/>

                    
                    <label htmlFor="account_no" className="form-group col-sm-5 text-right">Account No.</label>
                    <input type="text" className="form-control col-sm-6"  onChange={handleChange} name="account_no"  id="account_no" value={inputs.account_no || ""} style={{ backgroundColor: "white" }} /><br /><br/>

                    
                    <label htmlFor="address_1" className="form-group col-sm-5 text-right">Address</label>
                    <input type="text" className="form-control col-sm-6" name="address_1" onChange={handleChange}  placeholder="Letters, Nos.& Special Characters"  id="address_1" value={inputs.address_1 || ""} style={{ backgroundColor: "white" }} /><br /><br/>

                    <label htmlFor="address_2" className="form-group col-sm-5 text-right"></label>
                    <input type="text" className="form-control col-sm-6" name="address_2" onChange={handleChange} placeholder="Letters, Nos.& Special Characters"  id="address_2" value={inputs.address_2 || ""} style={{ backgroundColor: "white" }} /><br /><br/>

                    <label htmlFor="address_3" className="form-group col-sm-5 text-right"></label>
                    <input type="text" className="form-control col-sm-6" name="address_3" onChange={handleChange} placeholder="Letters, Nos.& Special Characters"  id="address_3" value={inputs.address_3 || ""} style={{ backgroundColor: "white" }} /><br /><br/>

                      
                    
                    <label htmlFor="pincode" className="form-group col-sm-5 text-right">PinCode</label>
                    <input type="number" min="0" name="pincode" onChange={(event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid Pincode")} value={inputs.pincode || ""} className="form-control col-sm-6"  id="pincode" /><br/><br/>

                    
                <label htmlFor="state" className="form-group col-sm-5 text-right">State</label>
                    <select id="state" name="state" className="form-control col-sm-6  browser-default custom-select" onChange={handleChange} value={inputs.state || ""}>
                      <option value="">Select State</option>
                      {
                        getIndianStates()
                        .map(indian_state => <option value={indian_state.value}>{indian_state.name}</option>
                        )
                      }
                    </select><br/><br/>

                    <label htmlFor="micr" className="form-group col-sm-5 text-right">MICR</label>
                    <input type="text" name="micr" className="form-control col-sm-6"  onChange={handleChange}   id="micr" value={inputs.micr || ""} style={{ backgroundColor: "white" }} /><br /><br/>

                    <label htmlFor="opening_balance" className="form-group col-sm-5 text-right">Opening Balance</label>
                    <input type="number" min="0" name="opening_balance" onChange={handleChange} className="form-control col-sm-6"  id="opening_balance" value={inputs.opening_balance || ""}/><br/><br/>
 
                    
                    <label htmlFor="closing_balance" className="form-group col-sm-5 text-right">Closing Balance(Book)</label>
                    <input type="number" min="0" name="closing_balance" onChange={handleChange} className="form-control col-sm-6"  id="closing_balance" value={inputs.closing_balance || ""} />

                  </div>
                
                  </Col>

                  <Col xl={6} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel " >
                  <h5 className='col-sm-11'><b>Bank Details2</b></h5><br/><br/>
                    <label htmlFor="ifsc" className="form-group col-sm-4 text-right">IFSC</label>
                    <input type="text" className="form-control col-sm-6" name="ifsc" onChange={handleChange} style={{backgroundColor:"white"}}   id="ifsc" value={inputs.ifsc || ""} /><br/><br/>

                    <label htmlFor="email_id" className="form-group col-sm-4 text-right">E-mail</label>
                    <input type="text" className="form-control col-sm-6" name="email_id" onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid Emailid")} value={inputs.email_id|| ""} style={{backgroundColor:"white"}} id="email_id" /><br/><br/>
                    {error.email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.email_id}</strong><br/></>)}

                   
                    <label htmlFor="phone_number" className="form-group col-sm-4 text-right">Phone No</label>
                    <input type="tel" className="form-control col-sm-6" onChange={handleChange} style={{backgroundColor:"white"}} name="phone_number"  id="phone_number" value={inputs.phone_number || ""} /><br/><br/>

                    <label htmlFor="customer_id" className="form-group col-sm-4 text-right">Customer ID</label>
                    <input type="text" className="form-control col-sm-6"  onChange={handleChange} style={{backgroundColor:"white"}} name="customer_id"  id="customer_id" value={inputs.customer_id || ""}/><br/><br/>

                    <label htmlFor="account_type" className="form-group col-sm-4 text-right">Type of Account</label>
                    <select id="account_type" name="account_type" className="form-control col-sm-6  browser-default custom-select" onChange={handleChange} value={inputs.account_type || ""}>
                      <option value="">Select</option>
                      <option value='Saving Account'>Saving Account</option>
                      <option value='Current Account'>Current Account</option>
                      <option value='Over Draft Account'>Over Draft Account</option>   
                    </select><br /><br/>
                    <label htmlFor="contact_person" className="form-group col-sm-4 text-right">Contact Name</label>
                    <input type="text" className="form-control col-sm-6"  onChange={handleChange} style={{backgroundColor:"white"}} name="contact_person"  id="contact_person" value={inputs.contact_person || ""} /><br/><br/>

                    <label htmlFor="contact_designation" className="form-group col-sm-4 text-right">Designation</label>
                    <input type="text" className="form-control col-sm-6"   onChange={handleChange} style={{backgroundColor:"white"}} name="contact_designation" id="contact_designation" value={inputs.contact_designation || ""}/><br/><br/>

                    <label htmlFor="contact_mobile_no" className="form-group col-sm-4 text-right">Mobile No</label>
                    <input type="text" className="form-control col-sm-6"  onChange={handleChange} style={{backgroundColor:"white"}} name="contact_mobile_no" id="contact_mobile_no" value={inputs.contact_mobile_no || ""}/><br/><br/>

                    <StatusDropDown status={inputs.status} onChange={handleChange}/><br/><br/>

                        <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                        <textarea rows="4" cols="50"  onChange={handleChange} class="form-control col-sm-6" id="user_remarks" name="user_remarks" value={inputs.user_remarks || ""}></textarea>


                    </div>
                    </Col>
                  </Row>
                </div>
                <div className="footer text-center">
                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
            </div>
              </form>
          </div>
         {/* </div> */}
 </>

);
}
export default Bank;