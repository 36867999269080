import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllCustomerMain, deleteCustomerMain } from "../../services/CustomerMainServices";
function CustomerMainTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'name', 
                headerName: 'Name'
            },
            {
                fieldName: 'address_1', 
                headerName: 'Address'
            },
            {
                fieldName: 'contact_person', 
                headerName: 'Contact Name'
            },
            {
                fieldName: 'contact_mobile_no', 
                headerName: 'Contact No'
            },
            {
                fieldName: 'email_id', 
                headerName: 'Email'
            },
            {
                fieldName: 'salesrep', 
                headerName: 'Sales Representative'
            },
            {
                fieldName: 'opening_balance_amt', 
                headerName: 'Opening Account Balance'
            },
           
   
            {
              fieldName: "status",
              headerName: 'Status'
          },
        
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
       
         
        ],
        [],);

    const onEditCustomerMain = (customerId, event) => {
       
       navigate(`/CustomerMainEdit/${customerId}/`);
    };

    const onDeleteCustomerMain = (customerId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deleteCustomerMain(cookies, customerId); 
            console.log(`Customer Main with id ${customerId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    name: r.name,
                    address_1: r.address_1,
                    contact_person:r.contact_person,
                    contact_mobile_no: r.contact_mobile_no,
                    email_id: r.email_id,
                    salesrep_id:r.salesrep.name,
                    opening_balance_amt: r.opening_balance_amt,
                    status:r.status ? "active":"inactive" 
                    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Customer Main Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                name: r.name,
                    address_1: r.address_1,
                    contact_person:r.contact_person,
                    contact_mobile_no: r.contact_mobile_no,
                    email_id: r.email_id,
                    salesrep_id:r.salesrep.name,
                    opening_balance_amt: r.opening_balance_amt,
                    status:r.status ? "active":"inactive" 
            })),
            'Customer Main_Forms.pdf'
        );
    };

    useEffect(() => {

        getAllCustomerMain(cookies)
        .then( customermainList => {
            const tableData = customermainList.customer_list
            .map(r => ({
                id :r.id,
                name: r.name,
                address_1: r.address_1,
                address_2: r.address_2,
                address_3: r.address_3,
                contact_person:r.contact_person,
                contact_mobile_no: r.contact_mobile_no,
                email_id: r.email_id,
                salesrep:r.salesrep.name,
                opening_balance_amt: r.opening_balance_amt,
                status:r.status ? "active":"inactive" ,
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
<>
        {/* <div id="outer-container"  className="App" >  */}
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Customers</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Customer Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditCustomerMain}
                            onRowDelete={onDeleteCustomerMain}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/CustomerMain")}}>Back</button>
        {/* </div> */}
        </>
        
    );

}



export default CustomerMainTable;