import React from 'react';
import { useState ,useEffect} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import { getCurrentDate, getCurrentTime} from '../../utils/DateUtils';
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import Select from 'react-select';
import {useLocation ,Link} from 'react-router-dom';
import {getAllPlants } from '../../services/PlantServices';
import {displayErrorToast,displayError} from '../../helpers';
import { createGatePass,getGatePassNumber } from '../../services/VehicleGatePassService';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getAllEquipments } from '../../services/EquipmentServices';
import { getDisplayDate } from '../../utils/DateUtils';
import {getAllGatePassForCheckIn} from '../../services/VehicleGatePassService'
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';


function VehicleGatePass() {
    const location = useLocation();
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [allgatepass,setAllGatePass]=React.useState([]);
    const[gp,setSelectedGP] = useState([]);
    const [equipments, setEquipment] = React.useState([]);
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);

    //const [vehicles, setVehicle] = useState('');
    const [options, setOptions] = useState([]);

    const [inputs, setInputs] = useState({
        mode: "",
        vehicle_id: 0,
        plant_id: "",
        opening_km: 0,
        ending_km: 0,
        gate_pass_no: "",
        gate_pass_date: "",
        gate_pass_time: "",
        driver_name: "",
        driver_mobile_no: "",
        user_remarks: "",
      });
const [userplants, setUserPlant] = React.useState([]);
const [error, setError] = useState({});
const [Ws, setWs]= useState({});
const isCheckInMode = inputs.mode === 'checkin';
const [inProgress, setInProgress] = useState(false);
const [Dc,setDc]=React.useState([]);
const [gatepass, setGatePass] = useState([]); 
const [isLoading, setIsLoading] = useState(true);  
const [plant, setPlant] = React.useState([]);

const navigate = useNavigate();

React.useEffect(() => {
        
        
  setInputs(values => ({...values,
     
      ['checkout_date']: getCurrentDate(),
      ['checkout_time']: getCurrentTime(),
      ['weighment_slip_date']: getCurrentDate(),
      ['weighment_slip_time']: getCurrentTime(),
     
  }))
    }, []);
React.useEffect(() => {
    getAllEquipments(cookies)
      .then (
      
         equipmentObject => {
          console.log(JSON.stringify(equipmentObject)+"getAllEquipments")
              const equipments = equipmentObject.equipment_list.filter(obj => ((obj.equip_type.name === 'TRANSIT MIXER')&& (obj.status) )).map(
                  equipment => {
                      return { value: equipment.id, label: equipment.equip_name }
                  }
              );
              setEquipment(equipments);
          }
      )
  }, []);

const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(name === 'mode'){
      if (value === 'checkout'){
      
        setInputs(values => ({...values, ['is_checkout']:true,['is_checkin']:false}));
  
          getAllEquipments(cookies)
          .then (
          
             equipmentObject => {
              console.log(JSON.stringify(equipmentObject)+"getAllEquipments")
                  const equipments = equipmentObject.equipment_list.filter(obj => ((obj.equip_type.name === 'TRANSIT MIXER')&& (obj.status) && (obj.is_equip_ready) )).map(
                      equipment => {
                          return { value: equipment.id, label: equipment.equip_name,opening_km:equipment.co_open_km}
                      }
                  );
                  setEquipment(equipments);
              }
          )
        
      }
      else if(value === 'checkin'){
        setInputs(values => ({...values, ['is_checkout']:true,['is_checkin']:true}));
    
          getAllGatePassForCheckIn(cookies,value)
              .then(gatepassList => {
                  //console.log(JSON.stringify(gatepassList)+"HC_deliverychallanList")
              
                      const gatepass= gatepassList.gate_pass_list.map(
                        gatepass => {
                              return { value: gatepass.id,label: gatepass.vehicle.name}
                          }
                      );
                      //console.log(JSON.stringify(deliverychallans)+"deliverychallans")
                    
                      setEquipment(gatepass);
                      
                      setAllGatePass(gatepassList.gate_pass_list)
                 })     
      }
    }
    setInputs(values => ({...values,[name]:value}));
}

React.useEffect(() => {
    getAllPlants(cookies)

    .then (
        plantList => {
            
            const plant = plantList.plant_list.filter(obj => obj.status).map(
                plant => {
                    return { value: plant.id, label: plant.plant_alias }
                }
            );
            setPlant(plant);
        }
    )
}, []);

React.useEffect(() => {      
    setInputs(values => ({...values,  
        ['gate_pass_date']: getCurrentDate(),
        ['gate_pass_time']: getCurrentTime()  
    }))
      }, []);

const handleSelectChange = (selectedOption) => {
   console.log(JSON.stringify(selectedOption)+"selected")
   
  setSelectedOption(selectedOption);
  if(inputs.mode === 'checkout' ){
    setInputs(values => ({...values,['vehicle_id']:selectedOption.value,['opening_km']:selectedOption.opening_km}));
  }
  else if(inputs.mode === 'checkin'){
    const selected_gp = allgatepass.filter(obj => obj.id == selectedOption.value)[0];
    setSelectedGP(selected_gp);
    setInputs(values =>({...values,['gate_pass_id']:selectedOption.value,['vehicle_id']:selected_gp.vehicle.id,['opening_km']:selected_gp.opening_km,['plant_id']:selected_gp.plant.id,['driver_name']:selected_gp.driver_name,['driver_mobile_no']:selected_gp.driver_mobile_no,['user_remarks']:selected_gp.user_remarks,['gate_pass_no']:selected_gp.gate_pass_no,['gate_pass_date']:getDisplayDate(selected_gp.gate_pass_date),['gate_pass_time']:selected_gp.gate_pass_time}));
    // setInputs(values => ({...values,}));
    
  }
// Update the selected option in the state
};

const handleChangeOpeningKmDetails = (event) => {
  const name = event.target.name;
  const value = event.target.value;
 

  if (name === 'ending_km') {
       var opening_km = document.getElementById('opening_km').value;
       var ending_km = document.getElementById('ending_km').value;
       document.getElementById('ending_km').setAttribute('min',opening_km);          
       if((opening_km === '') || (opening_km === NaN) ){
        opening_km = 0;
          
       }
       if((ending_km === '') || (ending_km === NaN) ){
        ending_km = 0;
          
       }
       console.log(opening_km+"opening_km")
       console.log(ending_km+"ending_km")
      var total_km = parseFloat(ending_km) - parseFloat(opening_km);
      console.log(total_km+"total_km")
      setInputs(values => ({...values, ['total_km']: total_km})) 
  }
  setInputs(values => ({...values, [name]: event.target.value}))
};

  const handleGatePassNumber = (event) => {
    const name = event.target.name;
    const value = event.target.value;
   
    try{
        setInputs(values => ({...values, [name]: value}));
        if(name === 'plant_id' && value){
            setIsLoading(true);
            getGatePassNumber(cookies,value)
            .then(gate_pass => {
                setIsLoading(false);
                setInputs(values => ({...values, ['gate_pass_no']: gate_pass.gate_pass_no}));
            })
            .catch((error) =>
            {
                setIsLoading(false);
              console.log(error.response.data);
              displayError(error.response.data," Failed");
            })
        } else if (name === 'plant_id' && !value) {
            setInputs(values => ({...values, ['gate_pass_no']: ''}));
        }
      }
     catch (e) {
    
      displayErrorToast(e);
    }
  }

const validate_items = (name,value,regular_expr,error_message) =>
{
   try{
     
        if(value.trim())     //If  required condition is valid it accept ,otherwise it is Invalid.
        {
            if(!regular_expr.test(value.trim())) //If regex is not statisfied ,then it is false
            {
                setError(values => ({...values, [name]: error_message}))
                setIsSubmitting(false); 
            }
            else            //If regex is statisfied ,then it is true
            {
                setError(values => ({...values, [name]: ''}))
                setIsSubmitting(true);
            }
        }
        else                 //If value must not be empty this condition is false
        {
            setError(values => ({...values, [name]: 'must not be empty...'}))
            setIsSubmitting(false);
        }                    // If condition is valid then display the values
        setInputs(values => ({...values, [name]: value}))
    }
 
   catch (e)
      {
       displayErrorToast(e);
      }
    }
const handleSubmit=(event)=> 
{
    event.preventDefault();

    Swal.fire({title: 'Do you want to save?',  
    showCancelButton: true,  
    confirmButtonText: `Yes`,  
    cancelButtonText: `No`,
    }).then((result) => {  
    if (result.isConfirmed)  //If all the required fields are field u can select the save button then choose the option yes or no
    { 
      createGatePass(cookies, {
                  
                        plant_id:parseInt(inputs.plant_id),
                        is_checkout:inputs.is_checkout,
                        is_checkin:inputs.is_checkin,
                        gate_pass_date:getDisplayDate(inputs.gate_pass_date),
                        gate_pass_time:inputs.gate_pass_time,
                        gate_pass_id:parseInt(inputs.gate_pass_id),
                        vehicle_id:parseInt(inputs.vehicle_id),
                        opening_km:parseInt(inputs.opening_km),
                        ending_km:parseInt(inputs.ending_km),
                        total_km:parseInt(inputs.total_km),
                        driver_name:inputs.driver_name,
                        driver_mobile_no:inputs.driver_mobile_no,
                        user_remarks:inputs.user_remarks
                    
                     })

                     .then(response => {
                        Swal.fire("Saved!", "", "success");
                        view();
                      }).catch((error) => {
                          console.log(error.response.data);
                          displayError(error.response.data,"Saved Failed");
                      });
                      
    } 
    else if (result.isDismissed) 
    {    
    Swal.fire('Not saved', '', 'info')  
    }
    });
}
        const view = () => {
          navigate('/VehicleGatePassTable')
        }  
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
            setInputs(() => "")

        }
    return (
    <>
     {/* <div id="outer-container"  className="App" >  */}
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit} data-id={Ws.Ws_id}> 
          <div id="header">
              <h3 className = "text font-weight-bold page-title" >VEHICLE GATE PASS</h3>
          </div>
         
          <FloatingControls tableLink="/VehicleGatePassTable" onCancel={Cancel} enableCancel={true}/>
          <div className="container-fluid">
              <Row>
                <Col xl={3} lg={3} md={3}></Col>
                <Col xl={5} lg={5} md={5}>
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel ">
                  <h5 className='col-sm-12'><b>Gate Pass Details</b></h5><br/><br/>
         
                  <label htmlFor="mode" className="form-group col-sm-5 text-right">Mode</label>
<select
  name="mode"
  className="form-control col-sm-6 browser-default custom-select mandatory-form-control"
  onChange={handleChange}
  value={inputs.mode || ""}
>
<option value="">Please Select Mode</option>
<option value="checkout">Check Out</option>
  <option value="checkin">Check In</option>

</select>

<label htmlFor="vehicle_id"  className="form-group col-sm-5 text-right">Vehicle No.</label>
<div style={{ width: '50%' }}>
      <Select
        id="vehicle_id"
        className={"mandatory-form-control react-select-dropdown"} 
        value={selectedOption}
        onChange={handleSelectChange}
        options={equipments}
        isSearchable={true}
        placeholder="Please Select Vehicle" // Enable search functionality
      />
      </div>
<label htmlFor="plant_id" className="form-group col-sm-5 text-right">Plant Location</label>
<select
  id="plant_id"
  name="plant_id"
  className="form-control col-sm-6 browser-default custom-select mandatory-form-control"
  required
  onChange={handleGatePassNumber}
  value={inputs.plant_id || ""}
  disabled={inputs.mode === "checkin"} // Disable when mode is "Check In"
>
  <option value="">Select Plant</option>
  {plant.map((item) => (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  ))}
</select>


              <ViewOnlyTextbox 
                name="opening_km" 
                value={inputs.opening_km || ""}
                labelClassName="form-group col-sm-5 text-right "
                label="Opening KM"
                inputClassName="form-control col-sm-6 mandatory-form-control"
                />  

              <label htmlFor="ending_km" className="form-group col-sm-5 text-right">Ending KM</label>
              <input type="number" id="ending_km" name="ending_km" className="form-control col-sm-6 mandatory-form-control" value={inputs.ending_km || 0} onChange={handleChangeOpeningKmDetails} readOnly={inputs.mode === "checkout"} />

              <ViewOnlyTextbox 
                name="total_km" 
                value={inputs.total_km || ""}
                labelClassName="form-group col-sm-5 text-right "
                label="Total KM"
                inputClassName="form-control col-sm-6 mandatory-form-control"
                />  


                <ViewOnlyTextbox 
                name="gate_pass_no" 
                value={inputs.gate_pass_no || ""}
                labelClassName="form-group col-sm-5 text-right "
                label="Gate Pass No"
                inputClassName="form-control col-sm-6 mandatory-form-control"
                /> 

<ViewOnlyTextbox 
                name="checkout_date" 
                value={inputs.checkout_date|| ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Check Out Date/Time"
                inputClassName="form-control col-sm-3 mandatory-form-control"
                />  
                 <ViewOnlyTextbox 
                name="checkout_date" 
                value={inputs.checkout_time|| ""}
                labelClassName="form-group col-sm-5 text-right"
                inputClassName="form-control col-sm-3 mandatory-form-control"
                />  

                        <label htmlFor="driver_name" className="form-group col-sm-5 text-right">Driver Name</label>
                        <input type="text" id="driver_name" onChange={handleChange} value={inputs.driver_name || ""}  name="driver_name" className="form-control col-sm-6"/>

                        <label htmlFor="driver_mobile_no"className="form-group col-sm-5 text-right  ">Mobile No</label>
                              <input type="text" id="driver_mobile_no" name="driver_mobile_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^\+?[0-9]{1,4}[-\s]?[0-9]{7,10}$/,"invalid Mobile No")} value={inputs.driver_mobile_no|| ""} pattern="[0-9]{10}"  maxLength="10" className="form-control col-sm-6" /><br/>
                              {error.driver_mobile_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.driver_mobile_no}</strong><br/></>)}

                              <label htmlFor="user_remarks" className="form-group col-sm-5 text-right">Remarks,if any</label>
                <input type="text" className="form-control col-sm-6" id="user_remarks"  name="user_remarks" value={inputs.user_remarks||""} onChange={handleChange} /><br />
                  <br/>
                  </div>
               
                </Col>
              </Row>
            </div>
            <div className="footer text-center">
                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
        </form>
        </div>
    {/* </div> */}
         </>
    );
  }
  export default VehicleGatePass;