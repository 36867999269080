import { useEffect, useState, useMemo } from "react";
import React from 'react';
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import {Button} from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import {Row,Col} from "reactstrap";
import { default as ReactSelect } from "react-select";
import {getGstReport,getGstReportCSV} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import { getAllPlants} from '../../services/PlantServices';
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import moment  from 'moment';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';
import {HandleSelect,ValueContainer,Option} from "../../components/MultiSelectControls";
const {RangePicker} = DatePicker;

function GstReport() 
{
    const InitialData = {
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [plants, setPlants] = React.useState([]);
    const [selectedPlant, setSelectedPlant] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [inputs, setInputs] = useState({});

    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
        setFromDate(values[0]);
        setToDate(values[1]);
        }
    };

    React.useEffect(() => {
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                const plants = plantList.plant_list.map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlants(plants);
                // setSelectedPlant(values =>[...values,...plants, ...plantsWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
        getAllCustomerMain(cookies)
        .then (
            customerList => {
                const customers = customerList.customer_list.map(
                    customer => {
                        return { value: customer.id, label: customer.name }
                    }
                );
                setCustomers(customers);
                setSelectedCustomer(values =>[...values,...customers, ...customersWithSelectAll]);
            }
        )
    }, []);


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name+value+"name and value")
        setInputs(values => ({...values, [name]: event.target.value}))
    }

    // Add a "Select All" option to the plants array
    const plantsWithSelectAll = [
        { value: "select-all", label: "Select All plants" },
        ...plants,
    ];

    // Add a "Select All" option to the customers array
    const customersWithSelectAll = [
        { value: "select-all", label: "Select All customers" },
        ...customers,
    ];
    
    const onPlantSelect = (selected) => {
        HandleSelect(selected,selectedPlant,setSelectedPlant, plants, plantsWithSelectAll);
    };
    
    const onCustomerSelect = (selected) => {
        HandleSelect(selected,selectedCustomer, setSelectedCustomer, customers, customersWithSelectAll);
    };
    
    const areAllPlantsSelected = () => {
        // Check if all plants are selected
        return plants.every((plant) =>
          selectedPlant ? selectedPlant.some((selected) => selected.value === plant.value) : false
        );
    };

    const areAllCustomersSelected = () => {
        // Check if all customers are selected
        return customers.every((customer) =>
            selectedCustomer ? selectedCustomer.some((selected) => selected.value === customer.value) : false
        );
    };

    const columns = useMemo(
        () => [
            {
                fieldName: 'invoice_no', 
                headerName: 'Invoice Number'
            },
            {
                fieldName: 'invoice_date', 
                headerName: 'Invoice Date'
            },
            {
                fieldName: "customer_name",
                headerName: ' Customer Name'
            },
            {
                fieldName: 'gst_no', 
                headerName: 'GSTIN/UIN'
            },
            {
                fieldName: "pan_no",
                headerName: 'PAN No.'
            },
            {
                fieldName: 'invoice_amt', 
                headerName: 'Invoice Amount'
            },
            {
                fieldName: "taxable_amt",
                headerName: 'Taxable Amount'
            },
            {
                fieldName: "cgst_amt",
                headerName: 'CGST Amount'
            },
            {
                fieldName: "sgst_amt",
                headerName: 'SGST Amount'
            },
            {
                fieldName: "igst_amt",
                headerName: ' IGST Amount'
            },
            {
                fieldName: "gst_amt",
                headerName: 'Total GST Amount'
            },
            {
                fieldName: 'tcs_amt', 
                headerName: 'TCS Amount'
            },
            {
                fieldName: 'rounded_off', 
                headerName: 'Rounding Off'
            },
            {
                fieldName: 'salesrep_name', 
                headerName: 'Marketing'
            },
            {
                fieldName: 'status', 
                headerName: 'Invoice Status'
            },
        ],
        [],);

   
    const handleCSVExport = (event) => {
    };

    const handlePDFExport = (event) => {
    };

    const constructFormData = () => {
        const EMPTY_STRING = '';
        return {
            plant_id:inputs.plant_id,
            customer_ids:areAllCustomersSelected()? '*' // Send '*' if all customers are selected
            : selectedCustomer.map((customer) => customer.value).join(','),
            from_date:fromDate.format('DD-MM-YYYY'),
            upto_date:toDate.format('DD-MM-YYYY'),
          }
    }

    const handleSubmit=(event)=> 
    {
        event.preventDefault();
        console.log(JSON.stringify(constructFormData())+"submit")
        const inputs = constructFormData()
        if ((inputs.plant_id) && (inputs.customer_ids)  && (inputs.from_date) && (inputs.upto_date))
        {
            getGstReport(cookies, constructFormData())
            .then(response => {
                const tableData = response.gst_report_list
                .map(invoice => ({
                    plant_alias: invoice.plant_alias,
                    id: invoice.id,
                    invoice_amt: invoice.invoice_amt,
                    taxable_amt:invoice.taxable_amt,
                    cgst_amt:invoice.cgst_amt,
                    sgst_amt:invoice.sgst_amt,
                    igst_amt:invoice.igst_amt,
                    gst_amt:invoice.gst_amt,
                    tcs_amt:invoice.tcs_amt,
                    rounded_off:invoice.rounded_off,
                    status:invoice.status,
                    invoice_no:invoice.invoice_no,
                    invoice_date: invoice.invoice_date,
                    invoice_time: invoice.invoice_time,
                    customer_name:invoice.customer_name,
                    salesrep_name:invoice.salesrep_name,
                    project_name:invoice.project_name,
                    pan_no:invoice.customer_pan_no,
                    gst_no: invoice.customer_gst_no,
                }));
                setData({
                    records: tableData
                });
                setIsLoading(false);
            }).catch((error) => {
                console.log(error.response.data);
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("plant,customer,from_date,upto_date must not be empty.", "", "info");
            setData({
                records: []
            });  
        }
    }

        const handleDownloadCsv = () => {
            console.log(JSON.stringify(constructFormData())+"submit")
            const inputs = constructFormData()
            if ((inputs.plant_id) && (inputs.customer_ids) &&
            (inputs.from_date) && (inputs.upto_date))
            {
                getGstReportCSV(cookies, constructFormData())
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'gst_report.csv');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((error) => {
                    console.log(error.response.data);
                    displayError(error.response.data);
                });
            }
            else 
            {
                Swal.fire("plant,customer,from_date,upto_date must not be empty.", "", "info");  
            }
        };

    return (
            <>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="container"> 
                       <Row> 
                            <Col xl={12} lg={12} md={12}>
                                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                                        {/* <ReactSelect
                                            options={plantsWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedPlant}
                                            onChange={onPlantSelect}
                                            className="react-select-dropdown col-sm-3"
                                            placeholder="Please select Plant name"
                                        /> */}
                                        {/* <label htmlFor="plant_id" className="form-group col-sm-4 text-right"> Plant Location: </label> */}
                                <select id="plant_id"name="plant_id" className="form-control col-sm-4  browser-default custom-select  mandatory-form-control"  required onChange={handleChange} value={inputs.plant_id || ""}>
                                <option value="">Select plant</option>
                                
                                    {plants.map((item) => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}</option>
                                    ))}
                                </select>
                                        <br/><br/>
                                    
                                        <ReactSelect
                                            options={customersWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedCustomer}
                                            onChange={onCustomerSelect}
                                            className="react-select-dropdown col-sm-3"
                                            placeholder="Please select Customer"
                                        />
                                        <br/><br/>

                                        <RangePicker
                                            defaultValue={[initialFromDate, initialToDate]}
                                            format="DD-MM-YYYY"
                                            onChange={handleDateChange}
                                            style={{height:"40px",width:"270px"}}
                                        />
                                        &nbsp;&nbsp;&nbsp;

                                        <Button  type="submit"
                                            style={{backgroundColor: "RGB(188,232,253)",fontWeight:"bold",
                                            height:"30px",display: "flex",
                                            alignItems: "center",  
                                            justifyContent: "center"}}>
                                            <i className="fa fa-search fa-2x" id="myData" aria-hidden="true" style={{color:"rgb(93, 156, 233)"}}></i>
                                        </Button> 
                                        <Button color="primary" onClick={handleDownloadCsv} startIcon={<FileDownloadRoundedIcon/>}  variant="contained">CSV</Button>
                                        &nbsp;&nbsp;
                                </div>
                             </Col>
                        </Row> 
                    </div> 
                </form>
                <div className="container item-list-table-container">
                    <div className="table-responsive">
                        <ReactTableWrapper
                            title='CustomerData Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            enableExportToolbar={false}
                            isLoading={isLoading}
                        />
                    </div>
                </div> 
            </>
);
}

export default GstReport;